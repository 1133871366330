{
  "jira-pulsar.jira-common.celebration-icon.body_image_alt": [
    {
      "type": 0,
      "value": "Celebration icon"
    }
  ],
  "jira-pulsar.jira-common.exclamation-icon.body_image_alt": [
    {
      "type": 0,
      "value": "Exclamation icon"
    }
  ],
  "jira-pulsar.jira-common.message-template.text.body.display.old.field.value": [
    {
      "type": 0,
      "value": "was: "
    },
    {
      "type": 1,
      "value": "oldFieldValue"
    }
  ],
  "jira-pulsar.jira-common.right-arrow-icon.no-translate": [
    {
      "type": 0,
      "value": "&#x2192;"
    }
  ],
  "jira-pulsar.jira-contact-admin.message-template.email.body.from.contact.admin": [
    {
      "type": 0,
      "value": "From:"
    }
  ],
  "jira-pulsar.jira-contact-admin.message-template.email.body.new.message.from.contact.admin": [
    {
      "type": 0,
      "value": "New message from Contact Administrators page"
    }
  ],
  "jira-pulsar.jira-contact-admin.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "header"
    }
  ],
  "jira-pulsar.jira-issue-approval-decided.right.arrow.image.alt.no-translate": [
    {
      "type": 0,
      "value": "->"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.assigned.to": [
    {
      "type": 0,
      "value": "Assigned to: "
    },
    {
      "type": 1,
      "value": "assignee"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.event.approvers": [
    {
      "type": 0,
      "value": "Approvers: "
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.event.approvers.plus.no-translate": [
    {
      "type": 0,
      "value": "+"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.issue.approved.by.user.subject": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " has approved "
    },
    {
      "type": 1,
      "value": "issueLabel"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.issue.approved.subject": [
    {
      "type": 1,
      "value": "issueLabel"
    },
    {
      "type": 0,
      "value": " has been approved"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.issue.declined.by.user.subject": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " has declined "
    },
    {
      "type": 1,
      "value": "issueLabel"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.required": [
    {
      "type": 1,
      "value": "issueLink"
    },
    {
      "type": 0,
      "value": " requires your approval"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.approval.required.subject": [
    {
      "type": 0,
      "value": "Approval required: "
    },
    {
      "type": 1,
      "value": "issueLabel"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.footer.contact.us": [
    {
      "type": 0,
      "value": "Contact Us"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.footer.copyright": [
    {
      "type": 0,
      "value": "Copyright "
    },
    {
      "type": 1,
      "value": "year"
    },
    {
      "type": 0,
      "value": " Atlassian Pty Ltd. All rights reserved."
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.footer.dot.no-translate": [
    {
      "type": 0,
      "value": "&bull;"
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.footer.location": [
    {
      "type": 0,
      "value": "We are located at 341 George Street, Sydney, NSW, 2000, Australia."
    }
  ],
  "jira-pulsar.jira-issue-approval.message-template.email.unassigned": [
    {
      "type": 0,
      "value": "Unassigned"
    }
  ],
  "jira-pulsar.jira-issue-archived.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " archived "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira-pulsar.jira-issue-archived.message-template.redacted.email.subject": [
    {
      "type": 0,
      "value": "("
    },
    {
      "type": 1,
      "value": "issueKey"
    },
    {
      "type": 0,
      "value": ") work item was archived"
    }
  ],
  "jira-pulsar.jira-issue-assign.message-template-template.email.body.heading": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "assigned"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": " this work item to you"
    }
  ],
  "jira-pulsar.jira-issue-assign.message-template.email.redacted.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "issueKey"
    },
    {
      "type": 0,
      "value": " work item was updated"
    }
  ],
  "jira-pulsar.jira-issue-assign.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " assigned "
    },
    {
      "type": 1,
      "value": "issueKey"
    },
    {
      "type": 0,
      "value": " to you"
    }
  ],
  "jira-pulsar.jira-issue-assigned.email.body.assignee": [
    {
      "type": 0,
      "value": "Assignee"
    }
  ],
  "jira-pulsar.jira-issue-assigned.email.body.unassigned": [
    {
      "type": 0,
      "value": "Unassigned"
    }
  ],
  "jira-pulsar.jira-issue-comment-delete.email.body.deleted": [
    {
      "type": 0,
      "value": "Deleted"
    }
  ],
  "jira-pulsar.jira-issue-comment-delete.message-template.hipppa.view.update": [
    {
      "type": 0,
      "value": "Comment: "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "View update"
        }
      ],
      "type": 8,
      "value": "a"
    }
  ],
  "jira-pulsar.jira-issue-comment-delete.message-template.redacted.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "issuekey"
    },
    {
      "type": 0,
      "value": ") Work item was updated"
    }
  ],
  "jira-pulsar.jira-issue-comment-update.email.body.added": [
    {
      "type": 0,
      "value": "Added"
    }
  ],
  "jira-pulsar.jira-issue-comment-update.email.body.deleted": [
    {
      "type": 0,
      "value": "Deleted"
    }
  ],
  "jira-pulsar.jira-issue-comment-update.email.body.edited": [
    {
      "type": 0,
      "value": "Edited"
    }
  ],
  "jira-pulsar.jira-issue-comment-update.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "issuekey"
    },
    {
      "type": 0,
      "value": ") "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "jira-pulsar.jira-issue-comment-update.message-template.redacted.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "issuekey"
    },
    {
      "type": 0,
      "value": ") Work item was updated"
    }
  ],
  "jira-pulsar.jira-issue-comment.body_image_alt": [
    {
      "type": 0,
      "value": "Icon for comment on a Jira issue"
    }
  ],
  "jira-pulsar.jira-issue-comment.email.body.view-comment": [
    {
      "type": 0,
      "value": "View comment"
    }
  ],
  "jira-pulsar.jira-issue-comment.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " commented on "
    },
    {
      "type": 1,
      "value": "issuekey"
    }
  ],
  "jira-pulsar.jira-issue-create.email.body.unassigned": [
    {
      "type": 0,
      "value": "Unassigned"
    }
  ],
  "jira-pulsar.jira-issue-moved.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "issueKey"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "jira-pulsar.jira-issue-moved.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " moved "
    },
    {
      "type": 1,
      "value": "issuekey"
    }
  ],
  "jira-pulsar.jira-issue-moved.message-template.redacted.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "issueKey"
    },
    {
      "type": 0,
      "value": " Work item was updated"
    }
  ],
  "jira-pulsar.jira-issue-reopen.message-template-template.email.body.img.alt.no-translate": [
    {
      "type": 0,
      "value": "Description of image"
    }
  ],
  "jira-pulsar.jira-issue-reopen.test-message.email.body.hello.no-translate": [
    {
      "type": 0,
      "value": "Hi "
    },
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ","
    }
  ],
  "jira-pulsar.jira-issue-reopen.test-message.email.body.regards.no-translate": [
    {
      "type": 0,
      "value": "Team Post Office"
    }
  ],
  "jira-pulsar.jira-issue-reopen.test-message.email.body.signoff.no-translate": [
    {
      "type": 0,
      "value": "Regards,"
    }
  ],
  "jira-pulsar.jira-issue-reopened.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "issuekey"
    },
    {
      "type": 0,
      "value": ") Work item was updated"
    }
  ],
  "jira-pulsar.jira-issue-update.email.body.slash.no-translate": [
    {
      "type": 0,
      "value": " → "
    }
  ],
  "jira-pulsar.jira-issue-update.message-template.changeby": [
    {
      "type": 0,
      "value": "Change by: "
    },
    {
      "children": [
        {
          "type": 1,
          "value": "actioner"
        }
      ],
      "type": 8,
      "value": "a"
    }
  ],
  "jira-pulsar.jira-issue-update.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " updated "
    },
    {
      "type": 1,
      "value": "issuekey"
    }
  ],
  "jira-pulsar.jira-issue-update.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " updated "
    },
    {
      "type": 1,
      "value": "issuekey"
    }
  ],
  "jira-pulsar.jira-issue-update.message-template.hipppa.view.update": [
    {
      "children": [
        {
          "type": 0,
          "value": "View update"
        }
      ],
      "type": 8,
      "value": "a"
    }
  ],
  "jira-pulsar.jira-issue-work-started.message-template.email.body.started": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "started work on this work item"
        }
      ],
      "type": 8,
      "value": "color"
    }
  ],
  "jira-pulsar.jira-issue-work-started.message-template.email.redacted.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "issuekey"
    },
    {
      "type": 0,
      "value": ") Work item was updated"
    }
  ],
  "jira-pulsar.jira-issue-work-stopped.message-template.email.body.stopped": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "stopped work on this work item"
        }
      ],
      "type": 8,
      "value": "color"
    }
  ],
  "jira-pulsar.jira-issue-work-stopped.message-template.email.redacted.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "issuekey"
    },
    {
      "type": 0,
      "value": ") Work item was updated"
    }
  ],
  "jira-pulsar.jira-issue-work-stopped.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " stopped "
    },
    {
      "type": 1,
      "value": "issuekey"
    }
  ],
  "jira-pulsar.jira-issue-worklog-delete.email.body.deleted": [
    {
      "type": 0,
      "value": "Deleted"
    }
  ],
  "jira-pulsar.jira-issue-worklog-delete.email.body.work.log.deleted": [
    {
      "type": 0,
      "value": "Deleted"
    }
  ],
  "jira-pulsar.jira-issue-worklog-update.email.body.edited": [
    {
      "type": 0,
      "value": "Edited"
    }
  ],
  "jira-pulsar.jira-issue-worklog.message-template.email.body.add.worklog.title": [
    {
      "children": [
        {
          "type": 1,
          "value": "actioner"
        }
      ],
      "type": 8,
      "value": "a"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "added a worklog"
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 0,
      "value": " on "
    },
    {
      "children": [
        {
          "type": 1,
          "value": "issueKey"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira-pulsar.jira-issue-worklog.message-template.email.body.change.by": [
    {
      "children": [
        {
          "type": 1,
          "value": "actioner"
        }
      ],
      "type": 8,
      "value": "a"
    }
  ],
  "jira-pulsar.jira-issue-worklog.message-template.email.body.worklog.add.comment": [
    {
      "children": [
        {
          "type": 0,
          "value": "Add Comment"
        }
      ],
      "type": 8,
      "value": "a"
    }
  ],
  "jira-pulsar.jira-issue-worklog.message-template.email.body.worklog.description": [
    {
      "children": [
        {
          "type": 0,
          "value": "View work description"
        }
      ],
      "type": 8,
      "value": "a"
    }
  ],
  "jira-pulsar.jira-issue-worklog.message-template.email.body.worklog.entry.logged.by": [
    {
      "type": 1,
      "value": "entry"
    },
    {
      "type": 0,
      "value": " logged by "
    },
    {
      "children": [
        {
          "type": 1,
          "value": "actioner"
        }
      ],
      "type": 8,
      "value": "a"
    }
  ],
  "jira-pulsar.jira-issue-worklogged.body_image_alt": [
    {
      "type": 0,
      "value": "Product logo"
    }
  ],
  "jira.default.subject": [
    {
      "type": 0,
      "value": "[JIRA] Project Update: "
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": " ("
    },
    {
      "type": 1,
      "value": "projectKey"
    },
    {
      "type": 0,
      "value": ")"
    }
  ],
  "jira.jira-bulk.message-template.email.header": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "sent you"
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 0,
      "value": " a message."
    }
  ],
  "jira.jira-bulk.message-template.email.subject.user.provided.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "subject"
    }
  ],
  "jira.jira-bulk.message-template.text.message": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " sent you a message"
    }
  ],
  "jira.jira-common.email.footer.get.apps": [
    {
      "type": 0,
      "value": "Get Jira notifications on your phone! Download the Jira Cloud app for "
    }
  ],
  "jira.jira-common.email.footer.give.feedback": [
    {
      "type": 0,
      "value": "Give feedback"
    }
  ],
  "jira.jira-common.email.footer.manage.notifications": [
    {
      "type": 0,
      "value": "Manage Notifications"
    }
  ],
  "jira.jira-common.email.footer.open.attachment": [
    {
      "type": 0,
      "value": "Open the work item to view attachments over 100 KB"
    }
  ],
  "jira.jira-common.email.footer.privacy.policy": [
    {
      "type": 0,
      "value": "Privacy Policy"
    }
  ],
  "jira.jira-common.email.footer.sent.by": [
    {
      "type": 0,
      "value": "This message was sent by Atlassian Jira"
    }
  ],
  "jira.jira-common.email.slash.no-translate": [
    {
      "type": 0,
      "value": "/"
    }
  ],
  "jira.jira-common.message-template.text.body.assignee.label": [
    {
      "type": 0,
      "value": "Assignee:"
    }
  ],
  "jira.jira-common.message-template.text.body.attachments.label": [
    {
      "type": 0,
      "value": "Attachments:"
    }
  ],
  "jira.jira-common.message-template.text.body.key.label": [
    {
      "type": 0,
      "value": "Key:"
    }
  ],
  "jira.jira-common.message-template.text.body.manage.notifications": [
    {
      "type": 0,
      "value": "Manage Notifications"
    }
  ],
  "jira.jira-common.message-template.text.body.priority.label": [
    {
      "type": 0,
      "value": "Priority:"
    }
  ],
  "jira.jira-common.message-template.text.body.project.label": [
    {
      "type": 0,
      "value": "Project:"
    }
  ],
  "jira.jira-common.message-template.text.body.reporter.heading": [
    {
      "type": 0,
      "value": "Reporter: "
    }
  ],
  "jira.jira-common.message-template.text.body.reporter.label": [
    {
      "type": 0,
      "value": "Reporter:"
    }
  ],
  "jira.jira-common.message-template.text.body.sent.by": [
    {
      "type": 0,
      "value": "This message was sent by Atlassian Jira"
    }
  ],
  "jira.jira-common.message-template.text.body.work.type.label": [
    {
      "type": 0,
      "value": "Work type:"
    }
  ],
  "jira.jira-filter-subscription.message-template.email.table.filter-label": [
    {
      "type": 0,
      "value": "Filter: "
    }
  ],
  "jira.jira-filter-subscription.message-template.email.table.header": [
    {
      "type": 0,
      "value": "Work Item Subscription"
    }
  ],
  "jira.jira-filter-subscription.message-template.email.table.subscriber-label": [
    {
      "type": 0,
      "value": "Subscriber:"
    }
  ],
  "jira.jira-issue-approval-decided.message-template.approval.by.user": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " has approved "
    },
    {
      "type": 1,
      "value": "issueLink"
    }
  ],
  "jira.jira-issue-approval-decided.message-template.approval.event.allapproved.transition": [
    {
      "type": 1,
      "value": "issueTypeName"
    },
    {
      "type": 0,
      "value": " has moved from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "arrowIcon"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "newStatus"
    }
  ],
  "jira.jira-issue-approval-decided.message-template.approval.event.approved.transition.plural": [
    {
      "type": 1,
      "value": "remainingApprovals"
    },
    {
      "type": 0,
      "value": " more approvals required to move this "
    },
    {
      "type": 1,
      "value": "issueTypeName"
    },
    {
      "type": 0,
      "value": " from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "arrowIcon"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "newStatus"
    }
  ],
  "jira.jira-issue-approval-decided.message-template.approval.event.approved.transition.singular": [
    {
      "type": 0,
      "value": "1 more approval required to move this "
    },
    {
      "type": 1,
      "value": "issueTypeName"
    },
    {
      "type": 0,
      "value": " from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "arrowIcon"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "newStatus"
    }
  ],
  "jira.jira-issue-approval-decided.message-template.approval.event.declined.transition": [
    {
      "type": 1,
      "value": "issueTypeName"
    },
    {
      "type": 0,
      "value": " has moved from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "arrowIcon"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "newStatus"
    }
  ],
  "jira.jira-issue-approval-decided.message-template.declined.by.user": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " has declined "
    },
    {
      "type": 1,
      "value": "issueLink"
    }
  ],
  "jira.jira-issue-approval.message-template.approval.required.title": [
    {
      "type": 1,
      "value": "issueLabel"
    },
    {
      "type": 0,
      "value": " requires your approval"
    }
  ],
  "jira.jira-issue-approval.message-template.approval.title.all-approved": [
    {
      "type": 1,
      "value": "issueLink"
    },
    {
      "type": 0,
      "value": " has been approved!"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.approved": [
    {
      "type": 0,
      "value": "Approved"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.approvers.remaining": [
    {
      "type": 1,
      "value": "remainingApprovers"
    },
    {
      "type": 0,
      "value": " more approvals required to move this "
    },
    {
      "type": 1,
      "value": "issueType"
    },
    {
      "type": 0,
      "value": " from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " to "
    },
    {
      "type": 1,
      "value": "approvedStatus"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.assigned.to": [
    {
      "type": 0,
      "value": "Assigned to: "
    },
    {
      "type": 1,
      "value": "assignee"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.declined": [
    {
      "type": 0,
      "value": "Declined"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.declined.transition": [
    {
      "type": 1,
      "value": "issueType"
    },
    {
      "type": 0,
      "value": " has moved from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " to "
    },
    {
      "type": 1,
      "value": "declinedStatus"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.event.approvers": [
    {
      "type": 0,
      "value": "Approvers: "
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.one.approver.remaining": [
    {
      "type": 0,
      "value": "1 more approval required to move this "
    },
    {
      "type": 1,
      "value": "issueType"
    },
    {
      "type": 0,
      "value": " from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " to "
    },
    {
      "type": 1,
      "value": "approvedStatus"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approval.view.work.item": [
    {
      "type": 0,
      "value": "View "
    },
    {
      "type": 1,
      "value": "issueType"
    }
  ],
  "jira.jira-issue-approval.message-template.email.approved.by": [
    {
      "type": 1,
      "value": "deciderName"
    },
    {
      "type": 0,
      "value": " has approved "
    },
    {
      "type": 1,
      "value": "issueLabel"
    }
  ],
  "jira.jira-issue-approval.message-template.email.declined.by": [
    {
      "type": 1,
      "value": "deciderName"
    },
    {
      "type": 0,
      "value": " has declined "
    },
    {
      "type": 1,
      "value": "issueLabel"
    }
  ],
  "jira.jira-issue-approval.message-template.email.footer.contact.us": [
    {
      "type": 0,
      "value": "Contact Us"
    }
  ],
  "jira.jira-issue-approval.message-template.email.footer.location": [
    {
      "type": 0,
      "value": "We are located at 341 George Street, Sydney, NSW, 2000, Australia."
    }
  ],
  "jira.jira-issue-approval.message-template.email.footer.privacy.policy": [
    {
      "type": 0,
      "value": "Privacy Policy"
    }
  ],
  "jira.jira-issue-approval.message-template.email.issue.approved": [
    {
      "type": 1,
      "value": "issueLabel"
    },
    {
      "type": 0,
      "value": " has been approved!"
    }
  ],
  "jira.jira-issue-approval.message-template.email.issue.has.moved.from": [
    {
      "type": 1,
      "value": "issueType"
    },
    {
      "type": 0,
      "value": " has moved from "
    },
    {
      "type": 1,
      "value": "originalStatus"
    },
    {
      "type": 0,
      "value": " to "
    },
    {
      "type": 1,
      "value": "approvedStatus"
    }
  ],
  "jira.jira-issue-archived.message-template.email.subject": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "archived a work item."
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-archived.message-template.redacted.email.subject": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "archived a work item."
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-archived.message-template.text.archived.work.item": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " archived "
    },
    {
      "type": 1,
      "value": "workItemKey"
    },
    {
      "type": 0,
      "value": ":"
    }
  ],
  "jira.jira-issue-assign.text.body.assigned": [
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " reassigned "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira.jira-issue-assign.text.body.assignee.update": [
    {
      "type": 0,
      "value": "Assignee: "
    },
    {
      "type": 1,
      "value": "assigneeName"
    },
    {
      "type": 0,
      "value": " was: ("
    },
    {
      "type": 1,
      "value": "oldAssigneeName"
    },
    {
      "type": 0,
      "value": ")"
    }
  ],
  "jira.jira-issue-assign.text.body.sentby": [
    {
      "type": 0,
      "value": "This message was sent by Atlassian Jira"
    }
  ],
  "jira.jira-issue-batch.email.body.issue.comments.heading": [
    {
      "type": 0,
      "value": "Comments"
    }
  ],
  "jira.jira-issue-batch.email.body.issue.created.heading": [
    {
      "type": 0,
      "value": "Work item created"
    }
  ],
  "jira.jira-issue-batch.email.body.issue.updates.heading": [
    {
      "type": 0,
      "value": "Updates"
    }
  ],
  "jira.jira-issue-batch.email.body.issue.worklog.heading": [
    {
      "type": 0,
      "value": "Work log"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.created.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " added a new comment"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  added "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " new comments"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.created.more.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " other people "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  added "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " new comments"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.created.two.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user2"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  added "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " new comments"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.deleted.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " deleted a comment"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  deleted "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " comments"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.deleted.more.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " other people "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  deleted "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " comments"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.deleted.two.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user2"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  deleted "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " comments"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.updated.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " updated a comment"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  updated "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " comments"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.updated.more.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " other people "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  updated "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " comments"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.comment.updated.two.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user2"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  updated "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " comments"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.created.heading": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "created a work item"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.deleted.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " deleted a work item"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  deleted "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " work items"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.dot": [
    {
      "type": 0,
      "value": ". "
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.separator.comma": [
    {
      "type": 0,
      "value": ", "
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.updates.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "type": 1,
              "value": "count"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " made an update"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  made "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " updates"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.updates.multiple.users.heading": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  made "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " updates"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.updates.two.users.heading": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  made "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " updates"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.added.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " added a worklog"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  added "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " work log"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.added.more.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " other people "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  added "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.added.two.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user2"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  added "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.deleted.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " deleted a worklog"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  deleted "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " work log"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.deleted.more.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " other people "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  deleted "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.deleted.two.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user2"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  deleted "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.started.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " started a worklog"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  started "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " work log"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.started.more.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " other people "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  started "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.started.two.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user2"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  started "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.updated.heading": [
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": " updated a worklog"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 1,
              "value": "user"
            },
            {
              "type": 0,
              "value": " "
            },
            {
              "children": [
                {
                  "type": 0,
                  "value": "  updated "
                },
                {
                  "type": 1,
                  "value": "count"
                },
                {
                  "type": 0,
                  "value": " work log"
                }
              ],
              "type": 8,
              "value": "b"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "count"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.updated.more.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "userCount"
    },
    {
      "type": 0,
      "value": " other people "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  updated "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.email.body.summary.issue.worklog.updated.two.heading": [
    {
      "type": 1,
      "value": "user1"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "type": 1,
      "value": "user2"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "  updated "
        },
        {
          "type": 1,
          "value": "count"
        },
        {
          "type": 0,
          "value": " work log"
        }
      ],
      "type": 8,
      "value": "b"
    }
  ],
  "jira.jira-issue-batch.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "issueId"
    },
    {
      "type": 0,
      "value": ") "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "jira.jira-issue-batch.message-template.redacted.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] Updates for "
    },
    {
      "type": 1,
      "value": "issueId"
    }
  ],
  "jira.jira-issue-closed.message-template.text.email.body": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " closed "
    },
    {
      "type": 1,
      "value": "workItemKey"
    }
  ],
  "jira.jira-issue-comment-delete.message-template.email.body.commented": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "deleted a comment on a work item"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-comment-delete.message-template.text.comment.was.deleted": [
    {
      "type": 0,
      "value": "Comment: was deleted"
    }
  ],
  "jira.jira-issue-comment-delete.message-template.text.updated.work.item": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " updated "
    },
    {
      "type": 1,
      "value": "workItemKey"
    },
    {
      "type": 0,
      "value": ":"
    }
  ],
  "jira.jira-issue-comment-update.message-template.email.body.commented": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "edited a comment on a work item"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-comment-update.message-template.text.body.comment.was": [
    {
      "type": 0,
      "value": "was (Author: "
    },
    {
      "type": 1,
      "value": "authorName"
    },
    {
      "type": 0,
      "value": "):"
    }
  ],
  "jira.jira-issue-comment-update.message-template.text.subject": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " edited a comment on "
    },
    {
      "type": 1,
      "value": "workItemKey"
    }
  ],
  "jira.jira-issue-comment.message-template.email.body.commented": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "added a comment on a work item"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-comment.message-template.text.subject": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " commented on "
    },
    {
      "type": 1,
      "value": "workItemKey"
    },
    {
      "type": 0,
      "value": ":"
    }
  ],
  "jira.jira-issue-common.message-template.text.body.common.author": [
    {
      "type": 0,
      "value": "Author:"
    }
  ],
  "jira.jira-issue-common.message-template.text.body.common.issue.author": [
    {
      "type": 0,
      "value": "Author:"
    }
  ],
  "jira.jira-issue-create.email.body.created": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "created a work item"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-create.email.body.view.attachments": [
    {
      "type": 0,
      "value": "View Attachments"
    }
  ],
  "jira.jira-issue-create.email.body.view.components": [
    {
      "type": 0,
      "value": "View Components"
    }
  ],
  "jira.jira-issue-create.email.body.view.description": [
    {
      "type": 0,
      "value": "View description"
    }
  ],
  "jira.jira-issue-create.email.body.view.environment": [
    {
      "type": 0,
      "value": "View environment"
    }
  ],
  "jira.jira-issue-create.email.body.view.labels": [
    {
      "type": 0,
      "value": "View labels"
    }
  ],
  "jira.jira-issue-create.email.body.view.work.type": [
    {
      "type": 0,
      "value": "View Work Type"
    }
  ],
  "jira.jira-issue-create.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " created "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira.jira-issue-create.message-template.text.body.common.issue.key": [
    {
      "type": 0,
      "value": "Key"
    }
  ],
  "jira.jira-issue-create.message-template.text.body.common.summary": [
    {
      "type": 0,
      "value": "Summary"
    }
  ],
  "jira.jira-issue-create.message-template.text.email.body.created": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " created a work item"
    }
  ],
  "jira.jira-issue-create.text.body.common.assignee": [
    {
      "type": 0,
      "value": "Assignee"
    }
  ],
  "jira.jira-issue-create.text.body.common.attachments": [
    {
      "type": 0,
      "value": "Attachments"
    }
  ],
  "jira.jira-issue-create.text.body.common.created": [
    {
      "type": 0,
      "value": "Created"
    }
  ],
  "jira.jira-issue-create.text.body.common.description": [
    {
      "type": 0,
      "value": "Description"
    }
  ],
  "jira.jira-issue-create.text.body.common.priority": [
    {
      "type": 0,
      "value": "Priority"
    }
  ],
  "jira.jira-issue-create.text.body.common.reporter": [
    {
      "type": 0,
      "value": "Reporter"
    }
  ],
  "jira.jira-issue-create.text.body.common.status": [
    {
      "type": 0,
      "value": "Status"
    }
  ],
  "jira.jira-issue-create.text.body.common.work.type": [
    {
      "type": 0,
      "value": "Work type"
    }
  ],
  "jira.jira-issue-delete.message-template.email.body.workitem.deleted": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " deleted this work item at "
    },
    {
      "type": 1,
      "value": "timestamp"
    }
  ],
  "jira.jira-issue-delete.message-template.redacted.email.body.workitem.deleted": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "deleted"
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 0,
      "value": " a work item"
    }
  ],
  "jira.jira-issue-delete.message-template.text.deleted.work.item": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " deleted "
    },
    {
      "type": 1,
      "value": "workItemKey"
    },
    {
      "type": 0,
      "value": ":"
    }
  ],
  "jira.jira-issue-filter-subscription.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] Subscription: "
    },
    {
      "type": 1,
      "value": "filterName"
    }
  ],
  "jira.jira-issue-filter-subscription.message-template.text.email.body.header": [
    {
      "type": 0,
      "value": "Work Item Subscription"
    }
  ],
  "jira.jira-issue-filter-subscription.message-template.text.email.body.header.filter-name": [
    {
      "type": 0,
      "value": "Filter: "
    },
    {
      "type": 1,
      "value": "filterName"
    },
    {
      "type": 1,
      "value": "count"
    }
  ],
  "jira.jira-issue-filter-subscription.message-template.text.email.body.header.subscriber-info": [
    {
      "type": 0,
      "value": "Subscriber: "
    },
    {
      "type": 1,
      "value": "subscriberName"
    }
  ],
  "jira.jira-issue-mention.email.body.colon.no-translate": [
    {
      "type": 0,
      "value": ": "
    }
  ],
  "jira.jira-issue-mention.email.body.mentioned.you": [
    {
      "type": 0,
      "value": " mentioned you "
    }
  ],
  "jira.jira-issue-mention.email.body.onWorkItem.you": [
    {
      "type": 0,
      "value": " on a work item "
    }
  ],
  "jira.jira-issue-mention.email.body.spaces.no-translate": [
    {
      "type": 0,
      "value": "&nbsp;&nbsp;"
    }
  ],
  "jira.jira-issue-mention.email.body.view.work.item": [
    {
      "type": 0,
      "value": " View work item "
    }
  ],
  "jira.jira-issue-mention.email.subject.mention": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " mentioned you on "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira.jira-issue-mention.push.mentioned.you": [
    {
      "type": 0,
      "value": "📣 "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " mentioned you on "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "jira.jira-issue-mention.push.mentioned.you.redacted": [
    {
      "type": 0,
      "value": "📣 "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " mentioned you on this work item"
    }
  ],
  "jira.jira-issue-mention.text.body.addComment": [
    {
      "type": 0,
      "value": " Add Comment: "
    }
  ],
  "jira.jira-issue-mention.text.body.manage.notifications": [
    {
      "type": 0,
      "value": "Manage Notifications"
    }
  ],
  "jira.jira-issue-mention.text.body.mention.hint": [
    {
      "type": 0,
      "value": " Hint: You can mention someone in a work item description or comment by typing  \"@\" in front of their username."
    }
  ],
  "jira.jira-issue-mention.text.body.mentioned": [
    {
      "type": 0,
      "value": " mentioned you on "
    }
  ],
  "jira.jira-issue-mention.text.body.sentby": [
    {
      "type": 0,
      "value": "This message was sent by Atlassian Jira"
    }
  ],
  "jira.jira-issue-mention.text.body.unassigned": [
    {
      "type": 0,
      "value": "Unassigned"
    }
  ],
  "jira.jira-issue-mention.text.body.viewOnline": [
    {
      "type": 0,
      "value": " View Online: "
    }
  ],
  "jira.jira-issue-moved.message-template.email.body.created": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "moved a work item"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-moved.message-template.text.email.body": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " moved "
    },
    {
      "type": 1,
      "value": "workItemKey"
    }
  ],
  "jira.jira-issue-notify.email.body.heading": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "shared"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": " a work item with you"
    }
  ],
  "jira.jira-issue-notify.email.subject.notify": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " shared \""
    },
    {
      "type": 1,
      "value": "issueKey"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "summary"
    },
    {
      "type": 0,
      "value": "\" with you"
    }
  ],
  "jira.jira-issue-reopened.message-template.email.body.reopened": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "reopened a work item"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-reopened.message-template.text.subject": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " reopened "
    },
    {
      "type": 1,
      "value": "workItemKey"
    }
  ],
  "jira.jira-issue-resolved.message-template.text.subject": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " resolved "
    },
    {
      "type": 1,
      "value": "workItemKey"
    }
  ],
  "jira.jira-issue-search.message-template.email.subject.filter.results": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " shared the filter \""
    },
    {
      "type": 1,
      "value": "filterName"
    },
    {
      "type": 0,
      "value": "\" with you"
    }
  ],
  "jira.jira-issue-search.message-template.email.subject.search.results": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " shared a search result with you"
    }
  ],
  "jira.jira-issue-share.message-template.email.header.filter": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "shared"
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 0,
      "value": " a filter with you."
    }
  ],
  "jira.jira-issue-share.message-template.email.header.results": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "shared"
        }
      ],
      "type": 8,
      "value": "strong"
    },
    {
      "type": 0,
      "value": " a search result with you."
    }
  ],
  "jira.jira-issue-share.message-template.email.view.results": [
    {
      "type": 0,
      "value": "View search results"
    }
  ],
  "jira.jira-issue-share.message-template.text.shared.filter.heading": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " shared a filter with you"
    }
  ],
  "jira.jira-issue-share.message-template.text.shared.results.heading": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " shared a search result with you"
    }
  ],
  "jira.jira-issue-share.message-template.text.shared.with.others": [
    {
      "type": 0,
      "value": "Also shared with"
    }
  ],
  "jira.jira-issue-test.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "[JIRA] test template "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira.jira-issue-update.message-template.email.body.created": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "updated a work item"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-update.message-template.email.body.worklog.change.by": [
    {
      "type": 0,
      "value": "Change by: "
    }
  ],
  "jira.jira-issue-update.message-template.text.subject": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " updated "
    },
    {
      "type": 1,
      "value": "workItemKey"
    }
  ],
  "jira.jira-issue-work-started.message-template.email.body.created": [
    {
      "type": 1,
      "value": "actioner"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "made "
        },
        {
          "offset": 0,
          "options": {
            "one": {
              "value": [
                {
                  "type": 7
                },
                {
                  "type": 0,
                  "value": " update"
                }
              ]
            },
            "other": {
              "value": [
                {
                  "type": 7
                },
                {
                  "type": 0,
                  "value": " updates"
                }
              ]
            }
          },
          "pluralType": "cardinal",
          "type": 6,
          "value": "numberOfUpdates"
        }
      ],
      "type": 8,
      "value": "strong"
    }
  ],
  "jira.jira-issue-work-started.message-template.text.header": [
    {
      "type": 0,
      "value": "Work on "
    },
    {
      "type": 1,
      "value": "workItemKey"
    },
    {
      "type": 0,
      "value": " started by "
    },
    {
      "type": 1,
      "value": "user"
    }
  ],
  "jira.jira-issue-work-stopped.message-template.text.header": [
    {
      "type": 0,
      "value": "Work on "
    },
    {
      "type": 1,
      "value": "workItemKey"
    },
    {
      "type": 0,
      "value": " stopped by "
    },
    {
      "type": 1,
      "value": "user"
    }
  ],
  "jira.jira-issue-worklog-delete.message-template.email.redacted.body.title": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "deleted a worklog"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": " on a work item"
    }
  ],
  "jira.jira-issue-worklog-delete.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " deleted a worklog on "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira.jira-issue-worklog-deleted.message-template.text.email.body.deleted.worklog": [
    {
      "type": 0,
      "value": "The following worklog was deleted:"
    }
  ],
  "jira.jira-issue-worklog-update.message-template.email.body.title": [
    {
      "children": [
        {
          "type": 0,
          "value": " made 2 updates"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "edited 1 work log"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "jira.jira-issue-worklog-update.message-template.email.redacted.body.title": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "updated a worklog"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": " on a work item"
    }
  ],
  "jira.jira-issue-worklog-updated.message-template.email.body.description": [
    {
      "type": 0,
      "value": "Description"
    }
  ],
  "jira.jira-issue-worklog-updated.message-template.email.body.timeLogged": [
    {
      "type": 0,
      "value": "Time logged"
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.body.description": [
    {
      "type": 0,
      "value": "Description: "
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.body.logTitle": [
    {
      "type": 0,
      "value": "Work log"
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.body.timelogged": [
    {
      "type": 0,
      "value": "Time logged: "
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.body.title": [
    {
      "children": [
        {
          "type": 0,
          "value": " made 2 updates"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": " and "
    },
    {
      "children": [
        {
          "type": 0,
          "value": "added 1 work log"
        }
      ],
      "type": 8,
      "value": "b"
    },
    {
      "type": 0,
      "value": "."
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.body.updateTitle": [
    {
      "type": 0,
      "value": "Updates"
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.body.worklog.change.by": [
    {
      "type": 0,
      "value": "Change by: "
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.body.worklog.entry": [
    {
      "type": 0,
      "value": "Worklog entry:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.email.subject.no-translate": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " logged work on "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.change.by": [
    {
      "type": 0,
      "value": "Change by"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.issue.created.on": [
    {
      "type": 0,
      "value": "Created on:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.issue.edited.by": [
    {
      "type": 0,
      "value": "Edited by:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.issue.edited.on": [
    {
      "type": 0,
      "value": "Edited on:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.issue.key": [
    {
      "type": 0,
      "value": "Key:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.issue.worklog.entry.label": [
    {
      "type": 0,
      "value": "Worklog entry"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.project": [
    {
      "type": 0,
      "value": "Project:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.start.date": [
    {
      "type": 0,
      "value": "Start Date:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.time.spent": [
    {
      "type": 0,
      "value": "Time Spent:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.url": [
    {
      "type": 0,
      "value": "URL:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.view.work.description": [
    {
      "type": 0,
      "value": "View work description"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.work.type": [
    {
      "type": 0,
      "value": "Work type:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.worklog.body": [
    {
      "type": 0,
      "value": "Work Description:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.worklog.description": [
    {
      "type": 0,
      "value": "Worklog Description:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.worklog.description.was": [
    {
      "type": 0,
      "value": "Worklog Description was:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.worklog.id": [
    {
      "type": 0,
      "value": "Worklog Id:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.worklog.time.estimated": [
    {
      "type": 0,
      "value": "Remaining Estimate:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.common.worklog.time.spent": [
    {
      "type": 0,
      "value": "Worklog Time Spent:"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.body.redacted.logged.by": [
    {
      "type": 1,
      "value": "worklogEntry"
    },
    {
      "type": 0,
      "value": " logged by "
    },
    {
      "type": 1,
      "value": "actioner"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.email.body.created": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " logged work on "
    },
    {
      "type": 1,
      "value": "issueKey"
    }
  ],
  "jira.jira-issue-worklogged.message-template.text.email.body.tracking": [
    {
      "type": 0,
      "value": "Issue time Tracking"
    }
  ],
  "jira.jira-project-deletion-reminder.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "header"
    },
    {
      "type": 0,
      "value": " "
    }
  ],
  "jira.jira-project-event-archived-restore.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": ") ("
    },
    {
      "type": 1,
      "value": "projectKey"
    },
    {
      "type": 0,
      "value": ") Project restored from archive "
    }
  ],
  "jira.jira-project-event-archived.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": ") ("
    },
    {
      "type": 1,
      "value": "projectKey"
    },
    {
      "type": 0,
      "value": ") Project archived"
    }
  ],
  "jira.jira-project-event-delete-failed.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": ") ("
    },
    {
      "type": 1,
      "value": "projectKey"
    },
    {
      "type": 0,
      "value": ") Could not permanently delete the project "
    }
  ],
  "jira.jira-project-event-deleted-restore.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": ") ("
    },
    {
      "type": 1,
      "value": "projectKey"
    },
    {
      "type": 0,
      "value": ") Project restored from trash"
    }
  ],
  "jira.jira-project-event-deleted.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": ") ("
    },
    {
      "type": 1,
      "value": "projectKey"
    },
    {
      "type": 0,
      "value": ") Project moved to trash"
    }
  ],
  "jira.jira-project-event-user-invited.message-template.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] ("
    },
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": ") invited you to ("
    },
    {
      "type": 1,
      "value": "projectName"
    },
    {
      "type": 0,
      "value": ")  "
    }
  ],
  "jira.project.event.restore.admin.permission.generic.message": [
    {
      "type": 0,
      "value": "Only Jira admins can restore the project."
    }
  ],
  "jira.user.premium-plan.requested.email.explaination": [
    {
      "type": 0,
      "value": "You can try Jira Premium for free with no commitment. This trial period includes access\n\t\t\t\tto our advanced planning features plus other great functionalities that unlock new ways\n\t\t\t\tof working for your teams."
    }
  ],
  "jira.user.premium-plan.requested.email.greeting": [
    {
      "type": 0,
      "value": "Hi,"
    }
  ],
  "jira.user.premium-plan.requested.email.learn-more": [
    {
      "type": 0,
      "value": "Learn more about Jira Premium."
    }
  ],
  "jira.user.premium-plan.requested.email.start-trial": [
    {
      "type": 0,
      "value": "Start your Jira Premium trial"
    }
  ],
  "jira.user.premium-plan.requested.email.subject": [
    {
      "type": 0,
      "value": "[JIRA] "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " has requested a free trial of Jira Premium"
    }
  ],
  "jira.user.premium-plan.requested.email.text": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " has requested a free trial of Jira Premium."
    }
  ],
  "note.added.by.user": [
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": " added this note"
    }
  ],
  "post-office.jira-pulsar.jira-issue-approval.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-archived.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-closed.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-comment-delete.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-comment-update.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-delete.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-generic.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-moved.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-resolved.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.jira-pulsar.jira-issue-update.sample-mobile-notification-message.no-translate": [
    {
      "type": 0,
      "value": "Hello, this is a mobile push notification test from post office."
    }
  ],
  "post-office.placement.jira-top-banner.primary-message": [
    {
      "type": 0,
      "value": "As we roll out 'work' as the new term for items tracked in Jira, you may still see 'issue' in some areas."
    }
  ],
  "postoffice.jira.issue.common.avatar.no-translate": [
    {
      "type": 0,
      "value": "Avatar"
    }
  ],
  "postoffice.jira.issue.metioned.avatar.no-translate": [
    {
      "type": 0,
      "value": "Avatar"
    }
  ],
  "postoffice.jira.issue.metioned.get-android.no-translate": [
    {
      "type": 0,
      "value": " Android "
    }
  ],
  "postoffice.jira.issue.metioned.get-ios.no-translate": [
    {
      "type": 0,
      "value": " iOS. "
    }
  ],
  "postoffice.jira.issue.metioned.get-or.no-translate": [
    {
      "type": 0,
      "value": " or"
    }
  ],
  "postoffice.jira.issue.work.started.avatar.no-translate": [
    {
      "type": 0,
      "value": "Avatar"
    }
  ],
  "postoffice.jira.issue.work.stopped.avatar.no-translate": [
    {
      "type": 0,
      "value": "Avatar"
    }
  ],
  "postoffice.jira.issue.worklogged.avatar.no-translate": [
    {
      "type": 0,
      "value": "Avatar"
    }
  ],
  "postoffice.jira.project.event.avatar.no-translate": [
    {
      "type": 0,
      "value": "avatar"
    }
  ],
  "postoffice.jira.project.event.blueprint.no-translate": [
    {
      "type": 0,
      "value": "blueprintLogo"
    }
  ],
  "postoffice.jira.project.event.globe.no-translate": [
    {
      "type": 0,
      "value": "globeLogo"
    }
  ],
  "postoffice.jira.project.event.logo.no-translate": [
    {
      "type": 0,
      "value": "logo"
    }
  ],
  "postoffice.jira.project.event.write.no-translate": [
    {
      "type": 0,
      "value": "writeLogo"
    }
  ],
  "push.mobile.issue.approval.requested.description": [
    {
      "type": 0,
      "value": "on "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.approval.requested.title": [
    {
      "type": 0,
      "value": "Approval requested"
    }
  ],
  "push.mobile.issue.assignment.description.assigned.others": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " assigned "
    },
    {
      "type": 1,
      "value": "summary"
    },
    {
      "type": 0,
      "value": " to "
    },
    {
      "type": 1,
      "value": "assignee"
    }
  ],
  "push.mobile.issue.assignment.description.assigned.you": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " assigned you to "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.assignment.description.unassigned.others": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " unassigned "
    },
    {
      "type": 1,
      "value": "oldAssignee"
    },
    {
      "type": 0,
      "value": " from "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.assignment.description.unassigned.you": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " unassigned you from "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.attachment.added.description": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " added an attachment to "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.attachment.added.description.redacted": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " added an attachment"
    }
  ],
  "push.mobile.issue.comment.added.android.description": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " commented on "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.comment.added.ios.body.redacted": [
    {
      "type": 0,
      "value": "commented on this work item"
    }
  ],
  "push.mobile.issue.comment.added.ios.subtitle": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " commented on "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.comment.mention.android.description": [
    {
      "type": 0,
      "value": "📣 "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " mentioned you on "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.comment.mention.android.description.redacted": [
    {
      "type": 0,
      "value": "📣 "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " mentioned you in a comment on "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.comment.mention.ios.subtitle": [
    {
      "type": 0,
      "value": "📣 "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " mentioned you on "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.comment.mention.ios.subtitle.redacted": [
    {
      "type": 0,
      "value": "📣 "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " mentioned you in a comment on this work item"
    }
  ],
  "push.mobile.issue.create.android.description": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " created a work item"
    }
  ],
  "push.mobile.issue.create.android.title": [
    {
      "type": 0,
      "value": "Work item Created"
    }
  ],
  "push.mobile.issue.create.ios.body.redacted": [
    {
      "type": 0,
      "value": "Open to view work item details"
    }
  ],
  "push.mobile.issue.create.ios.subtitle": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " created a work item"
    }
  ],
  "push.mobile.issue.transition.description.resolved": [
    {
      "type": 0,
      "value": "✔ "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " resolved "
    },
    {
      "type": 1,
      "value": "summary"
    }
  ],
  "push.mobile.issue.transition.description.resolved.redacted": [
    {
      "type": 0,
      "value": "✔ "
    },
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " resolved this work item"
    }
  ],
  "push.mobile.issue.transition.ios.description": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " moved "
    },
    {
      "type": 1,
      "value": "summary"
    },
    {
      "type": 0,
      "value": " to "
    },
    {
      "type": 1,
      "value": "status"
    }
  ],
  "push.mobile.issue.transition.ios.description.redacted": [
    {
      "type": 1,
      "value": "user"
    },
    {
      "type": 0,
      "value": " moved "
    },
    {
      "type": 1,
      "value": "summary"
    },
    {
      "type": 0,
      "value": " to "
    },
    {
      "type": 1,
      "value": "status"
    }
  ],
  "st-office.placement.jira-top-banner.primary-cta-text": [
    {
      "type": 0,
      "value": "Read about this terminology change"
    }
  ],
  "template..project-deletion-reminder.restore-link.multiple-project": [
    {
      "type": 0,
      "value": " Restore"
    }
  ],
  "template..project-deletion-reminder.restore-link.single-project": [
    {
      "type": 0,
      "value": " Restore"
    }
  ],
  "template..project-deletion-reminder.trash-link.multiple-project": [
    {
      "type": 0,
      "value": " Trash"
    }
  ],
  "template.project-deletion-reminder.generic-message.mutiple-projects": [
    {
      "type": 1,
      "value": "projectCount"
    },
    {
      "type": 0,
      "value": " projects will be permanently deleted in 7 days "
    }
  ],
  "template.project-deletion-reminder.generic-message.single-project": [
    {
      "type": 0,
      "value": "The project will be permanently deleted in 7 days "
    }
  ],
  "template.project-deletion-reminder.view-more-projects-message": [
    {
      "type": 0,
      "value": "+ "
    },
    {
      "type": 1,
      "value": "remainingProjectCount"
    },
    {
      "type": 0,
      "value": " other projects currently in "
    }
  ],
  "template.project-event-user-invited.invited-message": [
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": "  invited you to  "
    }
  ],
  "template.project-event-user-invited.project-name": [
    {
      "type": 0,
      "value": "  "
    },
    {
      "type": 1,
      "value": "projectName"
    }
  ],
  "template.project.event.admins.permission.message": [
    {
      "type": 0,
      "value": "Only Jira admins can restore the project."
    }
  ],
  "template.project.event.project.archive.restore.body.template": [
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": " restored this project from archive on "
    },
    {
      "type": 1,
      "value": "eventTime"
    }
  ],
  "template.project.event.project.archived.body.template": [
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": " archived this project on "
    },
    {
      "type": 1,
      "value": "eventTime"
    }
  ],
  "template.project.event.project.archived.body.template.archive-link": [
    {
      "type": 0,
      "value": "archive "
    }
  ],
  "template.project.event.project.archived.body.template.archive.forever": [
    {
      "type": 0,
      "value": " forever"
    }
  ],
  "template.project.event.project.archived.body.template.generic.message": [
    {
      "type": 0,
      "value": "This project and its issues, components, attachments, and versions will be available in the "
    }
  ],
  "template.project.event.project.deleted.body.template": [
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": " moved this project to trash on "
    },
    {
      "type": 1,
      "value": "eventTime"
    }
  ],
  "template.project.event.project.deleted.body.template.archive.forever": [
    {
      "type": 0,
      "value": "forever"
    }
  ],
  "template.project.event.project.deleted.body.template.generic.message": [
    {
      "type": 0,
      "value": "This project and its issues, components, attachments, and versions will be available in the "
    }
  ],
  "template.project.event.project.deleted.body.template.trash-link": [
    {
      "type": 0,
      "value": "trash "
    }
  ],
  "template.project.event.project.deleted.failure.body.template.generic.message": [
    {
      "type": 0,
      "value": "It's probably a temporary problem. "
    }
  ],
  "template.project.event.project.deleted.failure.body.template.trash-link": [
    {
      "type": 0,
      "value": " Go to trash "
    }
  ],
  "template.project.event.project.deleted.failurebody.template.archive.forever": [
    {
      "type": 0,
      "value": " and try again. If it persists, please contact support. "
    }
  ],
  "template.project.event.project.deleted.restore.body.template": [
    {
      "type": 0,
      "value": " "
    },
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": " restored this project from trash on "
    },
    {
      "type": 1,
      "value": "eventTime"
    }
  ],
  "template.project.event.project.deletion.failure.body.template": [
    {
      "type": 0,
      "value": "We couldn't delete this project"
    }
  ],
  "template.project.event.project.privacy.contactus.template": [
    {
      "type": 0,
      "value": "Contact us"
    }
  ],
  "template.project.event.project.privacy.copyright.template": [
    {
      "type": 0,
      "value": "Copyright 2025 Atlassian Pty Ltd. All rights reserved."
    }
  ],
  "template.project.event.project.privacy.location.template": [
    {
      "type": 0,
      "value": "We are located at 341 George Street, Sydney, NSW, 2000, Australia."
    }
  ],
  "template.project.event.project.privacy.policy.template": [
    {
      "type": 0,
      "value": "Privacy policy"
    }
  ],
  "template.project.event.project.privacy.policy.template.dot": [
    {
      "type": 0,
      "value": "."
    }
  ],
  "template.project.event.project.user.invited.and.message": [
    {
      "type": 0,
      "value": " and"
    }
  ],
  "template.project.event.project.user.invited.collborate.message": [
    {
      "type": 0,
      "value": "Collaborate, align, and deliver work, all in"
    }
  ],
  "template.project.event.project.user.invited.collect.message": [
    {
      "type": 0,
      "value": "Collect and send work to other teams or stakeholders using "
    }
  ],
  "template.project.event.project.user.invited.forms.message": [
    {
      "type": 0,
      "value": "  forms"
    }
  ],
  "template.project.event.project.user.invited.join.link": [
    {
      "type": 0,
      "value": "Join the Project"
    }
  ],
  "template.project.event.project.user.invited.learn.more.message": [
    {
      "type": 0,
      "value": "Learn more about Jira "
    }
  ],
  "template.project.event.project.user.invited.list.message": [
    {
      "type": 0,
      "value": " list, board, calendar"
    }
  ],
  "template.project.event.project.user.invited.one.place.message": [
    {
      "type": 0,
      "value": " one place"
    }
  ],
  "template.project.event.project.user.invited.timeline.message": [
    {
      "type": 0,
      "value": " timeline"
    }
  ],
  "template.project.event.project.user.invited.use.jira.generic.message": [
    {
      "type": 0,
      "value": "Use Jira to..."
    }
  ],
  "template.project.event.project.user.invited.view.work.message": [
    {
      "type": 0,
      "value": "View work your way using the"
    }
  ],
  "template.project.event.project.user.invited.views.message": [
    {
      "type": 0,
      "value": " views"
    }
  ],
  "template.subscription.filter.count.issue-term-refresh": [
    {
      "type": 0,
      "value": " ("
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " work item"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " work items"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "total"
    },
    {
      "type": 0,
      "value": ")"
    }
  ],
  "template.subscription.filter.footer.edit.subscription": [
    {
      "type": 0,
      "value": "here"
    }
  ],
  "template.subscription.filter.html.subset.issue-term-refresh": [
    {
      "type": 0,
      "value": "Displaying "
    },
    {
      "type": 1,
      "value": "actual"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "total"
    },
    {
      "type": 0,
      "value": " matched work items. "
    }
  ],
  "template.subscription.filter.html.view.filter.work-items": [
    {
      "type": 0,
      "value": "View all of them"
    }
  ],
  "template.subscription.filter.redacted.html.edit": [
    {
      "type": 0,
      "value": "You may edit this subscription "
    }
  ],
  "template.subscription.filter.redacted.html.view.filter": [
    {
      "type": 0,
      "value": "Details are hidden to protect sensitive data."
    }
  ],
  "template.subscription.filter.redacted.html.view.filter.link": [
    {
      "type": 0,
      "value": "View the filter"
    }
  ],
  "template.subscription.filter.subcount.issue-term-refresh": [
    {
      "type": 0,
      "value": "  ("
    },
    {
      "type": 1,
      "value": "actual"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "total"
    },
    {
      "type": 0,
      "value": " work items)"
    }
  ],
  "template.subscription.filter.text.count.issue-term-refresh": [
    {
      "type": 0,
      "value": " ("
    },
    {
      "offset": 0,
      "options": {
        "one": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " work item"
            }
          ]
        },
        "other": {
          "value": [
            {
              "type": 7
            },
            {
              "type": 0,
              "value": " work items"
            }
          ]
        }
      },
      "pluralType": "cardinal",
      "type": 6,
      "value": "total"
    },
    {
      "type": 0,
      "value": ")"
    }
  ],
  "template.subscription.filter.text.subcount.issue-term-refresh": [
    {
      "type": 0,
      "value": " ("
    },
    {
      "type": 1,
      "value": "actual"
    },
    {
      "type": 0,
      "value": " of "
    },
    {
      "type": 1,
      "value": "total"
    },
    {
      "type": 0,
      "value": " work items)"
    }
  ],
  "user.avatar.alt": [
    {
      "type": 0,
      "value": "User avatar"
    }
  ]
}