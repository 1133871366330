import React from 'react';
import type OptimizeFieldConfigurationSchemePageType from '@atlassian/jira-optimize-field-configuration-scheme-page/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyOptimizeFieldConfigurationSchemePagePage = lazyForPaint<
	typeof OptimizeFieldConfigurationSchemePageType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-jira-admin-pages-optimize-field-configuration-scheme-page", jiraSpaEntry: "async-jira-admin-pages-optimize-field-configuration-scheme-page" */ '@atlassian/jira-optimize-field-configuration-scheme-page'
		),
	),
);

export const OptimizeFieldConfigurationSchemePage = () => (
	<LazyPage
		Page={LazyOptimizeFieldConfigurationSchemePagePage}
		pageId="jira-admin-pages-optimize-field-configuration-scheme-page"
		shouldShowSpinner
	/>
);
