import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/createEntry.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { softwareReportsVelocityRoute } from '@atlassian/jira-router-routes-next-gen-routes/src/softwareReportsVelocityRoute.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getTMPReportsCommonResource } from './common/ui/resources/reports/index.tsx';
import VelocityPage, {
	LazyNextGenVelocityReport,
} from './ui/software/reports/velocity/index-old.tsx';
import { velocityReportPageEntrypoint } from './ui/software/reports/velocity/index.tsx';

export const softwareReportsVelocityRouteEntry = createEntry(softwareReportsVelocityRoute, () => ({
	group: 'software',
	ufoName: 'software-reports.velocity-report',
	component: fg('fun-1600-migrate-software-reports-velocity-route') ? undefined : VelocityPage,
	layout: softwareProjectLayoutNoOnboarding,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
	},
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getTMPReportsCommonResource(),
	],
	forPaint: fg('fun-1600-migrate-software-reports-velocity-route')
		? undefined
		: [LazyAtlassianNavigation, LazyNextGenVelocityReport],
	entryPoint: () =>
		fg('fun-1600-migrate-software-reports-velocity-route')
			? velocityReportPageEntrypoint
			: undefined,
}));
