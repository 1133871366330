/**
 * @generated SignedSource<<2b36fb9c008865716f90451e7afab3a4>>
 * @relayHash f575c60745edae3b480014983fd26a23
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f983316fe2b032d677f7f5474f645ff73dac7daf268c4eaf3a14b1ca4424deeb

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_timelineQuery } from './src_timelineQuery.graphql';

import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isReparentingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-reparenting.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: PreloadableConcreteRequest<src_timelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f983316fe2b032d677f7f5474f645ff73dac7daf268c4eaf3a14b1ca4424deeb",
    "metadata": {},
    "name": "src_timelineQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};

export default node;
