import type { UserPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions.tsx';
import type { State, Value } from './types.tsx';

export const store = createStore<State, Actions>({
	name: 'user-preferences',
	initialState: {
		preferences: {},
	},
	actions,
});

const getUserPreference = (state: State, userPreferenceKey: UserPreferences): Value =>
	state.preferences[userPreferenceKey];

export const useUserPreferencesStoreValue = createHook(store, {
	selector: getUserPreference,
});

export const useUserPreferencesStoreActions = createHook(store, {
	selector: null,
});
