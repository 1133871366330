import { globalSettingsContactAdministratorRouteEntry } from '@atlassian/jira-router-routes-admin-pages-contact-administrator-entries/src/globalSettingsContactAdministratorRouteEntry.tsx';
import { emailNotificationsLogAccessRouteEntry } from '@atlassian/jira-router-routes-admin-pages-email-notifications-log-entries/src/emailNotificationsLogAccessRouteEntry.tsx';
import { externalImportRouteEntry } from '@atlassian/jira-router-routes-admin-pages-external-import-entries/src/externalImportRouteEntry.tsx';
import { globalSettingsGlobalPermissionsRouteEntry } from '@atlassian/jira-router-routes-admin-pages-global-permissions-entries/src/globalSettingsGlobalPermissionsRouteEntry.tsx';
import { globalSettingsIssueHierarchyRedirectRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueHierarchyRedirectRouteEntry.tsx';
import { globalSettingsIssueHierarchyRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueHierarchyRouteEntry.tsx';
import { globalSettingsIssueTypesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueTypesRouteEntry.tsx';
import { globalSettingsIssueTypesSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-issue-types-entries/src/globalSettingsIssueTypesSchemesRouteEntry.tsx';
import { productsJsmAuthenticationSettingsRouteEntry } from '@atlassian/jira-router-routes-admin-pages-jsm-authentication-settings-entries/src/productsJsmAuthenticationSettingsRouteEntry.tsx';
import { productsJsmCustomerAccessRouteEntry } from '@atlassian/jira-router-routes-admin-pages-jsm-customer-access-entries/src/productsJsmCustomerAccessRouteEntry.tsx';
import { globalSettingsIssuesNewIssueSearchRouteEntry } from '@atlassian/jira-router-routes-admin-pages-new-issue-features-entries/src/globalSettingsIssuesNewIssueSearchRouteEntry.tsx';
import { globalSettingsSystemBulkMailRouteEntry } from '@atlassian/jira-router-routes-admin-pages-outgoing-mail-entries/src/globalSettingsSystemBulkMailRouteEntry.tsx';
import { globalSettingsPerformanceCustomFieldRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceCustomFieldRouteEntry.tsx';
import { globalSettingsPerformanceIssueArchivalRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceIssueArchivalRouteEntry.tsx';
import { globalSettingsPerformanceMetricDetailRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceMetricDetailRouteEntry.tsx';
import { globalSettingsPerformanceOverviewRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceOverviewRouteEntry.tsx';
import { globalSettingsPerformanceProjectCleanupRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceProjectCleanupRouteEntry.tsx';
import { globalSettingsPerformanceProjectRoleActorRouteEntry } from '@atlassian/jira-router-routes-admin-pages-performance-entries/src/globalSettingsPerformanceProjectRoleActorRouteEntry.tsx';
import { globalSettingsEditPermissionSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-permission-schemes-entries/src/globalSettingsEditPermissionSchemesRouteEntry.tsx';
import { globalSettingsPermissionSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-permission-schemes-entries/src/globalSettingsPermissionSchemesRouteEntry.tsx';
import { globalSettingsIssuesPrioritiesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-priorities-entries/src/globalSettingsIssuesPrioritiesRouteEntry.tsx';
import { globalSettingsIssueFieldsManagementRouteEntry } from '@atlassian/jira-router-routes-admin-pages-fields-entries/src/globalSettingsIssueFieldsManagementRouteEntry.tsx';
import { globalSettingsIssuesPrioritySchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-priorities-entries/src/globalSettingsIssuesPrioritySchemesRouteEntry.tsx';
import { globalSettingsFormDataConnectionsRouteEntry } from '@atlassian/jira-router-routes-admin-pages-proforma-entries/src/globalSettingsFormDataConnectionsRouteEntry.tsx';
import { globalSettingsIssueFieldSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-screens-entries/src/globalSettingsIssueFieldSchemesRouteEntry.tsx';
import { globalSettingsIssueFieldScreensRouteEntry } from '@atlassian/jira-router-routes-admin-pages-screens-entries/src/globalSettingsIssueFieldScreensRouteEntry.tsx';
import { globalSettingsIssueTypesScreenSchemesRouteEntry } from '@atlassian/jira-router-routes-admin-pages-screens-entries/src/globalSettingsIssueTypesScreenSchemesRouteEntry.tsx';
import { systemConfigurationRedirectRouteEntry } from '@atlassian/jira-router-routes-admin-pages-system-configuration-entries/src/systemConfigurationRedirectRouteEntry.tsx';
import { systemConfigurationRouteEntry } from '@atlassian/jira-router-routes-admin-pages-system-configuration-entries/src/systemConfigurationRouteEntry.tsx';
import { globalSettingsSystemUiAnnouncementBannerRouteEntry } from '@atlassian/jira-router-routes-admin-pages-system-entries/src/globalSettingsSystemUiAnnouncementBannerRouteEntry.tsx';
import { globalSettingsIssueWorkflow1RouteEntry } from '@atlassian/jira-router-routes-admin-pages-workflows-entries/src/globalSettingsIssueWorkflow1RouteEntry.tsx';
import { globalSettingsIssueWorkflowEditRouteEntry } from '@atlassian/jira-router-routes-admin-pages-workflows-entries/src/globalSettingsIssueWorkflowEditRouteEntry.tsx';
import { productsJcsCustomerAccessRouteEntry } from '@atlassian/jira-router-routes-admin-pages-jcs-customer-access-entries/src/productsJcsCustomerAccessRouteEntry.tsx';
import { productsJcsFormFieldsRouteEntry } from '@atlassian/jira-router-routes-admin-pages-jcs-form-fields-entries/src/productsJcsFormFieldsRouteEntry.tsx';
import { arjCalendarEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjCalendarEmbedRouteEntry.tsx';
import { arjDependenciesEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjDependenciesEmbedRouteEntry.tsx';
import { arjIncrementEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjIncrementEmbedRouteEntry.tsx';
import { arjSummaryEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjSummaryEmbedRouteEntry.tsx';
import { arjTimelineEmbedRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-entries/src/arjTimelineEmbedRouteEntry.tsx';
import { legacyPlanPageRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/legacyPlanPageRedirectRouteEntry.tsx';
import { legacyPlanReportPageRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/legacyPlanReportPageRedirectRouteEntry.tsx';
import { planExportPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planExportPageRouteEntry.tsx';
import { planPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planPageRouteEntry.tsx';
import { planPageWithoutScenarioIdRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planPageWithoutScenarioIdRouteEntry.tsx';
import { planReportPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planReportPageRouteEntry.tsx';
import { planSetupPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planSetupPageRouteEntry.tsx';
import { softwareAdvancedRoadmapsGettingStartedRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwareAdvancedRoadmapsGettingStartedRedirectRouteEntry.tsx';
import { softwarePlanCalendarRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanCalendarRouteEntry.tsx';
import { softwarePlanDependenciesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanDependenciesRouteEntry.tsx';
import { softwarePlanReleasesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanReleasesRouteEntry.tsx';
import { softwarePlanSettingsAutoSchedulerRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsAutoSchedulerRouteEntry.tsx';
import { softwarePlanSettingsCustomFieldsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsCustomFieldsRouteEntry.tsx';
import { softwarePlanSettingsExclusionRulesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsExclusionRulesRouteEntry.tsx';
import { softwarePlanSettingsFindYourIssueRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsFindYourIssueRouteEntry.tsx';
import { softwarePlanSettingsIssueSourcesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsIssueSourcesRouteEntry.tsx';
import { softwarePlanSettingsPermissionsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsPermissionsRouteEntry.tsx';
import { softwarePlanSettingsPlanInfoRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsPlanInfoRouteEntry.tsx';
import { softwarePlanSettingsFeaturesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsFeaturesRouteEntry.tsx';
import { softwarePlanSettingsProgramBoardsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsProgramBoardsRouteEntry.tsx';
import { softwarePlanSettingsRemovedIssuesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsRemovedIssuesRouteEntry.tsx';
import { softwarePlanSettingsSavedViewsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsSavedViewsRouteEntry.tsx';
import { softwarePlanSettingsScenariosRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsScenariosRouteEntry.tsx';
import { softwarePlanSettingsSchedulingRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSettingsSchedulingRouteEntry.tsx';
import { softwarePlanSummaryRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanSummaryRouteEntry.tsx';
import { softwarePlanTeamsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanTeamsRouteEntry.tsx';
import { softwarePlanTimelineRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/softwarePlanTimelineRouteEntry.tsx';
import { planUpsellRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planUpsellRouteEntry.tsx';
import { planViewNotFoundRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-entries/src/planViewNotFoundRouteEntry.tsx';
import { arjSettingsDependenciesLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsDependenciesLegacyRedirectRouteEntry.tsx';
import { arjSettingsDependenciesRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsDependenciesRouteEntry.tsx';
import { arjSettingsLoadingLimitsLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsLoadingLimitsLegacyRedirectRouteEntry.tsx';
import { arjSettingsLoadingLimitsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsLoadingLimitsRouteEntry.tsx';
import { arjSettingsPermissionsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsPermissionsRouteEntry.tsx';
import { arjSettingsPermissionsRouteLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-entries/src/arjSettingsPermissionsRouteLegacyRedirectRouteEntry.tsx';
import { archivedPlansRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/archivedPlansRouteEntry.tsx';
import { arjSettingsFinancialYearPageRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/arjSettingsFinancialYearPageRouteEntry.tsx';
import { planIncrementRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/planIncrementRouteEntry.tsx';
import { planIncrementsRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/planIncrementsRouteEntry.tsx';
import { trashedPlanDirectLinkRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/trashedPlanDirectLinkRouteEntry.tsx';
import { trashPlansRouteEntry } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-entries/src/trashPlansRouteEntry.tsx';
import { archivedProjectDirectLinkRouteEntry } from '@atlassian/jira-router-routes-archived-project-direct-link-entries/src/archivedProjectDirectLinkRouteEntry.tsx';
import { archivedProjectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-archived-projects-directory-entries/src/archivedProjectsDirectoryRouteEntry.tsx';
import { archivedIssuesJsmRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJsmRouteEntry.tsx';
import { archivedIssuesJswCompanyRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJswCompanyRouteEntry.tsx';
import { archivedIssuesJswRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJswRouteEntry.tsx';
import { archivedIssuesJwmRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesJwmRouteEntry.tsx';
import { archivedIssuesRedirectRouteEntry } from '@atlassian/jira-router-routes-archives-entries/src/archivedIssuesRedirectRouteEntry.tsx';
import { browseProjectRedirectRouteEntry } from '@atlassian/jira-router-routes-browse-project-entries/src/browseProjectRedirectRouteEntry.tsx';
import { jiraBusinessAttachmentsRouteEntry } from '@atlassian/jira-router-routes-business-attachments-entries/src/jiraBusinessAttachmentsRouteEntry.tsx';
import { jiraBusinessBoardEmbedRouteEntry } from '@atlassian/jira-router-routes-business-board-entries/src/jiraBusinessBoardEmbedRouteEntry.tsx';
import { jiraBusinessBoardCustomEmbedRouteEntry } from '@atlassian/jira-router-routes-business-board-entries/src/jiraBusinessBoardCustomEmbedRouteEntry.tsx';
import { jiraBusinessBoardRouteEntry } from '@atlassian/jira-router-routes-business-board-entries/src/jiraBusinessBoardRouteEntry.tsx';
import { jiraBusinessCalendarEmbedRouteEntry } from '@atlassian/jira-router-routes-business-calendar-entries/src/jiraBusinessCalendarEmbedRouteEntry.tsx';
import { jiraBusinessCalendarRouteEntry } from '@atlassian/jira-router-routes-business-calendar-entries/src/jiraBusinessCalendarRouteEntry.tsx';
import { jiraBusinessReportsOverviewRouteEntry } from '@atlassian/jira-router-routes-business-reports-entries/src/jiraBusinessReportsOverviewRouteEntry.tsx';
import { classicBusinessOverviewCalendarRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessOverviewCalendarRouteEntry.tsx';
import { classicBusinessOverviewSummaryRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessOverviewSummaryRouteEntry.tsx';
import { classicBusinessOverviewTimelineRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessOverviewTimelineRouteEntry.tsx';
import { classicBusinessProjectApprovalsRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectApprovalsRouteEntry.tsx';
import { classicBusinessProjectFormBuilderRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormBuilderRouteEntry.tsx';
import { classicBusinessProjectFormRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormRouteEntry.tsx';
import { classicBusinessProjectFormSubmitEmbedRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormSubmitEmbedRouteEntry.tsx';
import { classicBusinessProjectFormSubmitRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormSubmitRouteEntry.tsx';
import { classicBusinessProjectFormSubmitPublicRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectFormSubmitPublicRouteEntry.tsx';
import { classicBusinessProjectPagesRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectPagesRouteEntry.tsx';
import { classicBusinessProjectSampleOnboardingRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectSampleOnboardingRouteEntry.tsx';
import { classicBusinessProjectShortcutRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectShortcutRouteEntry.tsx';
import { classicBusinessProjectViewNotFoundRouteEntry } from '@atlassian/jira-router-routes-business-entries/src/classicBusinessProjectViewNotFoundRouteEntry.tsx';
import { jiraBusinessListEmbedRouteEntry } from '@atlassian/jira-router-routes-business-list-entries/src/jiraBusinessListEmbedRouteEntry.tsx';
import { jiraBusinessListRouteEntry } from '@atlassian/jira-router-routes-business-list-entries/src/jiraBusinessListRouteEntry.tsx';
import { jiraBusinessOverviewRedirectRouteEntry } from '@atlassian/jira-router-routes-business-redirect-entries/src/jiraBusinessOverviewRedirectRouteEntry.tsx';
import { jiraBusinessProjectRedirectRouteEntry } from '@atlassian/jira-router-routes-business-redirect-entries/src/jiraBusinessProjectRedirectRouteEntry.tsx';
import { jiraBusinessSummaryEmbedRouteEntry } from '@atlassian/jira-router-routes-business-summary-entries/src/jiraBusinessSummaryEmbedRouteEntry.tsx';
import { jiraBusinessSummaryRouteEntry } from '@atlassian/jira-router-routes-business-summary-entries/src/jiraBusinessSummaryRouteEntry.tsx';
import { jiraBusinessTimelineEmbedRouteEntry } from '@atlassian/jira-router-routes-business-timeline-entries/src/jiraBusinessTimelineEmbedRouteEntry.tsx';
import { jiraBusinessTimelineRouteEntry } from '@atlassian/jira-router-routes-business-timeline-entries/src/jiraBusinessTimelineRouteEntry.tsx';
import { classicProjectSettingsDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-classic-project-settings-data-classifications-entries/src/classicProjectSettingsDataClassificationsRouteEntry.tsx';
import { classicProjectSettingsIssueLayoutRouteEntry } from '@atlassian/jira-router-routes-classic-project-settings-issue-layout-entries/src/classicProjectSettingsIssueLayoutRouteEntry.tsx';
import { compassProductSettingsRouteEntry } from '@atlassian/jira-router-routes-compass-product-settings-entries/src/compassProductSettingsRouteEntry.tsx';
import { connectGeneralInvalidRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectGeneralInvalidRouteEntry.tsx';
import { connectGeneralRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectGeneralRouteEntry.tsx';
import { connectGeneralServiceDeskProjectRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectGeneralServiceDeskProjectRouteEntry.tsx';
import { connectProjectRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/connectProjectRouteEntry.tsx';
import { servicedeskConnectProjectRouteEntry } from '@atlassian/jira-router-routes-connect-entries/src/servicedeskConnectProjectRouteEntry.tsx';
import { createProjectRouteEntry } from '@atlassian/jira-router-routes-create-project-entries/src/createProjectRouteEntry.tsx';
import { dashboardEditRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardEditRouteEntry.tsx';
import { dashboardRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardRouteEntry.tsx';
import { dashboardViewEmbedRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardViewEmbedRouteEntry.tsx';
import { dashboardViewRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardViewRouteEntry.tsx';
import { dashboardWallboardRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/dashboardWallboardRouteEntry.tsx';
import { selectedDashboardRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/selectedDashboardRouteEntry.tsx';
import { systemDashboardEditRouteEntry } from '@atlassian/jira-router-routes-dashboard-entries/src/systemDashboardEditRouteEntry.tsx';
import { devopsConnectedProjectRouteEntry } from '@atlassian/jira-router-routes-devops-connected-project-entries/src/devopsConnectedProjectRouteEntry.tsx';
import { boardsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/boardsDirectoryRedirectRouteEntry.tsx';
import { boardsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/boardsDirectoryRouteEntry.tsx';
import { dashboardsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/dashboardsDirectoryRedirectRouteEntry.tsx';
import { dashboardsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/dashboardsDirectoryRouteEntry.tsx';
import { filtersDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/filtersDirectoryRedirectRouteEntry.tsx';
import { filtersDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/filtersDirectoryRouteEntry.tsx';
import { manageProjectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/manageProjectsDirectoryRouteEntry.tsx';
import { plansDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/plansDirectoryRouteEntry.tsx';
import { projectsDirectoryLegacyPathRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryLegacyPathRedirectRouteEntry.tsx';
import { projectsDirectoryManageRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryManageRedirectRouteEntry.tsx';
import { projectsDirectoryManageRootRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryManageRootRedirectRouteEntry.tsx';
import { projectsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryRedirectRouteEntry.tsx';
import { projectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/projectsDirectoryRouteEntry.tsx';
import { secureFiltersDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-directories-entries/src/secureFiltersDirectoryRedirectRouteEntry.tsx';
import { eocAlertIssueSyncingCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocAlertIssueSyncingCreateRouteEntry.tsx';
import { eocAlertIssueSyncingDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocAlertIssueSyncingDetailRouteEntry.tsx';
import { eocAlertIssueSyncingRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocAlertIssueSyncingRouteEntry.tsx';
import { eocTeamAlertIssueSyncingCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocTeamAlertIssueSyncingCreateRouteEntry.tsx';
import { eocTeamAlertIssueSyncingDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocTeamAlertIssueSyncingDetailRouteEntry.tsx';
import { eocTeamAlertIssueSyncingRouteEntry } from '@atlassian/jira-router-routes-eoc-alert-issue-syncing-entries/src/eocTeamAlertIssueSyncingRouteEntry.tsx';
import { eocApiKeyManagementRouteEntry } from '@atlassian/jira-router-routes-eoc-api-key-management-entries/src/eocApiKeyManagementRouteEntry.tsx';
import { eocAssignCustomRolesRouteEntry } from '@atlassian/jira-router-routes-eoc-assign-custom-roles-entries/src/eocAssignCustomRolesRouteEntry.tsx';
import { eocAutomationsRouteEntry } from '@atlassian/jira-router-routes-eoc-automations-entries/src/eocAutomationsRouteEntry.tsx';
import { eocCustomRolesRouteEntry } from '@atlassian/jira-router-routes-eoc-custom-roles-entries/src/eocCustomRolesRouteEntry.tsx';
import { eocGlobalAlertListRouteEntry } from '@atlassian/jira-router-routes-eoc-global-alerts-entries/src/eocGlobalAlertListRouteEntry.tsx';
import { eocGlobalAlertsDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-global-alerts-entries/src/eocGlobalAlertsDetailRouteEntry.tsx';
import { globalEscalationsRouteEntry } from '@atlassian/jira-router-routes-eoc-global-escalations-entries/src/globalEscalationsRouteEntry.tsx';
import { eocGlobalGroupDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-group-detail-entries/src/eocGlobalGroupDetailRouteEntry.tsx';
import { eocTeamGroupDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-group-detail-entries/src/eocTeamGroupDetailRouteEntry.tsx';
import { eocHeartbeatsRouteEntry } from '@atlassian/jira-router-routes-eoc-heartbeats-entries/src/eocHeartbeatsRouteEntry.tsx';
import { eocGlobalHeartbeatsRouteEntry } from '@atlassian/jira-router-routes-eoc-heartbeats-entries/src/eocGlobalHeartbeatsRouteEntry.tsx';
import { globalIncomingCallDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-incoming-call-entries/src/globalIncomingCallDetailRouteEntry.tsx';
import { globalIncomingCallRouteEntry } from '@atlassian/jira-router-routes-eoc-incoming-call-entries/src/globalIncomingCallRouteEntry.tsx';
import { globalIntegrationCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationCreateRouteEntry.tsx';
import { globalIntegrationDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationDetailRouteEntry.tsx';
import { globalIntegrationListRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationListRouteEntry.tsx';
import { globalIntegrationLogRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationLogRouteEntry.tsx';
import { globalIntegrationsRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/globalIntegrationsRouteEntry.tsx';
import { teamIntegrationCreateRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationCreateRouteEntry.tsx';
import { teamIntegrationDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationDetailRouteEntry.tsx';
import { teamIntegrationListRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationListRouteEntry.tsx';
import { teamIntegrationLogRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationLogRouteEntry.tsx';
import { teamIntegrationsRouteEntry } from '@atlassian/jira-router-routes-eoc-integration-automation-entries/src/teamIntegrationsRouteEntry.tsx';
import { eocGlobalMaintenanceRouteEntry } from '@atlassian/jira-router-routes-eoc-maintenance-entries/src/eocGlobalMaintenanceRouteEntry.tsx';
import { eocMaintenanceRouteEntry } from '@atlassian/jira-router-routes-eoc-maintenance-entries/src/eocMaintenanceRouteEntry.tsx';
import { eocOnCallRedirectRouteEntry } from '@atlassian/jira-router-routes-eoc-on-call-entries/src/eocOnCallRedirectRouteEntry.tsx';
import { eocOnCallRouteEntry } from '@atlassian/jira-router-routes-eoc-on-call-entries/src/eocOnCallRouteEntry.tsx';
import { eocOverviewAllTeamsRouteEntry } from '@atlassian/jira-router-routes-eoc-overview-entries/src/eocOverviewAllTeamsRouteEntry.tsx';
import { eocOverviewRouteEntry } from '@atlassian/jira-router-routes-eoc-overview-entries/src/eocOverviewRouteEntry.tsx';
import { eocPermissionsRouteEntry } from '@atlassian/jira-router-routes-eoc-permissions-entries/src/eocPermissionsRouteEntry.tsx';
import { alertPoliciesRouteEntry } from '@atlassian/jira-router-routes-eoc-policies-entries/src/alertPoliciesRouteEntry.tsx';
import { globalPoliciesRouteEntry } from '@atlassian/jira-router-routes-eoc-policies-entries/src/globalPoliciesRouteEntry.tsx';
import { eocGlobalReportsHomePageNav4RouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocGlobalReportsHomePageNav4RouteEntry.tsx';
import { eocGlobalReportsNav4RouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocGlobalReportsNav4RouteEntry.tsx';
import { eocReportsTeamRedirectRouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocReportsTeamRedirectRouteEntry.tsx';
import { eocReportsTeamRouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocReportsTeamRouteEntry.tsx';
import { eocReportsUnsubscribeRouteEntry } from '@atlassian/jira-router-routes-eoc-reports-entries/src/eocReportsUnsubscribeRouteEntry.tsx';
import { roleBasedNotificationDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-role-based-notifications-entries/src/roleBasedNotificationDetailRouteEntry.tsx';
import { roleBasedNotificationsRouteEntry } from '@atlassian/jira-router-routes-eoc-role-based-notifications-entries/src/roleBasedNotificationsRouteEntry.tsx';
import { eocScheduleGlobalRouteEntry } from '@atlassian/jira-router-routes-eoc-schedule-global-entries/src/eocScheduleGlobalRouteEntry.tsx';
import { eocTeamAlertDetailRouteEntry } from '@atlassian/jira-router-routes-eoc-team-alerts-entries/src/eocTeamAlertDetailRouteEntry.tsx';
import { eocTeamAlertListRouteEntry } from '@atlassian/jira-router-routes-eoc-team-alerts-entries/src/eocTeamAlertListRouteEntry.tsx';
import { eocTeamCustomerLogsDownloadRouteEntry } from '@atlassian/jira-router-routes-eoc-team-customer-logs-entries/src/eocTeamCustomerLogsDownloadRouteEntry.tsx';
import { eocTeamCustomerLogsRouteEntry } from '@atlassian/jira-router-routes-eoc-team-customer-logs-entries/src/eocTeamCustomerLogsRouteEntry.tsx';
import { unlockOperationsRouteEntry } from '@atlassian/jira-router-routes-eoc-unlock-operations-entries/src/unlockOperationsRouteEntry.tsx';
import { whoIsOnCallRouteEntry } from '@atlassian/jira-router-routes-eoc-who-is-on-call-entries/src/whoIsOnCallRouteEntry.tsx';
import { errorsNotFoundRouteEntry } from '@atlassian/jira-router-routes-errors-not-found-entries/src/errorsNotFoundRouteEntry.tsx';
import { forgeAdminRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeAdminRouteEntry.tsx';
import { forgeAdminSettingsRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeAdminSettingsRouteEntry.tsx';
import { forgeFieldContextualConfigurationRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeFieldContextualConfigurationRouteEntry.tsx';
import { forgeGlobalRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeGlobalRouteEntry.tsx';
import { forgePersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgePersonalSettingsRouteEntry.tsx';
import { forgeProjectRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeProjectRouteEntry.tsx';
import { forgeProjectServicedeskRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeProjectServicedeskRouteEntry.tsx';
import { forgeServicedeskQueueRouteEntry } from '@atlassian/jira-router-routes-forge-entries/src/forgeServicedeskQueueRouteEntry.tsx';
import { globalMailSettingsRouteEntry } from '@atlassian/jira-router-routes-global-mail-settings-entries/src/globalMailSettingsRouteEntry.tsx';
import { globalSettingsCustomFieldsRouteEntry } from '@atlassian/jira-router-routes-global-settings-custom-fields-entries/src/globalSettingsCustomFieldsRouteEntry.tsx';
import { globalSettingsIssuesExplicitFieldAssociationRouteEntry } from '@atlassian/jira-router-routes-global-settings-issues-explicit-field-association-entries/src/globalSettingsIssuesExplicitFieldAssociationRouteEntry.tsx';
import { helpCenterSettingsAgentStudioConversationReviewRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsAgentStudioConversationReviewRouteEntry.tsx';
import { helpCenterSettingsAgentStudioKnowledgeRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsAgentStudioKnowledgeRouteEntry.tsx';
import { helpCenterSettingsAgentStudioChatSimulatorRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsAgentStudioChatSimulatorRouteEntry.tsx';
import { helpCenterSettingsAgentStudioActionsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsAgentStudioActionsRouteEntry.tsx';
import { helpCenterSettingsAgentStudioRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsAgentStudioRouteEntry.tsx';
import { helpCenterSettingsArticlesRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsArticlesRouteEntry.tsx';
import { helpCenterSettingsArticlesViewArticleRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsArticlesViewArticleRouteEntry.tsx';
import { helpCenterSettingsCreateArticleRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsCreateArticleRouteEntry.tsx';
import { helpCenterSettingsCustomerAccessRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsCustomerAccessRouteEntry.tsx';
import { helpCenterSettingsCustomerAccessOldRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsCustomerAccessOldRouteEntry.tsx';
import { helpCenterSettingsCustomerNotificationsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsCustomerNotificationsRouteEntry.tsx';
import { helpCenterSettingsCustomerNotificationsOldRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsCustomerNotificationsOldRouteEntry.tsx';
import { helpCenterSettingsDetailsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsDetailsRouteEntry.tsx';
import { helpCenterSettingsEmailRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsEmailRouteEntry.tsx';
import { helpCenterSettingsEmailOldRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsEmailOldRouteEntry.tsx';
import { helpCenterSettingsSupportWebsiteRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsSupportWebsiteRouteEntry.tsx';
import { helpCenterSettingsSupportWebsiteOverviewRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsSupportWebsiteOverviewRouteEntry.tsx';
import { helpCenterSettingsSupportWebsitePagesRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsSupportWebsitePagesRouteEntry.tsx';
import { helpCenterSettingsFormEditRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsFormEditRouteEntry.tsx';
import { helpCenterSettingsFormsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsFormsRouteEntry.tsx';
import { helpCenterSettingsKbRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsKbRouteEntry.tsx';
import { helpCenterSettingsKbOldRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsKbOldRouteEntry.tsx';
import { helpCenterSettingsServiceHubsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsServiceHubsRouteEntry.tsx';
import { helpCenterSettingsNotFoundRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsNotFoundRouteEntry.tsx';
import { helpCenterSettingsAnalyticsRouteEntry } from '@atlassian/jira-router-routes-help-center-settings-entries/src/helpCenterSettingsAnalyticsRouteEntry.tsx';
import { homeOnboardingRedirectRouteEntry } from '@atlassian/jira-router-routes-home-onboarding-redirect-entries/src/homeOnboardingRedirectRouteEntry.tsx';
import { homeRedirectRouteEntry } from '@atlassian/jira-router-routes-home-redirect-entries/src/homeRedirectRouteEntry.tsx';
import { softwareIncidentsNextGenRouteEntry } from '@atlassian/jira-router-routes-incidents-entries/src/softwareIncidentsNextGenRouteEntry.tsx';
import { issueCreateEmbedRouteEntry } from '@atlassian/jira-router-routes-issue-create-embed-entries/src/issueCreateEmbedRouteEntry.tsx';
import { browseIssueEmbedRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueEmbedRouteEntry.tsx';
import { browseIssueRouteEntry } from '@atlassian/jira-router-routes-issue-entries/src/browseIssueRouteEntry.tsx';
import { globalIssueNavigatorBrowseIssueRedirectRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/globalIssueNavigatorBrowseIssueRedirectRouteEntry.tsx';
import { issueNavigatorGlobalJiraRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorGlobalJiraRouteEntry.tsx';
import { issueNavigatorGlobalRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorGlobalRouteEntry.tsx';
import { issueNavigatorProjectBusinessRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectBusinessRouteEntry.tsx';
import { issueNavigatorProjectClassicRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectClassicRouteEntry.tsx';
import { issueNavigatorProjectServicedeskRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectServicedeskRouteEntry.tsx';
import { issueNavigatorProjectSoftwareRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/issueNavigatorProjectSoftwareRouteEntry.tsx';
import { projectIssueNavigatorRouteEntry } from '@atlassian/jira-router-routes-issue-navigator-entries/src/projectIssueNavigatorRouteEntry.tsx';
import { jiraHomeRouteEntry } from '@atlassian/jira-router-routes-jira-home-entries/src/jiraHomeRouteEntry.tsx';
import { jiraSearchRouteEntry } from '@atlassian/jira-router-routes-jira-search-entries/src/jiraSearchRouteEntry.tsx';
import { legacyRedirectRouteEntry } from '@atlassian/jira-router-routes-legacy-redirect-entries/src/legacyRedirectRouteEntry.tsx';
import { loginEmbedRouteEntry } from '@atlassian/jira-router-routes-login-embed-entries/src/loginEmbedRouteEntry.tsx';
import { appRequestsRouteEntry } from '@atlassian/jira-router-routes-marketplace-entries/src/appRequestsRouteEntry.tsx';
import { findAppsRouteEntry } from '@atlassian/jira-router-routes-marketplace-entries/src/findAppsRouteEntry.tsx';
import { promotionsRouteEntry } from '@atlassian/jira-router-routes-marketplace-entries/src/promotionsRouteEntry.tsx';
import { softwareCodeRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareCodeRouteEntry.tsx';
import { softwareNextgenRedirectRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareNextgenRedirectRouteEntry.tsx';
import { softwareReportsBurndownRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsBurndownRouteEntry.tsx';
import { softwareReportsBurnupRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsBurnupRouteEntry.tsx';
import { softwareReportsControlRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsControlRouteEntry.tsx';
import { softwareReportsCumulativeRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsCumulativeRouteEntry.tsx';
import { softwareReportsCycleTimeRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsCycleTimeRouteEntry.tsx';
import { softwareReportsDeploymentFrequencyRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsDeploymentFrequencyRouteEntry.tsx';
import { softwareReportsRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsRouteEntry.tsx';
import { softwareReportsVelocityRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareReportsVelocityRouteEntry.tsx';
import { softwareRoadmapRedirectRouteEntry } from '@atlassian/jira-router-routes-next-gen-entries/src/softwareRoadmapRedirectRouteEntry.tsx';
import { opsgenieScheduleRedirectRouteEntry } from '@atlassian/jira-router-routes-opsgenie-schedule-entries/src/opsgenieScheduleRedirectRouteEntry.tsx';
import { opsgenieScheduleRouteEntry } from '@atlassian/jira-router-routes-opsgenie-schedule-entries/src/opsgenieScheduleRouteEntry.tsx';
import { peopleProfilesRouteEntry } from '@atlassian/jira-router-routes-people-profiles-entries/src/peopleProfilesRouteEntry.tsx';
import { generalPersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/generalPersonalSettingsRouteEntry.tsx';
import { notificationsPersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/notificationsPersonalSettingsRouteEntry.tsx';
import { opsNotificationsPersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/opsNotificationsPersonalSettingsRouteEntry.tsx';
import { digestsNotificationsPersonalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/digestsNotificationsPersonalSettingsRouteEntry.tsx';
import { personalSettingsRouteEntry } from '@atlassian/jira-router-routes-personal-settings-entries/src/personalSettingsRouteEntry.tsx';
import { polarisCollectionsRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisCollectionsRouteEntry.tsx';
import { polarisIdeasRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisIdeasRouteEntry.tsx';
import { polarisOnboardingRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisOnboardingRouteEntry.tsx';
import { polarisSettingsAccessRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisSettingsAccessRouteEntry.tsx';
import { polarisSettingsGlobalFieldsRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisSettingsGlobalFieldsRouteEntry.tsx';
import { polarisSettingsEarlyAccessFeaturesRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisSettingsEarlyAccessFeaturesRouteEntry.tsx';
import { polarisSettingsFinancialYearRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisSettingsFinancialYearRouteEntry.tsx';
import { polarisUnlicensedRouteEntry } from '@atlassian/jira-router-routes-polaris-entries/src/polarisUnlicensedRouteEntry.tsx';
import { productsJsmChatopsAndVideosToolsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-chatops-and-videos-tools-entries/src/productsJsmChatopsAndVideosToolsRouteEntry.tsx';
import { productsJsmComplianceRouteEntry } from '@atlassian/jira-router-routes-products-jsm-compliance-entries/src/productsJsmComplianceRouteEntry.tsx';
import { productsJsmConfigurationRouteEntry } from '@atlassian/jira-router-routes-products-jsm-configuration-entries/src/productsJsmConfigurationRouteEntry.tsx';
import { productsJsmEmailRequestsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-email-requests-entries/src/productsJsmEmailRequestsRouteEntry.tsx';
import { productsJsmFeatureUsageRouteEntry } from '@atlassian/jira-router-routes-products-jsm-feature-usage-entries/src/productsJsmFeatureUsageRouteEntry.tsx';
import { productsJsmGlobalOpsReportsRedirectRouteEntry } from '@atlassian/jira-router-routes-products-jsm-global-ops-reports-entries/src/productsJsmGlobalOpsReportsRedirectRouteEntry.tsx';
import { productsJsmGlobalOpsReportsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-global-ops-reports-entries/src/productsJsmGlobalOpsReportsRouteEntry.tsx';
import { productsJsmIncidentManagementToolsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-incident-management-tools-entries/src/productsJsmIncidentManagementToolsRouteEntry.tsx';
import { productsJsmKbPermissionsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-kb-permissions-entries/src/productsJsmKbPermissionsRouteEntry.tsx';
import { productsJsmOrganizationsDetailsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-organizations-entries/src/productsJsmOrganizationsDetailsRouteEntry.tsx';
import { productsJsmOrganizationsRouteEntry } from '@atlassian/jira-router-routes-products-jsm-organizations-entries/src/productsJsmOrganizationsRouteEntry.tsx';
import { productsJswConfigurationRouteEntry } from '@atlassian/jira-router-routes-products-jsw-configuration-entries/src/productsJswConfigurationRouteEntry.tsx';
import { productsJswStorageManagementRouteEntry } from '@atlassian/jira-router-routes-products-jsw-configuration-entries/src/productsJswStorageManagementRouteEntry.tsx';
import { proformaExportRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/proformaExportRouteEntry.tsx';
import { proformaIssueFormsGeneralRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/proformaIssueFormsGeneralRouteEntry.tsx';
import { classicBusinessProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/classicBusinessProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { classicBusinessProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/classicBusinessProformaIssueFormsDirectRouteEntry.tsx';
import { servicedeskProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/servicedeskProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { servicedeskProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/servicedeskProformaIssueFormsDirectRouteEntry.tsx';
import { softwareProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/softwareProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { softwareProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-proforma-entries/src/softwareProformaIssueFormsDirectRouteEntry.tsx';
import { projectComponentsRedirectRouteEntry } from '@atlassian/jira-router-routes-project-components-entries/src/projectComponentsRedirectRouteEntry.tsx';
import { projectComponentsRouteEntry } from '@atlassian/jira-router-routes-project-components-entries/src/projectComponentsRouteEntry.tsx';
import { projectNotFoundRouteEntry } from '@atlassian/jira-router-routes-project-not-found-entries/src/projectNotFoundRouteEntry.tsx';
import { projectPagesLegacyRouteEntry } from '@atlassian/jira-router-routes-project-pages-entries/src/projectPagesLegacyRouteEntry.tsx';
import { projectPagesRouteEntry } from '@atlassian/jira-router-routes-project-pages-entries/src/projectPagesRouteEntry.tsx';
import { projectSettingsComponentsRouteEntry } from '@atlassian/jira-router-routes-project-settings-components-entries/src/projectSettingsComponentsRouteEntry.tsx';
import { projectSettingsVersionsRouteEntry } from '@atlassian/jira-router-routes-project-settings-versions-entries/src/projectSettingsVersionsRouteEntry.tsx';
import { projectsRedirectRouteEntry } from '@atlassian/jira-router-routes-projects-redirect-entries/src/projectsRedirectRouteEntry.tsx';
import { projectVersionsRouteEntry } from '@atlassian/jira-router-routes-projects-versions-entries/src/projectVersionsRouteEntry.tsx';
import { sandboxBundlerRouteEntry } from '@atlassian/jira-router-routes-sandbox-entries/src/sandboxBundlerRouteEntry.tsx';
import { sandboxBundlerBifrostRLLRouteEntry } from '@atlassian/jira-router-routes-sandbox-entries/src/sandboxBundlerBifrostRLLRouteEntry.tsx';
import { sandboxBundlerBifrostEntrypointsRouteEntry } from '@atlassian/jira-router-routes-sandbox-entries/src/sandboxBundlerBifrostEntrypointsRouteEntry.tsx';
import { jsmBoardRouteEntry } from '@atlassian/jira-router-routes-servicedesk-board-entries/src/jsmBoardRouteEntry.tsx';
import { servicedeskCalendarRouteEntry } from '@atlassian/jira-router-routes-servicedesk-calendar-entries/src/servicedeskCalendarRouteEntry.tsx';
import { servicedeskChangeCalendarRouteEntry } from '@atlassian/jira-router-routes-servicedesk-change-calendar-entries/src/servicedeskChangeCalendarRouteEntry.tsx';
import { assetsRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsRouteEntry.tsx';
import { assetsReportsRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsReportsRouteEntry.tsx';
import { assetsReportsTemplateRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsReportsTemplateRouteEntry.tsx';
import { assetsReportsUnsubscribeRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsReportsUnsubscribeRouteEntry.tsx';
import { assetsSchemaRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsSchemaRouteEntry.tsx';
import { assetsObjectTypeConfigurationRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsObjectTypeConfigurationRouteEntry.tsx';
import { assetsConfigurationRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsConfigurationRouteEntry.tsx';
import { assetsSchemaConfigurationRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsSchemaConfigurationRouteEntry.tsx';
import { assetsObjectRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsObjectRouteEntry.tsx';
import { servicedeskCmdbAssetsGlobalConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsGlobalConfigurationRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectSchemaConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectSchemaConfigurationRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectSchemaRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectSchemaRouteEntry.tsx';
import { servicedeskCmdbAssetsObjectTypeConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsObjectTypeConfigurationRouteEntry.tsx';
import { servicedeskCmdbAssetsReportsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsReportsRouteEntry.tsx';
import { servicedeskCmdbAssetsReportsTemplateRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsReportsTemplateRouteEntry.tsx';
import { servicedeskCmdbAssetsReportsUnsubscribeRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsReportsUnsubscribeRouteEntry.tsx';
import { servicedeskCmdbAssetsSchemasRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbAssetsSchemasRouteEntry.tsx';
import { servicedeskCmdbInsightConfigureGlobalRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightConfigureGlobalRouteEntry.tsx';
import { servicedeskCmdbInsightConfigureRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightConfigureRouteEntry.tsx';
import { servicedeskCmdbInsightObject4RouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObject4RouteEntry.tsx';
import { servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry.tsx';
import { servicedeskCmdbInsightObjectSchemaRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObjectSchemaRouteEntry.tsx';
import { servicedeskCmdbInsightObjectTypeConfigurationRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightObjectTypeConfigurationRouteEntry.tsx';
import { servicedeskCmdbInsightRouteEntry } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/servicedeskCmdbInsightRouteEntry.tsx';
import { servicedeskCreateProjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-create-project-entries/src/servicedeskCreateProjectRouteEntry.tsx';
import { customerServiceEscalationsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-customer-service-escalations-entries/src/customerServiceEscalationsRouteEntry.tsx';
import { customerServiceProductsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-customer-service-products-entries/src/customerServiceProductsRouteEntry.tsx';
import { servicedeskCustomerDetailsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-customers-entries/src/servicedeskCustomerDetailsRouteEntry.tsx';
import { servicedeskCustomersV2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-customers-entries/src/servicedeskCustomersV2RouteEntry.tsx';
import { projectServiceDeskRouteEntry } from '@atlassian/jira-router-routes-servicedesk-default-entries/src/projectServiceDeskRouteEntry.tsx';
import { jsmGSPPageRouteEntry } from '@atlassian/jira-router-routes-servicedesk-getting-started-page-entries/src/jsmGSPPageRouteEntry.tsx';
import { servicedeskItsmSampleSpaceRouteEntry } from '@atlassian/jira-router-routes-servicedesk-itsm-sample-space-entries/src/servicedeskItsmSampleSpaceRouteEntry.tsx';
import { knowledgeHubAllArticlesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubAllArticlesRouteEntry.tsx';
import { knowledgeHubArticleEmbedEditorRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubArticleEmbedEditorRouteEntry.tsx';
import { knowledgeHubArticleInCategoryRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubArticleInCategoryRouteEntry.tsx';
import { knowledgeHubBrowseAllArticlesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubBrowseAllArticlesRouteEntry.tsx';
import { knowledgeHubBrowseAllCategoriesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubBrowseAllCategoriesRouteEntry.tsx';
import { knowledgeHubBrowseViewArticleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubBrowseViewArticleRouteEntry.tsx';
import { knowledgeHubCategoryPageRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubCategoryPageRouteEntry.tsx';
import { knowledgeHubCategoryRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubCategoryRouteEntry.tsx';
import { knowledgeHubDraftsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubDraftsRouteEntry.tsx';
import { knowledgeHubLandingRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubLandingRouteEntry.tsx';
import { knowledgeHubSearchRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubSearchRouteEntry.tsx';
import { knowledgeHubSetupRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubSetupRouteEntry.tsx';
import { knowledgeHubSuggestedArticlesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-entries/src/knowledgeHubSuggestedArticlesRouteEntry.tsx';
import { notificationLogsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-notification-logs-entries/src/notificationLogsRouteEntry.tsx';
import { servicedeskOncallScheduleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-oncall-entries/src/servicedeskOncallScheduleRouteEntry.tsx';
import { servicedeskOrganizationV2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-organization-entries/src/servicedeskOrganizationV2RouteEntry.tsx';
import { servicedeskOrganizationDetailsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-organizations-entries/src/servicedeskOrganizationDetailsRouteEntry.tsx';
import { servicedeskOrganizationsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-organizations-entries/src/servicedeskOrganizationsRouteEntry.tsx';
import { servicedeskProjectSummaryRouteEntry } from '@atlassian/jira-router-routes-servicedesk-project-summary-entries/src/servicedeskProjectSummaryRouteEntry.tsx';
import { customPracticeQueueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customPracticeQueueRouteEntry.tsx';
import { customPracticeQueueBoardRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customPracticeQueueBoardRouteEntry.tsx';
import { customPracticeQueueCalendarRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customPracticeQueueCalendarRouteEntry.tsx';
import { customQueueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customQueueRouteEntry.tsx';
import { customQueueBoardRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customQueueBoardRouteEntry.tsx';
import { customQueueCalendarRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customQueueCalendarRouteEntry.tsx';
import { customIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queue-entries/src/customIssueRouteEntry.tsx';
import { servicedeskIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskIssueRouteEntry.tsx';
import { servicedeskPracticeEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeEditRouteEntry.tsx';
import { servicedeskPracticeIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeIssueRouteEntry.tsx';
import { servicedeskPracticeLandingRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeLandingRouteEntry.tsx';
import { servicedeskPracticeManageViewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeManageViewRouteEntry.tsx';
import { servicedeskPracticeNewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeNewRouteEntry.tsx';
import { servicedeskQueuesAddonRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesAddonRouteEntry.tsx';
import { servicedeskQueuesEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesEditRouteEntry.tsx';
import { servicedeskQueuesIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesIssueRouteEntry.tsx';
import { servicedeskQueuesLandingRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesLandingRouteEntry.tsx';
import { servicedeskQueuesManageViewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesManageViewRouteEntry.tsx';
import { servicedeskQueuesNewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesNewRouteEntry.tsx';
import { servicedeskAtlassianAnalyticsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskAtlassianAnalyticsRouteEntry.tsx';
import { servicedeskKbRequestsReports2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskKbRequestsReports2RouteEntry.tsx';
import { servicedeskKbRequestsReportsIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskKbRequestsReportsIssueRouteEntry.tsx';
import { servicedeskKbRequestsReportsTimeScaleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskKbRequestsReportsTimeScaleRouteEntry.tsx';
import { servicedeskReports1RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReports1RouteEntry.tsx';
import { servicedeskReportsCustom4RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustom4RouteEntry.tsx';
import { servicedeskReportsCustomCreateRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomCreateRouteEntry.tsx';
import { servicedeskReportsCustomEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomEditRouteEntry.tsx';
import { servicedeskReportsCustomIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomIssueRouteEntry.tsx';
import { servicedeskReportsCustomTimeScaleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsCustomTimeScaleRouteEntry.tsx';
import { servicedeskReportsSatisfaction2RouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsSatisfaction2RouteEntry.tsx';
import { servicedeskReportsSatisfactionIssueRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsSatisfactionIssueRouteEntry.tsx';
import { servicedeskReportsSatisfactionTimeScaleRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsSatisfactionTimeScaleRouteEntry.tsx';
import { servicedeskReportsTypeRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsTypeRouteEntry.tsx';
import { servicedeskReportsWorkloadRouteEntry } from '@atlassian/jira-router-routes-servicedesk-reports-entries/src/servicedeskReportsWorkloadRouteEntry.tsx';
import { servicedeskServiceDetailsRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServiceDetailsRouteEntry.tsx';
import { serviceDeskServiceDetailsWithoutProjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/serviceDeskServiceDetailsWithoutProjectRouteEntry.tsx';
import { servicedeskServiceModalEditRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServiceModalEditRouteEntry.tsx';
import { servicedeskServiceModalNewRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServiceModalNewRouteEntry.tsx';
import { servicedeskServicesRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/servicedeskServicesRouteEntry.tsx';
import { serviceDeskServicesWithoutProjectRouteEntry } from '@atlassian/jira-router-routes-servicedesk-services-entries/src/serviceDeskServicesWithoutProjectRouteEntry.tsx';
import { settingsAutomationRouteEntry } from '@atlassian/jira-router-routes-settings-automation-entries/src/settingsAutomationRouteEntry.tsx';
import { settingsCmdbFieldConfigRouteEntry } from '@atlassian/jira-router-routes-settings-cmdb-field-config-entries/src/settingsCmdbFieldConfigRouteEntry.tsx';
import { settingsOauthCredentialsRouteEntry } from '@atlassian/jira-router-routes-settings-oauth-credentials-entries/src/settingsOauthCredentialsRouteEntry.tsx';
import { softwareBacklogEmbedRouteEntry } from '@atlassian/jira-router-routes-software-backlog-entries/src/softwareBacklogEmbedRouteEntry.tsx';
import { softwareBacklogRouteEntry } from '@atlassian/jira-router-routes-software-backlog-entries/src/softwareBacklogRouteEntry.tsx';
import { softwareBoardEmbedRouteEntry } from '@atlassian/jira-router-routes-software-board-entries/src/softwareBoardEmbedRouteEntry.tsx';
import { softwareBoardRouteEntry } from '@atlassian/jira-router-routes-software-board-entries/src/softwareBoardRouteEntry.tsx';
import { softwareCalendarEmbedRouteEntry } from '@atlassian/jira-router-routes-software-calendar-entries/src/softwareCalendarEmbedRouteEntry.tsx';
import { softwareCalendarRouteEntry } from '@atlassian/jira-router-routes-software-calendar-entries/src/softwareCalendarRouteEntry.tsx';
import { softwareDeploymentsNextGenRouteEntry } from '@atlassian/jira-router-routes-software-deployments-entries/src/softwareDeploymentsNextGenRouteEntry.tsx';
import { softwareFormBuilderRouteEntry } from '@atlassian/jira-router-routes-software-form-builder-entries/src/softwareFormBuilderRouteEntry.tsx';
import { softwareFormRouteEntry } from '@atlassian/jira-router-routes-software-form-entries/src/softwareFormRouteEntry.tsx';
import { softwareFormSubmitEmbedRouteEntry } from '@atlassian/jira-router-routes-software-form-submit-embed-entries/src/softwareFormSubmitEmbedRouteEntry.tsx';
import { softwareFormSubmitRouteEntry } from '@atlassian/jira-router-routes-software-form-submit-entries/src/softwareFormSubmitRouteEntry.tsx';
import { softwareFormSubmitPublicRouteEntry } from '@atlassian/jira-router-routes-software-form-submit-public-entries/src/softwareFormSubmitPublicRouteEntry.tsx';
import { softwareGoalsRouteEntry } from '@atlassian/jira-router-routes-software-goals-entries/src/softwareGoalsRouteEntry.tsx';
import { softwareInboxNextGenRouteEntry } from '@atlassian/jira-router-routes-software-inbox-entries/src/softwareInboxNextGenRouteEntry.tsx';
import { softwareInboxClassicRouteEntry } from '@atlassian/jira-router-routes-software-inbox-entries/src/softwareInboxClassicRouteEntry.tsx';
import { softwareListEmbedRouteEntry } from '@atlassian/jira-router-routes-software-list-entries/src/softwareListEmbedRouteEntry.tsx';
import { softwareListRouteEntry } from '@atlassian/jira-router-routes-software-list-entries/src/softwareListRouteEntry.tsx';
import { softwareOnboardingRouteEntry } from '@atlassian/jira-router-routes-software-onboarding-entries/src/softwareOnboardingRouteEntry.tsx';
import { softwarePeriscopeNextGenRouteEntry } from '@atlassian/jira-router-routes-software-periscope-entries/src/softwarePeriscopeNextGenRouteEntry.tsx';
import { softwareRoadmapRouteEntry } from '@atlassian/jira-router-routes-software-roadmap-entries/src/softwareRoadmapRouteEntry.tsx';
import { softwareSecurityNextGenRouteEntry } from '@atlassian/jira-router-routes-software-security-entries/src/softwareSecurityNextGenRouteEntry.tsx';
import { softwareSummaryRouteEntry } from '@atlassian/jira-router-routes-software-summary-entries/src/softwareSummaryRouteEntry.tsx';
import { softwareSummaryEmbedRouteEntry } from '@atlassian/jira-router-routes-software-summary-embed-entries/src/softwareSummaryEmbedRouteEntry.tsx';
import { softwareTimelineRouteEntry } from '@atlassian/jira-router-routes-software-timeline-entries/src/softwareTimelineRouteEntry.tsx';
import { softwareVersionDetailEmbedRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailEmbedRouteEntry.tsx';
import { softwareVersionDetailLegacyRedirectRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailLegacyRedirectRouteEntry.tsx';
import { softwareVersionDetailNoTabPathRedirectRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailNoTabPathRedirectRouteEntry.tsx';
import { softwareVersionDetailRouteEntry } from '@atlassian/jira-router-routes-software-versions-entries/src/softwareVersionDetailRouteEntry.tsx';
import { trashProjectsDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-trash-projects-directory-entries/src/trashProjectsDirectoryRedirectRouteEntry.tsx';
import { trashProjectsDirectoryRouteEntry } from '@atlassian/jira-router-routes-trash-projects-directory-entries/src/trashProjectsDirectoryRouteEntry.tsx';
import { userSegmentationRouteEntry } from '@atlassian/jira-router-routes-user-segmentation-entries/src/userSegmentationRouteEntry.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { softwareRoadmapClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareRoadmapClassicRouteEntry.tsx';
import { softwareTimelineClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareTimelineClassicRouteEntry.tsx';
import { classicSoftwareBoardSettingsRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-settings-entries/src/classicSoftwareBoardSettingsRouteEntry.tsx';
import { classicSoftwareBoardSettingsInsightsRapidboardBacklogRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-settings-insights-entries/src/classicSoftwareBoardSettingsInsightsRapidboardBacklogRouteEntry.tsx';
import { classicSoftwareBoardSettingsInsightsRapidboardBoardRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-settings-insights-entries/src/classicSoftwareBoardSettingsInsightsRapidboardBoardRouteEntry.tsx';
import { softwareCalendarClassicEmbedRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareCalendarClassicEmbedRouteEntry.tsx';
import { softwareCalendarClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareCalendarClassicRouteEntry.tsx';
import { softwareListEmbedClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareListEmbedClassicRouteEntry.tsx';
import { softwareListClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareListClassicRouteEntry.tsx';
import { rapidboardReportRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardReportRouteEntry.tsx';
import { softwareReportsOverviewRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsOverviewRouteEntry.tsx';
import { softwareReportsOverviewBoardlessRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsOverviewBoardlessRouteEntry.tsx';
import { softwareReportsCycleTimeClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsCycleTimeClassicRouteEntry.tsx';
import { softwareReportsCycleTimeClassicBoardRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsCycleTimeClassicBoardRouteEntry.tsx';
import { softwareFormBuilderClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormBuilderClassicRouteEntry.tsx';
import { softwareFormSubmitClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormSubmitClassicRouteEntry.tsx';
import { softwareFormSubmitEmbedClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormSubmitEmbedClassicRouteEntry.tsx';
import { softwareFormSubmitClassicPublicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormSubmitClassicPublicRouteEntry.tsx';
import { softwareFormClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareFormClassicRouteEntry.tsx';
import { softwareGoalsClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareGoalsClassicRouteEntry.tsx';
import { softwareSummaryClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareSummaryClassicRouteEntry.tsx';
import { softwareSummaryEmbedClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareSummaryEmbedClassicRouteEntry.tsx';
import { softwareClassicProformaIssueFormsDirectRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareClassicProformaIssueFormsDirectRouteEntry.tsx';
import { softwareClassicProformaIssueFormsDirectEmbedRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareClassicProformaIssueFormsDirectEmbedRouteEntry.tsx';
import { softwareReportsDeploymentFrequencyClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsDeploymentFrequencyClassicRouteEntry.tsx';
import { softwareReportsDeploymentFrequencyClassicBoardRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareReportsDeploymentFrequencyClassicBoardRouteEntry.tsx';
import { classicSoftwareBacklogRouteEntry } from '@atlassian/jira-router-routes-software-classic-backlog-entries/src/classicSoftwareBacklogRouteEntry.tsx';
import { classicSoftwareBacklogEmbedRouteEntry } from '@atlassian/jira-router-routes-software-classic-backlog-entries/src/classicSoftwareBacklogEmbedRouteEntry.tsx';
import { classicSoftwareBoardRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-entries/src/classicSoftwareBoardRouteEntry.tsx';
import { classicSoftwareBoardEmbedRouteEntry } from '@atlassian/jira-router-routes-software-classic-board-entries/src/classicSoftwareBoardEmbedRouteEntry.tsx';
import { rapidboardUserBacklogRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardUserBacklogRouteEntry.tsx';
import { userBoardVelocityRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/userBoardVelocityRouteEntry.tsx';
import { rapidboardUserReportsOverviewRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardUserReportsOverviewRouteEntry.tsx';
import { rapidboardUserReportRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardUserReportRouteEntry.tsx';
import { userBoardRouteEntry } from '@atlassian/jira-router-routes-user-board-entries/src/userBoardRouteEntry.tsx';
import { userBoardSettingsRouteEntry } from '@atlassian/jira-router-routes-user-board-entries/src/userBoardSettingsRouteEntry.tsx';
import { userBoardViewNotFoundRouteEntry } from '@atlassian/jira-router-routes-user-board-entries/src/userBoardViewNotFoundRouteEntry.tsx';
import { rapidboardBacklog1RouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardBacklog1RouteEntry.tsx';
import { rapidboardReport1RouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardReport1RouteEntry.tsx';
import { softwareCodeClassicRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/softwareCodeClassicRouteEntry.tsx';
import { softwareSecurityClassicRouteEntry } from '@atlassian/jira-router-routes-software-security-entries/src/softwareSecurityClassicRouteEntry.tsx';
import { softwareDeploymentsClassicRouteEntry } from '@atlassian/jira-router-routes-software-deployments-entries/src/softwareDeploymentsClassicRouteEntry.tsx';
import { softwarePeriscopeClassicRouteEntry } from '@atlassian/jira-router-routes-software-periscope-entries/src/softwarePeriscopeClassicRouteEntry.tsx';
import { rapidboardLastVisitedProjectRedirectRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/rapidboardLastVisitedProjectRedirectRouteEntry.tsx';
import { projectDirectoryRedirectRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/projectDirectoryRedirectRouteEntry.tsx';
import { softwareIncidentsClassicRouteEntry } from '@atlassian/jira-router-routes-incidents-entries/src/softwareIncidentsClassicRouteEntry.tsx';
import { softwareViewNotFoundRouteEntry } from '@atlassian/jira-router-routes-software-view-not-found-entries/src/softwareViewNotFoundRouteEntry.tsx';
import { softwareViewNotFoundClassicRouteEntry } from '@atlassian/jira-router-routes-software-view-not-found-entries/src/softwareViewNotFoundClassicRouteEntry.tsx';
import { softwareBoardViewNotFoundRouteEntry } from '@atlassian/jira-router-routes-software-view-not-found-entries/src/softwareBoardViewNotFoundRouteEntry.tsx';
import { softwareBoardViewNotFoundClassicRouteEntry } from '@atlassian/jira-router-routes-software-view-not-found-entries/src/softwareBoardViewNotFoundClassicRouteEntry.tsx';
import { legacyRapidBoardRouteEntry } from '@atlassian/jira-router-routes-software-classic-rapid-board-entries/src/legacyRapidBoardRouteEntry.tsx';
import { projectSettingsCoreAccessRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAccessRedirectRouteEntry.tsx';
import { projectSettingsCoreAccessRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAccessRouteEntry.tsx';
import { projectSettingsCoreApprovalsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreApprovalsRouteEntry.tsx';
import { projectSettingsCoreAppsFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAppsFieldsRouteEntry.tsx';
import { projectSettingsCoreAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAppsRouteEntry.tsx';
import { projectSettingsCoreAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAutomationRouteEntry.tsx';
import { projectSettingsCoreAutomationV2RouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreAutomationV2RouteEntry.tsx';
import { projectSettingsCoreDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreDataClassificationsRouteEntry.tsx';
import { projectSettingsCoreDefaultRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreDefaultRedirectRouteEntry.tsx';
import { projectSettingsCoreDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreDetailsRouteEntry.tsx';
import { projectSettingsCoreFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreFieldsRouteEntry.tsx';
import { projectSettingsCoreForgeAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreForgeAppsRouteEntry.tsx';
import { projectSettingsCoreIssueTypesRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreIssueTypesRouteEntry.tsx';
import { projectSettingsCoreIssueTypesWorkflowRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreIssueTypesWorkflowRouteEntry.tsx';
import { projectSettingsCoreNotificationsEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreNotificationsEmailRouteEntry.tsx';
import { projectSettingsCoreNotificationsIssueLogsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreNotificationsIssueLogsRouteEntry.tsx';
import { projectSettingsCoreNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreNotificationsRouteEntry.tsx';
import { projectSettingsCoreProformaJiraFormBuilderRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreProformaJiraFormBuilderRouteEntry.tsx';
import { projectSettingsCoreProformaProjectFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-core-entries/src/projectSettingsCoreProformaProjectFormsRouteEntry.tsx';
import { classicProjectSettingsDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsDetailsRouteEntry.tsx';
import { classicProjectSettingsFeaturesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsFeaturesRouteEntry.tsx';
import { classicProjectSettingsOpsgenieRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsOpsgenieRouteEntry.tsx';
import { classicProjectSettingsSoftwareNotificationsIssueLogsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareNotificationsIssueLogsRouteEntry.tsx';
import { classicProjectSettingsSoftwareProformaEditFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareProformaEditFormsRouteEntry.tsx';
import { classicProjectSettingsSoftwareProformaFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareProformaFormsRouteEntry.tsx';
import { classicProjectSettingsSoftwareRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsSoftwareRedirectRouteEntry.tsx';
import { classicProjectSettingsToolchainRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/classicProjectSettingsToolchainRouteEntry.tsx';
import { legacyProjectReleasesNoteConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/legacyProjectReleasesNoteConfigRouteEntry.tsx';
import { legacyProjectReleasesNoteRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/legacyProjectReleasesNoteRouteEntry.tsx';
import { projectSettingsAutomationCRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsAutomationCRouteEntry.tsx';
import { projectSettingsAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsAutomationRouteEntry.tsx';
import { projectSettingsForgeAppsNextgenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsForgeAppsNextgenRouteEntry.tsx';
import { projectSettingsForgeAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsForgeAppsRouteEntry.tsx';
import { projectSettingsOpsgenieRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsOpsgenieRouteEntry.tsx';
import { projectSettingsSoftwareAccessRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAccessRedirectRouteEntry.tsx';
import { projectSettingsSoftwareAccessRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAccessRouteEntry.tsx';
import { projectSettingsSoftwareAppsFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAppsFieldsRouteEntry.tsx';
import { projectSettingsSoftwareAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAppsRouteEntry.tsx';
import { projectSettingsSoftwareAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareAutomationRouteEntry.tsx';
import { projectSettingsSoftwareBoardsCardRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsCardRouteEntry.tsx';
import { projectSettingsSoftwareBoardsCsmRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsCsmRouteEntry.tsx';
import { projectSettingsSoftwareBoardsCustomFiltersRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsCustomFiltersRouteEntry.tsx';
import { projectSettingsSoftwareBoardsRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsRedirectRouteEntry.tsx';
import { projectSettingsSoftwareBoardsRoadmapRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsRoadmapRouteEntry.tsx';
import { projectSettingsSoftwareBoardsTimelineRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareBoardsTimelineRouteEntry.tsx';
import { projectSettingsSoftwareDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareDataClassificationsRouteEntry.tsx';
import { projectSettingsSoftwareDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareDetailsRouteEntry.tsx';
import { projectSettingsSoftwareFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareFieldsRouteEntry.tsx';
import { projectSettingsSoftwareIssuetypesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareIssuetypesRouteEntry.tsx';
import { projectSettingsSoftwareIssuetypesWorkflowRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareIssuetypesWorkflowRouteEntry.tsx';
import { projectSettingsSoftwareNextGenFeaturesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNextGenFeaturesRouteEntry.tsx';
import { projectSettingsSoftwareNotificationEmailCRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationEmailCRouteEntry.tsx';
import { projectSettingsSoftwareNotificationEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationEmailRouteEntry.tsx';
import { projectSettingsSoftwareNotificationsIssueLogsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationsIssueLogsRouteEntry.tsx';
import { projectSettingsSoftwareNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareNotificationsRouteEntry.tsx';
import { projectSettingsSoftwareProformaJiraFormBuilderRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareProformaJiraFormBuilderRouteEntry.tsx';
import { projectSettingsSoftwareProformaProjectFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareProformaProjectFormsRouteEntry.tsx';
import { projectSettingsSoftwareRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsSoftwareRedirectRouteEntry.tsx';
import { projectSettingsToolchainRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/projectSettingsToolchainRouteEntry.tsx';
import { softwareReleaseNotesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-software-entries/src/softwareReleaseNotesRouteEntry.tsx';
import { projectSettingsServicedeskClassicAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskClassicAppsRouteEntry.tsx';
import { projectSettingsServicedeskDetailsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskDetailsRouteEntry.tsx';
import { projectSettingsServicedeskFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskFieldsRouteEntry.tsx';
import { projectSettingsServicedeskIssuetypesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskIssuetypesRouteEntry.tsx';
import { projectSettingsServicedeskIssuetypesWorkflowRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskIssuetypesWorkflowRouteEntry.tsx';
import { projectSettingsServicedeskProformaJiraFormBuilderRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskProformaJiraFormBuilderRouteEntry.tsx';
import { projectSettingsServicedeskProformaProjectFormsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskProformaProjectFormsRouteEntry.tsx';
import { projectSettingsServicedeskAccessRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAccessRedirectRouteEntry.tsx';
import { projectSettingsServicedeskAccessRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAccessRouteEntry.tsx';
import { projectSettingsServicedeskAppsFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAppsFieldsRouteEntry.tsx';
import { projectSettingsServicedeskAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAppsRouteEntry.tsx';
import { projectSettingsServicedeskAutomationRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskAutomationRouteEntry.tsx';
import { projectSettingsServicedeskDataClassificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskDataClassificationsRouteEntry.tsx';
import { projectSettingsServicedeskItsmFeaturesRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskItsmFeaturesRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsInternalNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsInternalNotificationsRouteEntry.tsx';
import { projectSettingsServicedeskInternalNotificationsNextGenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskInternalNotificationsNextGenRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsNotificationEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsNotificationEmailRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsEmailRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskNotificationsRouteEntry.tsx';
import { projectSettingsServicedeskLanguageKeyRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskLanguageKeyRouteEntry.tsx';
import { projectSettingsServicedeskLanguageRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskLanguageRouteEntry.tsx';
import { projectSettingsServicedeskChannelsPermissionsNextGenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsPermissionsNextGenRouteEntry.tsx';
import { projectSettingsServicedeskCustomerPermissionsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskCustomerPermissionsRouteEntry.tsx';
import { projectSettingsServicedeskChannelsEmailNextGenRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsEmailNextGenRouteEntry.tsx';
import { projectSettingsServicedeskEmailRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskEmailRouteEntry.tsx';
import { projectSettingsServicedeskPortalSettingsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskPortalSettingsRouteEntry.tsx';
import { projectSettingsServicedeskChannelsPortalRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsPortalRouteEntry.tsx';
import { projectSettingsServicedeskExternalLinksRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskExternalLinksRouteEntry.tsx';
import { projectSettingsServicedeskChannelsWidgetRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsWidgetRouteEntry.tsx';
import { projectSettingsServicedeskWidgetRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskWidgetRouteEntry.tsx';
import { projectSettingsServicedeskChangeManagementRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChangeManagementRouteEntry.tsx';
import { projectSettingsServicedeskIncidentManagementRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskIncidentManagementRouteEntry.tsx';
import { projectSettingsServicedeskChannelsChatRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsChatRouteEntry.tsx';
import { projectSettingsServicedeskChatRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChatRouteEntry.tsx';
import { projectSettingsServicedeskChannelsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskChannelsRouteEntry.tsx';
import { servicedeskSlaSettingsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-sla-settings-entries/src/servicedeskSlaSettingsRouteEntry.tsx';
import { projectSettingsServicedeskFeedbackRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskFeedbackRouteEntry.tsx';
import { projectSettingsServicedeskForgeAppsRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskForgeAppsRouteEntry.tsx';
import { projectSettingsServicedeskRedirectRouteEntry } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-entries/src/projectSettingsServicedeskRedirectRouteEntry.tsx';
import { servicedeskPlaybooksListRouteEntry } from '@atlassian/jira-router-routes-project-settings-playbooks-entries/src/servicedeskPlaybooksListRouteEntry.tsx';
import { servicedeskPlaybooksEditRouteEntry } from '@atlassian/jira-router-routes-project-settings-playbooks-entries/src/servicedeskPlaybooksEditRouteEntry.tsx';
import { projectSettingsServicedeskAutomationEditRuleRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationEditRuleRouteEntry.tsx';
import { projectSettingsServicedeskAutomationCopyRuleRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationCopyRuleRouteEntry.tsx';
import { projectSettingsServicedeskAutomationCreateRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationCreateRouteEntry.tsx';
import { projectSettingsServicedeskAutomationViewLogRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationViewLogRouteEntry.tsx';
import { projectSettingsServicedeskAutomationSummaryRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskAutomationSummaryRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectCreateRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectCreateRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectEditRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectEditRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectCopyRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectCopyRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectViewLogRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectViewLogRouteEntry.tsx';
import { projectSettingsServicedeskLegacyAutomationRedirectSummaryRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries/src/projectSettingsServicedeskLegacyAutomationRedirectSummaryRouteEntry.tsx';
import { projectSettingsServicedeskCustomerNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-entries/src/projectSettingsServicedeskCustomerNotificationsRouteEntry.tsx';
import { projectSettingsServicedeskNotificationsCustomerNotificationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-entries/src/projectSettingsServicedeskNotificationsCustomerNotificationsRouteEntry.tsx';
import { projectSettingsServicedeskJourneysRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-entries/src/projectSettingsServicedeskJourneysRouteEntry.tsx';
import { projectSettingsServicedeskJourneyConfigurationRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-entries/src/projectSettingsServicedeskJourneyConfigurationRouteEntry.tsx';
import { projectSettingsServicedeskJourneyConfigurationEditRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-entries/src/projectSettingsServicedeskJourneyConfigurationEditRouteEntry.tsx';
import { projectSettingsServicedeskKbRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-knowledge-base-entries/src/projectSettingsServicedeskKbRouteEntry.tsx';
import { projectSettingsServicedeskUnassignedTicketTypeFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-entries/src/projectSettingsServicedeskUnassignedTicketTypeFieldsRouteEntry.tsx';
import { projectSettingsServicedeskItsmTicketTypeFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-entries/src/projectSettingsServicedeskItsmTicketTypeFieldsRouteEntry.tsx';
import { projectSettingsServicedeskTicketTypeFieldsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-entries/src/projectSettingsServicedeskTicketTypeFieldsRouteEntry.tsx';
import { projectSettingsServicedeskUnassignedTicketTypesConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-entries/src/projectSettingsServicedeskUnassignedTicketTypesConfigRouteEntry.tsx';
import { projectSettingsServicedeskItsmTicketTypesConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-entries/src/projectSettingsServicedeskItsmTicketTypesConfigRouteEntry.tsx';
import { projectSettingsServicedeskTicketTypesConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-entries/src/projectSettingsServicedeskTicketTypesConfigRouteEntry.tsx';
import { landingPageRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/landingPageRouteEntry.tsx';
import { aiAnswersRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/aiAnswersRouteEntry.tsx';
import { browseConversationsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/browseConversationsRouteEntry.tsx';
import { browseIntentsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/browseIntentsRouteEntry.tsx';
import { browseStandardFlowsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/browseStandardFlowsRouteEntry.tsx';
import { performanceRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/performanceRouteEntry.tsx';
import { settingsRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/settingsRouteEntry.tsx';
import { viewIntentRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/viewIntentRouteEntry.tsx';
import { viewStandardFlowRouteEntry } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries/src/viewStandardFlowRouteEntry.tsx';
import { servicedeskWorkflowConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-workflow-scheme-config-entries/src/servicedeskWorkflowConfigRouteEntry.tsx';
import { softwareStaffingRouteEntry } from '@atlassian/jira-router-routes-software-staffing-entries/src/softwareStaffingRouteEntry.tsx';
import { cmpVelocityRouteEntry } from '@atlassian/jira-router-routes-classic-projects-entries/src/cmpVelocityRouteEntry.tsx';
import { servicedeskQueuesViewsConfigRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskQueuesViewsConfigRouteEntry.tsx';
import { servicedeskPracticeViewsConfigRouteEntry } from '@atlassian/jira-router-routes-servicedesk-queues-entries/src/servicedeskPracticeViewsConfigRouteEntry.tsx';
import { assetsSearchRouteEntry } from '@atlassian/jira-router-routes-assets-entries/src/assetsSearchRouteEntry.tsx';
import { rovoSearchRouteEntry } from '@atlassian/jira-router-routes-rovo-search-entries/src/rovoSearchRouteEntry.tsx';
import { piEditIssueEmbedRouteEntry } from '@atlassian/jira-router-routes-product-integrations-entries/src/piEditIssueEmbedRouteEntry.tsx';
import { piCreateIssueEmbedRouteEntry } from '@atlassian/jira-router-routes-product-integrations-entries/src/piCreateIssueEmbedRouteEntry.tsx';
import { projectSettingsCustomerServiceFeaturesRouteEntry } from '@atlassian/jira-router-routes-project-settings-customer-service-entries/src/projectSettingsCustomerServiceFeaturesRouteEntry.tsx';
import { applinksExtraAuthHealthcheckRouteEntry } from '@atlassian/jira-router-routes-admin-pages-applinks-entries/src/applinksExtraAuthHealthcheckRouteEntry.tsx';
import { globalSettingsOptimizeFieldConfigurationSchemeRouteEntry } from '@atlassian/jira-router-routes-admin-pages-fields-entries/src/globalSettingsOptimizeFieldConfigurationSchemeRouteEntry.tsx';

/**
 *
 * This method should contain an array of all routes in Jira Frontend.
 *
 * Please do not remove the `export` as this function is used by BUNDLEANALYZER-ROUTES
 *
 * We aim to make these routes static, which is it currently very much not.
 * To aid the gradual refactor towards static routes, we have an eslint rule
 * (jira/js/static-routes) which enforces static content on function definitions
 * with an identifier matching regex: /^get(?!Static).+Routes$/
 *
 * We will make the plugin more strict and cover more fields as we work on this
 * refactor.
 *
 * To be a good citizen, please make your routes as static as possible and avoid
 * feature flags on routes - feature flagging inside the app rather than the route
 * definitions is preferred (and will be gradually required).
 *
 * If you need to remove a route, either just remove it or return a conditional component
 * by using componentWithCondition and return the 404 component with FF true, and current component with FF false.
 * Once rolled out you can remove the route entirely and the 404 component will be returned anyway.
 *
 * There is also a webpack plugin for SSR which we use to import routes while we
 * make routes static (`services/jira-ssr/scripts/babel-plugins/jira-routes.js`).
 * Breaking the contract we enforce with eslint is likely to break this functionality,
 * so please don't (for the sake of my greying hair).
 *
 * Remember to add routes with the same prefix from the most specific to the most generic.
 * For example:
 * /jira/product/share
 * /jira/product/create
 * /jira/product
 *
 * If you have any questions about this, please reach out to disturbed at #jira-frontend-perf
 */

export const getSpaRoutes = (): Route[] => [
	connectGeneralServiceDeskProjectRouteEntry,
	connectGeneralRouteEntry,
	connectGeneralInvalidRouteEntry,
	connectProjectRouteEntry,
	forgeAdminSettingsRouteEntry,
	forgeAdminRouteEntry,
	forgeProjectRouteEntry,
	forgeProjectServicedeskRouteEntry,
	forgeServicedeskQueueRouteEntry,
	forgeFieldContextualConfigurationRouteEntry,
	forgeGlobalRouteEntry,
	forgePersonalSettingsRouteEntry,
	softwareViewNotFoundRouteEntry,
	softwareViewNotFoundClassicRouteEntry,
	softwareBoardViewNotFoundRouteEntry,
	softwareBoardViewNotFoundClassicRouteEntry,
	softwareBoardEmbedRouteEntry,
	softwareBoardRouteEntry,
	softwareBacklogEmbedRouteEntry,
	softwareBacklogRouteEntry,
	softwareFormBuilderRouteEntry,
	softwareFormRouteEntry,
	softwareFormSubmitRouteEntry,
	globalSettingsOptimizeFieldConfigurationSchemeRouteEntry,
	softwareFormSubmitEmbedRouteEntry,
	softwareFormSubmitPublicRouteEntry,
	softwareGoalsRouteEntry,
	softwareReportsBurnupRouteEntry,
	softwareReportsVelocityRouteEntry,
	cmpVelocityRouteEntry,
	softwareReportsCumulativeRouteEntry,
	softwareReportsBurndownRouteEntry,
	softwareReportsCycleTimeRouteEntry,
	softwareReportsDeploymentFrequencyRouteEntry,
	softwareReportsControlRouteEntry,
	softwareReportsRouteEntry,
	softwareNextgenRedirectRouteEntry,
	softwareCodeRouteEntry,
	softwareSecurityNextGenRouteEntry,
	softwareSummaryEmbedRouteEntry,
	softwareSummaryRouteEntry,
	softwareDeploymentsNextGenRouteEntry,
	softwarePeriscopeNextGenRouteEntry,
	softwareVersionDetailEmbedRouteEntry,
	softwareVersionDetailRouteEntry,
	softwareVersionDetailNoTabPathRedirectRouteEntry,
	softwareVersionDetailLegacyRedirectRouteEntry,
	softwareTimelineRouteEntry,
	softwareRoadmapRouteEntry,
	softwareCalendarEmbedRouteEntry,
	softwareCalendarRouteEntry,
	softwareListEmbedRouteEntry,
	softwareListRouteEntry,
	softwareRoadmapRedirectRouteEntry,
	softwareIncidentsNextGenRouteEntry,
	softwareStaffingRouteEntry,
	polarisCollectionsRouteEntry,
	polarisIdeasRouteEntry,
	polarisOnboardingRouteEntry,
	polarisSettingsAccessRouteEntry,
	polarisSettingsGlobalFieldsRouteEntry,
	polarisSettingsFinancialYearRouteEntry,
	polarisSettingsEarlyAccessFeaturesRouteEntry,
	polarisUnlicensedRouteEntry,
	softwareOnboardingRouteEntry,
	softwareRoadmapClassicRouteEntry,
	softwareTimelineClassicRouteEntry,
	classicSoftwareBoardSettingsRouteEntry,
	classicSoftwareBoardSettingsInsightsRapidboardBacklogRouteEntry,
	classicSoftwareBoardSettingsInsightsRapidboardBoardRouteEntry,
	softwareCalendarClassicEmbedRouteEntry,
	softwareCalendarClassicRouteEntry,
	softwareListEmbedClassicRouteEntry,
	softwareListClassicRouteEntry,
	softwareReportsDeploymentFrequencyClassicBoardRouteEntry,
	softwareReportsCycleTimeClassicBoardRouteEntry,
	rapidboardReportRouteEntry,
	softwareReportsCycleTimeClassicRouteEntry,
	softwareReportsOverviewRouteEntry,
	softwareReportsOverviewBoardlessRouteEntry,
	softwareFormBuilderClassicRouteEntry,
	softwareFormSubmitClassicRouteEntry,
	softwareFormSubmitEmbedClassicRouteEntry,
	softwareFormSubmitClassicPublicRouteEntry,
	softwareFormClassicRouteEntry,
	softwareGoalsClassicRouteEntry,
	softwareSummaryEmbedClassicRouteEntry,
	softwareSummaryClassicRouteEntry,
	softwareClassicProformaIssueFormsDirectRouteEntry,
	softwareClassicProformaIssueFormsDirectEmbedRouteEntry,
	softwareReportsDeploymentFrequencyClassicRouteEntry,
	classicSoftwareBacklogEmbedRouteEntry,
	classicSoftwareBacklogRouteEntry,
	classicSoftwareBoardEmbedRouteEntry,
	classicSoftwareBoardRouteEntry,
	rapidboardUserBacklogRouteEntry,
	userBoardVelocityRouteEntry,
	rapidboardUserReportRouteEntry,
	userBoardViewNotFoundRouteEntry,
	userBoardRouteEntry,
	rapidboardUserReportsOverviewRouteEntry,
	userBoardSettingsRouteEntry,
	rapidboardBacklog1RouteEntry,
	rapidboardReport1RouteEntry,
	legacyRapidBoardRouteEntry,
	softwareCodeClassicRouteEntry,
	softwareSecurityClassicRouteEntry,
	softwareDeploymentsClassicRouteEntry,
	softwarePeriscopeClassicRouteEntry,
	rapidboardLastVisitedProjectRedirectRouteEntry,
	projectDirectoryRedirectRouteEntry,
	softwareIncidentsClassicRouteEntry,
	softwareInboxNextGenRouteEntry,
	softwareInboxClassicRouteEntry,
	/*
	 *************************************************** ATTENTION PROJECT SETTINGS ROUTES ***************************************************
	 * Take special care with any redirect components you render. For legacy routes they should redirect to the legacy URL,
	 * and for product routes the should redirect to the product URL.
	 */
	projectSettingsSoftwareIssuetypesRouteEntry,
	projectSettingsSoftwareIssuetypesWorkflowRouteEntry,
	projectSettingsSoftwareAppsFieldsRouteEntry,
	projectSettingsSoftwareAppsRouteEntry,
	projectSettingsSoftwareDetailsRouteEntry,
	projectSettingsSoftwareFieldsRouteEntry,
	projectSettingsSoftwareAccessRouteEntry,
	projectSettingsSoftwareAccessRedirectRouteEntry,
	projectSettingsSoftwareProformaProjectFormsRouteEntry,
	projectSettingsSoftwareProformaJiraFormBuilderRouteEntry,
	projectSettingsAutomationRouteEntry,
	projectSettingsSoftwareDataClassificationsRouteEntry,
	classicProjectSettingsDetailsRouteEntry,
	projectSettingsSoftwareNextGenFeaturesRouteEntry,
	projectSettingsSoftwareNotificationsRouteEntry,
	projectSettingsSoftwareNotificationsIssueLogsRouteEntry,
	projectSettingsOpsgenieRouteEntry,
	classicProjectSettingsOpsgenieRouteEntry,
	projectSettingsToolchainRouteEntry,
	classicProjectSettingsToolchainRouteEntry,
	classicProjectSettingsFeaturesRouteEntry,
	classicProjectSettingsSoftwareNotificationsIssueLogsRouteEntry,
	projectSettingsSoftwareNotificationEmailCRouteEntry,
	projectSettingsSoftwareNotificationEmailRouteEntry,
	legacyProjectReleasesNoteRouteEntry,
	legacyProjectReleasesNoteConfigRouteEntry,
	softwareReleaseNotesRouteEntry,
	projectSettingsSoftwareAutomationRouteEntry,
	classicProjectSettingsSoftwareProformaFormsRouteEntry,
	classicProjectSettingsSoftwareProformaEditFormsRouteEntry,
	projectSettingsSoftwareBoardsRedirectRouteEntry,
	projectSettingsSoftwareBoardsCsmRouteEntry,
	projectSettingsSoftwareBoardsCustomFiltersRouteEntry,
	projectSettingsSoftwareBoardsCardRouteEntry,
	projectSettingsSoftwareBoardsRoadmapRouteEntry,
	projectSettingsSoftwareBoardsTimelineRouteEntry,
	projectSettingsAutomationCRouteEntry,
	projectSettingsForgeAppsRouteEntry,
	projectSettingsForgeAppsNextgenRouteEntry,
	projectSettingsSoftwareRedirectRouteEntry,
	classicProjectSettingsSoftwareRedirectRouteEntry,
	projectSettingsServicedeskClassicAppsRouteEntry,
	projectSettingsServicedeskIssuetypesRouteEntry,
	projectSettingsServicedeskIssuetypesWorkflowRouteEntry,
	projectSettingsServicedeskFieldsRouteEntry,
	projectSettingsServicedeskAppsFieldsRouteEntry,
	projectSettingsServicedeskAppsRouteEntry,
	projectSettingsServicedeskDetailsRouteEntry,
	projectSettingsServicedeskAccessRouteEntry,
	projectSettingsServicedeskAccessRedirectRouteEntry,
	projectSettingsServicedeskProformaProjectFormsRouteEntry,
	projectSettingsServicedeskProformaJiraFormBuilderRouteEntry,
	projectSettingsServicedeskAutomationRouteEntry,
	projectSettingsServicedeskDataClassificationsRouteEntry,
	projectSettingsServicedeskKbRouteEntry,
	projectSettingsServicedeskUnassignedTicketTypeFieldsRouteEntry,
	projectSettingsServicedeskItsmTicketTypeFieldsRouteEntry,
	projectSettingsServicedeskTicketTypeFieldsRouteEntry,
	projectSettingsServicedeskUnassignedTicketTypesConfigRouteEntry,
	projectSettingsServicedeskItsmTicketTypesConfigRouteEntry,
	projectSettingsServicedeskTicketTypesConfigRouteEntry,
	projectSettingsServicedeskAutomationEditRuleRouteEntry,
	projectSettingsServicedeskAutomationCopyRuleRouteEntry,
	projectSettingsServicedeskAutomationCreateRouteEntry,
	projectSettingsServicedeskAutomationViewLogRouteEntry,
	projectSettingsServicedeskAutomationSummaryRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectCreateRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectEditRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectCopyRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectViewLogRouteEntry,
	projectSettingsServicedeskLegacyAutomationRedirectSummaryRouteEntry,
	projectSettingsServicedeskCustomerNotificationsRouteEntry,
	projectSettingsServicedeskNotificationsCustomerNotificationsRouteEntry,
	landingPageRouteEntry,
	browseIntentsRouteEntry,
	viewIntentRouteEntry,
	browseStandardFlowsRouteEntry,
	viewStandardFlowRouteEntry,
	browseConversationsRouteEntry,
	settingsRouteEntry,
	aiAnswersRouteEntry,
	performanceRouteEntry,
	servicedeskPlaybooksListRouteEntry,
	servicedeskPlaybooksEditRouteEntry,
	projectSettingsCustomerServiceFeaturesRouteEntry,
	projectSettingsServicedeskJourneysRouteEntry,
	projectSettingsServicedeskJourneyConfigurationRouteEntry,
	projectSettingsServicedeskJourneyConfigurationEditRouteEntry,
	servicedeskWorkflowConfigRouteEntry,
	projectSettingsServicedeskItsmFeaturesRouteEntry,
	projectSettingsServicedeskNotificationsInternalNotificationsRouteEntry,
	projectSettingsServicedeskInternalNotificationsNextGenRouteEntry,
	projectSettingsServicedeskNotificationsNotificationEmailRouteEntry,
	projectSettingsServicedeskNotificationsEmailRouteEntry,
	projectSettingsServicedeskNotificationsRouteEntry,
	projectSettingsServicedeskLanguageKeyRouteEntry,
	projectSettingsServicedeskLanguageRouteEntry,
	projectSettingsServicedeskChannelsPermissionsNextGenRouteEntry,
	projectSettingsServicedeskCustomerPermissionsRouteEntry,
	projectSettingsServicedeskChannelsEmailNextGenRouteEntry,
	projectSettingsServicedeskEmailRouteEntry,
	projectSettingsServicedeskPortalSettingsRouteEntry,
	projectSettingsServicedeskChannelsPortalRouteEntry,
	projectSettingsServicedeskExternalLinksRouteEntry,
	projectSettingsServicedeskChannelsWidgetRouteEntry,
	projectSettingsServicedeskWidgetRouteEntry,
	projectSettingsServicedeskChangeManagementRouteEntry,
	projectSettingsServicedeskIncidentManagementRouteEntry,
	projectSettingsServicedeskChannelsChatRouteEntry,
	projectSettingsServicedeskChatRouteEntry,
	projectSettingsServicedeskChannelsRouteEntry,
	servicedeskSlaSettingsRouteEntry,
	projectSettingsServicedeskFeedbackRouteEntry,
	projectSettingsServicedeskForgeAppsRouteEntry,
	projectSettingsServicedeskRedirectRouteEntry,
	projectSettingsCoreIssueTypesRouteEntry,
	projectSettingsCoreFieldsRouteEntry,
	projectSettingsCoreIssueTypesWorkflowRouteEntry,
	projectSettingsCoreAppsFieldsRouteEntry,
	projectSettingsCoreAppsRouteEntry,
	projectSettingsCoreDetailsRouteEntry,
	projectSettingsCoreAccessRouteEntry,
	projectSettingsCoreAccessRedirectRouteEntry,
	projectSettingsCoreProformaProjectFormsRouteEntry,
	projectSettingsCoreProformaJiraFormBuilderRouteEntry,
	projectSettingsCoreAutomationV2RouteEntry,
	projectSettingsCoreDataClassificationsRouteEntry,
	projectSettingsCoreAutomationRouteEntry,
	projectSettingsCoreNotificationsRouteEntry,
	projectSettingsCoreNotificationsIssueLogsRouteEntry,
	projectSettingsCoreNotificationsEmailRouteEntry,
	projectSettingsCoreForgeAppsRouteEntry,
	projectSettingsCoreApprovalsRouteEntry,
	projectSettingsCoreDefaultRedirectRouteEntry,
	classicBusinessProjectViewNotFoundRouteEntry,
	jiraBusinessSummaryRouteEntry,
	jiraBusinessBoardEmbedRouteEntry,
	jiraBusinessBoardCustomEmbedRouteEntry,
	jiraBusinessBoardRouteEntry,
	jiraBusinessCalendarEmbedRouteEntry,
	jiraBusinessCalendarRouteEntry,
	jiraBusinessReportsOverviewRouteEntry,
	classicBusinessProjectShortcutRouteEntry,
	jiraBusinessListRouteEntry,
	classicBusinessProjectFormRouteEntry,
	classicBusinessProjectFormSubmitRouteEntry,
	classicBusinessProjectFormBuilderRouteEntry,
	classicBusinessProjectFormSubmitPublicRouteEntry,
	jiraBusinessTimelineRouteEntry,
	classicBusinessProjectApprovalsRouteEntry,
	classicBusinessProjectPagesRouteEntry,
	jiraBusinessAttachmentsRouteEntry,
	jiraBusinessTimelineEmbedRouteEntry,
	jiraBusinessListEmbedRouteEntry,
	classicBusinessProjectFormSubmitEmbedRouteEntry,
	jiraBusinessSummaryEmbedRouteEntry,
	classicBusinessProjectSampleOnboardingRouteEntry,
	classicBusinessOverviewTimelineRouteEntry,
	classicBusinessOverviewCalendarRouteEntry,
	classicBusinessOverviewSummaryRouteEntry,
	jiraBusinessProjectRedirectRouteEntry,
	jiraBusinessOverviewRedirectRouteEntry,
	globalSettingsIssueFieldScreensRouteEntry,
	globalSettingsIssueFieldSchemesRouteEntry,
	globalSettingsIssueTypesScreenSchemesRouteEntry,
	globalSettingsIssueWorkflowEditRouteEntry,
	globalSettingsIssueWorkflow1RouteEntry,
	globalSettingsIssueHierarchyRouteEntry,
	globalSettingsIssueHierarchyRedirectRouteEntry,
	globalSettingsIssueTypesRouteEntry,
	globalSettingsIssueTypesSchemesRouteEntry,
	globalSettingsContactAdministratorRouteEntry,
	globalSettingsSystemUiAnnouncementBannerRouteEntry,
	productsJsmCustomerAccessRouteEntry,
	productsJsmAuthenticationSettingsRouteEntry,
	globalSettingsSystemBulkMailRouteEntry,
	globalSettingsEditPermissionSchemesRouteEntry,
	globalSettingsPermissionSchemesRouteEntry,
	globalSettingsFormDataConnectionsRouteEntry,
	globalSettingsIssuesNewIssueSearchRouteEntry,
	globalSettingsPerformanceOverviewRouteEntry,
	globalSettingsPerformanceMetricDetailRouteEntry,
	globalSettingsPerformanceProjectCleanupRouteEntry,
	globalSettingsPerformanceCustomFieldRouteEntry,
	globalSettingsPerformanceIssueArchivalRouteEntry,
	globalSettingsPerformanceProjectRoleActorRouteEntry,
	globalSettingsIssueFieldsManagementRouteEntry,
	globalSettingsIssuesPrioritiesRouteEntry,
	globalSettingsIssuesPrioritySchemesRouteEntry,
	globalSettingsGlobalPermissionsRouteEntry,
	systemConfigurationRouteEntry,
	systemConfigurationRedirectRouteEntry,
	externalImportRouteEntry,
	emailNotificationsLogAccessRouteEntry,
	archivedIssuesRedirectRouteEntry,
	archivedIssuesJswRouteEntry,
	archivedIssuesJswCompanyRouteEntry,
	archivedIssuesJsmRouteEntry,
	archivedIssuesJwmRouteEntry,
	issueCreateEmbedRouteEntry,
	piCreateIssueEmbedRouteEntry,
	piEditIssueEmbedRouteEntry,
	issueNavigatorProjectClassicRouteEntry,
	issueNavigatorProjectSoftwareRouteEntry,
	issueNavigatorProjectServicedeskRouteEntry,
	issueNavigatorProjectBusinessRouteEntry,
	issueNavigatorGlobalRouteEntry,
	issueNavigatorGlobalJiraRouteEntry,
	projectIssueNavigatorRouteEntry,
	globalIssueNavigatorBrowseIssueRedirectRouteEntry,
	appRequestsRouteEntry,
	promotionsRouteEntry,
	findAppsRouteEntry,
	proformaExportRouteEntry,
	proformaIssueFormsGeneralRouteEntry,
	servicedeskProformaIssueFormsDirectRouteEntry,
	servicedeskProformaIssueFormsDirectEmbedRouteEntry,
	classicBusinessProformaIssueFormsDirectRouteEntry,
	classicBusinessProformaIssueFormsDirectEmbedRouteEntry,
	softwareProformaIssueFormsDirectRouteEntry,
	softwareProformaIssueFormsDirectEmbedRouteEntry,
	browseIssueEmbedRouteEntry,
	browseIssueRouteEntry,
	compassProductSettingsRouteEntry,
	jiraHomeRouteEntry,
	jiraSearchRouteEntry,
	homeRedirectRouteEntry,
	homeOnboardingRedirectRouteEntry,
	devopsConnectedProjectRouteEntry,
	peopleProfilesRouteEntry,
	trashProjectsDirectoryRedirectRouteEntry,
	settingsAutomationRouteEntry,
	trashProjectsDirectoryRouteEntry,
	archivedProjectsDirectoryRouteEntry,
	projectsDirectoryRedirectRouteEntry,
	projectsDirectoryLegacyPathRedirectRouteEntry,
	projectsDirectoryRouteEntry,
	projectsDirectoryManageRedirectRouteEntry,
	manageProjectsDirectoryRouteEntry,
	projectsDirectoryManageRootRedirectRouteEntry,
	classicProjectSettingsIssueLayoutRouteEntry,
	classicProjectSettingsDataClassificationsRouteEntry,
	projectComponentsRedirectRouteEntry,
	projectComponentsRouteEntry,
	projectSettingsComponentsRouteEntry,
	projectVersionsRouteEntry,
	createProjectRouteEntry,
	projectSettingsVersionsRouteEntry,
	projectPagesLegacyRouteEntry,
	projectPagesRouteEntry,
	opsgenieScheduleRedirectRouteEntry,
	opsgenieScheduleRouteEntry,
	dashboardEditRouteEntry,
	dashboardWallboardRouteEntry,
	dashboardViewRouteEntry,
	dashboardViewEmbedRouteEntry,
	dashboardsDirectoryRedirectRouteEntry,
	dashboardsDirectoryRouteEntry,
	boardsDirectoryRedirectRouteEntry,
	boardsDirectoryRouteEntry,
	filtersDirectoryRedirectRouteEntry,
	filtersDirectoryRouteEntry,
	secureFiltersDirectoryRedirectRouteEntry,
	personalSettingsRouteEntry,
	generalPersonalSettingsRouteEntry,
	notificationsPersonalSettingsRouteEntry,
	digestsNotificationsPersonalSettingsRouteEntry,
	opsNotificationsPersonalSettingsRouteEntry,
	customerServiceEscalationsRouteEntry,
	customerServiceProductsRouteEntry,
	archivedPlansRouteEntry,
	trashPlansRouteEntry,
	trashedPlanDirectLinkRouteEntry,
	arjSettingsFinancialYearPageRouteEntry,
	planIncrementRouteEntry,
	planIncrementsRouteEntry,
	globalSettingsCustomFieldsRouteEntry,
	globalSettingsIssuesExplicitFieldAssociationRouteEntry,
	settingsCmdbFieldConfigRouteEntry,
	settingsOauthCredentialsRouteEntry,
	projectsRedirectRouteEntry,
	browseProjectRedirectRouteEntry,
	productsJswConfigurationRouteEntry,
	productsJswStorageManagementRouteEntry,
	productsJsmOrganizationsRouteEntry,
	productsJsmOrganizationsDetailsRouteEntry,
	productsJsmConfigurationRouteEntry,
	productsJsmComplianceRouteEntry,
	productsJsmKbPermissionsRouteEntry,
	productsJsmEmailRequestsRouteEntry,
	productsJcsCustomerAccessRouteEntry,
	productsJcsFormFieldsRouteEntry,
	eocTeamCustomerLogsRouteEntry,
	eocTeamCustomerLogsDownloadRouteEntry,
	eocApiKeyManagementRouteEntry,
	globalMailSettingsRouteEntry,
	productsJsmIncidentManagementToolsRouteEntry,
	productsJsmFeatureUsageRouteEntry,
	knowledgeHubLandingRouteEntry,
	knowledgeHubBrowseAllArticlesRouteEntry,
	knowledgeHubDraftsRouteEntry,
	knowledgeHubBrowseAllCategoriesRouteEntry,
	knowledgeHubSuggestedArticlesRouteEntry,
	knowledgeHubBrowseViewArticleRouteEntry,
	knowledgeHubArticleEmbedEditorRouteEntry,
	knowledgeHubAllArticlesRouteEntry,
	knowledgeHubArticleInCategoryRouteEntry,
	knowledgeHubCategoryPageRouteEntry,
	knowledgeHubCategoryRouteEntry,
	knowledgeHubSearchRouteEntry,
	knowledgeHubSetupRouteEntry,
	servicedeskReports1RouteEntry,
	servicedeskAtlassianAnalyticsRouteEntry,
	servicedeskReportsWorkloadRouteEntry,
	servicedeskReportsSatisfactionIssueRouteEntry,
	servicedeskReportsSatisfactionTimeScaleRouteEntry,
	servicedeskReportsSatisfaction2RouteEntry,
	servicedeskKbRequestsReportsIssueRouteEntry,
	servicedeskKbRequestsReportsTimeScaleRouteEntry,
	servicedeskKbRequestsReports2RouteEntry,
	servicedeskReportsCustomEditRouteEntry,
	servicedeskReportsCustomCreateRouteEntry,
	servicedeskReportsCustomIssueRouteEntry,
	servicedeskReportsCustomTimeScaleRouteEntry,
	servicedeskReportsCustom4RouteEntry,
	servicedeskReportsTypeRouteEntry,
	servicedeskIssueRouteEntry,
	servicedeskQueuesLandingRouteEntry,
	servicedeskQueuesNewRouteEntry,
	servicedeskQueuesViewsConfigRouteEntry,
	servicedeskPracticeViewsConfigRouteEntry,
	servicedeskQueuesEditRouteEntry,
	customPracticeQueueCalendarRouteEntry,
	customPracticeQueueBoardRouteEntry,
	customQueueCalendarRouteEntry,
	customQueueBoardRouteEntry,
	customQueueRouteEntry,
	customIssueRouteEntry,
	servicedeskQueuesIssueRouteEntry,
	servicedeskQueuesManageViewRouteEntry,
	servicedeskQueuesAddonRouteEntry,
	servicedeskPracticeLandingRouteEntry,
	servicedeskPracticeNewRouteEntry,
	servicedeskPracticeManageViewRouteEntry,
	servicedeskPracticeEditRouteEntry,
	customPracticeQueueRouteEntry,
	servicedeskPracticeIssueRouteEntry,
	jsmBoardRouteEntry,
	jsmGSPPageRouteEntry,
	servicedeskServicesRouteEntry,
	servicedeskServiceDetailsRouteEntry,
	serviceDeskServicesWithoutProjectRouteEntry,
	serviceDeskServiceDetailsWithoutProjectRouteEntry,
	servicedeskServiceModalNewRouteEntry,
	servicedeskServiceModalEditRouteEntry,
	servicedeskCustomerDetailsRouteEntry,
	servicedeskCustomersV2RouteEntry,
	servicedeskOrganizationDetailsRouteEntry,
	servicedeskOrganizationsRouteEntry,
	servicedeskOrganizationV2RouteEntry,
	servicedeskOncallScheduleRouteEntry,
	servicedeskChangeCalendarRouteEntry,
	servicedeskCalendarRouteEntry,
	servicedeskProjectSummaryRouteEntry,
	// /jira/assets/* routes
	assetsRouteEntry,
	assetsReportsRouteEntry,
	assetsReportsTemplateRouteEntry,
	assetsReportsUnsubscribeRouteEntry,
	assetsSchemaRouteEntry,
	assetsObjectTypeConfigurationRouteEntry,
	assetsConfigurationRouteEntry,
	assetsSchemaConfigurationRouteEntry,
	assetsObjectRouteEntry,
	assetsSearchRouteEntry,
	// /jira/servicedesk/assets/* routes
	servicedeskCmdbAssetsSchemasRouteEntry,
	servicedeskCmdbAssetsReportsRouteEntry,
	servicedeskCmdbAssetsReportsTemplateRouteEntry,
	servicedeskCmdbAssetsReportsUnsubscribeRouteEntry,
	servicedeskCmdbAssetsObjectSchemaRouteEntry,
	servicedeskCmdbAssetsObjectTypeConfigurationRouteEntry,
	servicedeskCmdbAssetsGlobalConfigurationRouteEntry,
	servicedeskCmdbAssetsObjectSchemaConfigurationRouteEntry,
	servicedeskCmdbAssetsObjectRouteEntry,
	// /jira/servicedesk/insight/* routes
	servicedeskCmdbInsightRouteEntry,
	servicedeskCmdbInsightObjectSchemaRouteEntry,
	servicedeskCmdbInsightObjectTypeConfigurationRouteEntry,
	servicedeskCmdbInsightConfigureGlobalRouteEntry,
	servicedeskCmdbInsightObjectSchemaConfigurationRouteEntry,
	servicedeskCmdbInsightConfigureRouteEntry,
	servicedeskCmdbInsightObject4RouteEntry,
	notificationLogsRouteEntry,
	servicedeskConnectProjectRouteEntry,
	projectServiceDeskRouteEntry,
	servicedeskItsmSampleSpaceRouteEntry,
	servicedeskCreateProjectRouteEntry,
	archivedProjectDirectLinkRouteEntry,
	selectedDashboardRouteEntry,
	dashboardRouteEntry,
	systemDashboardEditRouteEntry,
	arjSettingsDependenciesRouteEntry,
	arjSettingsDependenciesLegacyRedirectRouteEntry,
	arjSettingsLoadingLimitsRouteEntry,
	arjSettingsLoadingLimitsLegacyRedirectRouteEntry,
	arjSettingsPermissionsRouteEntry,
	arjSettingsPermissionsRouteLegacyRedirectRouteEntry,
	arjCalendarEmbedRouteEntry,
	arjDependenciesEmbedRouteEntry,
	arjIncrementEmbedRouteEntry,
	arjSummaryEmbedRouteEntry,
	arjTimelineEmbedRouteEntry,
	softwarePlanDependenciesRouteEntry,
	softwarePlanSummaryRouteEntry,
	softwarePlanCalendarRouteEntry,
	softwarePlanReleasesRouteEntry,
	softwarePlanTimelineRouteEntry,
	softwarePlanSettingsPermissionsRouteEntry,
	softwarePlanSettingsSavedViewsRouteEntry,
	softwarePlanSettingsPlanInfoRouteEntry,
	softwarePlanSettingsFeaturesRouteEntry,
	softwarePlanSettingsProgramBoardsRouteEntry,
	softwarePlanSettingsSchedulingRouteEntry,
	softwarePlanSettingsScenariosRouteEntry,
	softwarePlanSettingsAutoSchedulerRouteEntry,
	softwarePlanSettingsCustomFieldsRouteEntry,
	softwarePlanSettingsExclusionRulesRouteEntry,
	softwarePlanSettingsIssueSourcesRouteEntry,
	softwarePlanSettingsFindYourIssueRouteEntry,
	softwarePlanSettingsRemovedIssuesRouteEntry,
	softwarePlanTeamsRouteEntry,
	planViewNotFoundRouteEntry,
	planUpsellRouteEntry,
	softwareAdvancedRoadmapsGettingStartedRedirectRouteEntry,
	planSetupPageRouteEntry,
	planExportPageRouteEntry,
	planPageRouteEntry,
	planPageWithoutScenarioIdRouteEntry,
	legacyPlanPageRedirectRouteEntry,
	planReportPageRouteEntry,
	legacyPlanReportPageRedirectRouteEntry,
	plansDirectoryRouteEntry,
	eocScheduleGlobalRouteEntry,
	eocGlobalAlertListRouteEntry,
	eocGlobalAlertsDetailRouteEntry,
	eocTeamAlertListRouteEntry,
	eocTeamAlertDetailRouteEntry,
	eocGlobalGroupDetailRouteEntry,
	eocTeamGroupDetailRouteEntry,
	roleBasedNotificationsRouteEntry,
	roleBasedNotificationDetailRouteEntry,
	globalIncomingCallRouteEntry,
	globalIncomingCallDetailRouteEntry,
	unlockOperationsRouteEntry,
	teamIntegrationsRouteEntry,
	teamIntegrationListRouteEntry,
	teamIntegrationCreateRouteEntry,
	teamIntegrationDetailRouteEntry,
	teamIntegrationLogRouteEntry,
	globalIntegrationsRouteEntry,
	globalIntegrationListRouteEntry,
	globalIntegrationCreateRouteEntry,
	globalIntegrationDetailRouteEntry,
	globalIntegrationLogRouteEntry,
	alertPoliciesRouteEntry,
	globalPoliciesRouteEntry,
	globalEscalationsRouteEntry,
	eocOverviewRouteEntry,
	eocOverviewAllTeamsRouteEntry,
	eocHeartbeatsRouteEntry,
	eocGlobalHeartbeatsRouteEntry,
	eocMaintenanceRouteEntry,
	eocGlobalMaintenanceRouteEntry,
	eocOnCallRedirectRouteEntry,
	eocOnCallRouteEntry,
	whoIsOnCallRouteEntry,
	eocPermissionsRouteEntry,
	eocAutomationsRouteEntry,
	eocTeamAlertIssueSyncingRouteEntry,
	eocTeamAlertIssueSyncingCreateRouteEntry,
	eocTeamAlertIssueSyncingDetailRouteEntry,
	eocAlertIssueSyncingRouteEntry,
	eocAlertIssueSyncingCreateRouteEntry,
	eocAlertIssueSyncingDetailRouteEntry,
	eocReportsTeamRedirectRouteEntry,
	eocReportsUnsubscribeRouteEntry,
	eocGlobalReportsHomePageNav4RouteEntry,
	eocGlobalReportsNav4RouteEntry,
	eocReportsTeamRouteEntry,
	eocCustomRolesRouteEntry,
	eocAssignCustomRolesRouteEntry,
	productsJsmGlobalOpsReportsRouteEntry,
	productsJsmGlobalOpsReportsRedirectRouteEntry,
	productsJsmChatopsAndVideosToolsRouteEntry,
	sandboxBundlerRouteEntry,
	sandboxBundlerBifrostRLLRouteEntry,
	sandboxBundlerBifrostEntrypointsRouteEntry,
	projectNotFoundRouteEntry,
	errorsNotFoundRouteEntry,
	loginEmbedRouteEntry,
	helpCenterSettingsAgentStudioActionsRouteEntry,
	helpCenterSettingsAgentStudioChatSimulatorRouteEntry,
	helpCenterSettingsAgentStudioConversationReviewRouteEntry,
	helpCenterSettingsAgentStudioKnowledgeRouteEntry,
	helpCenterSettingsAgentStudioRouteEntry,
	helpCenterSettingsArticlesRouteEntry,
	helpCenterSettingsArticlesViewArticleRouteEntry,
	helpCenterSettingsCreateArticleRouteEntry,
	helpCenterSettingsKbRouteEntry,
	helpCenterSettingsKbOldRouteEntry,
	helpCenterSettingsCustomerNotificationsRouteEntry,
	helpCenterSettingsCustomerNotificationsOldRouteEntry,
	helpCenterSettingsCustomerAccessRouteEntry,
	helpCenterSettingsCustomerAccessOldRouteEntry,
	helpCenterSettingsDetailsRouteEntry,
	helpCenterSettingsEmailRouteEntry,
	helpCenterSettingsEmailOldRouteEntry,
	helpCenterSettingsSupportWebsiteRouteEntry,
	helpCenterSettingsSupportWebsiteOverviewRouteEntry,
	helpCenterSettingsSupportWebsitePagesRouteEntry,
	helpCenterSettingsFormsRouteEntry,
	helpCenterSettingsFormEditRouteEntry,
	helpCenterSettingsAnalyticsRouteEntry,
	helpCenterSettingsServiceHubsRouteEntry,
	helpCenterSettingsNotFoundRouteEntry,
	userSegmentationRouteEntry,
	rovoSearchRouteEntry,
	applinksExtraAuthHealthcheckRouteEntry,
	// [WARNING]: This route should be the last route.
	// This route redirects to the monolith for all routes we cannot match.
	// It is purposefully missing a path so it always matches and therefore needs to be last.
	// [WARNING]: Please don't add a new route below this. If you want to add, please contact the package owner team.
	legacyRedirectRouteEntry,
];
