import React from 'react';
import type CycleTimeReportType from '@atlassian/jira-spa-apps-software-reports-cycle-time-report/src/ui/index-old.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyCycleTimeReport = lazyForPaint<typeof CycleTimeReportType>(
	() =>
		import(
			/* webpackChunkName: "async-cycle-time-report", jiraSpaEntry: "async-cycle-time-report" */ '@atlassian/jira-spa-apps-software-reports-cycle-time-report/src/ui/index-old.tsx'
		),
);

export const CycleTimeReportNextGen = () => (
	<LazyPage Page={LazyCycleTimeReport} pageId="cycleTimeReportNextGen" shouldShowSpinner />
);

export const CycleTimeReportClassic = () => (
	<LazyPage Page={LazyCycleTimeReport} pageId="cycleTimeReportClassic" shouldShowSpinner />
);
