import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { nextGenBoardEntryPoint } from '@atlassian/jira-spa-apps-next-gen-board/entrypoint.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';

export const softwareBoardPageEntrypoint = createPageEntryPoint({
	main: nextGenBoardEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});
