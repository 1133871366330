/**
 * @generated SignedSource<<5d71969f9f17f171bc1bb115c437b9df>>
 * @relayHash a9a20ac35215d7481c3a2ce02570e8ab
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8ab9d2595535bdb4474da7992bbcb0eda979882355177ecb5eb15f202bde4878

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

import withMergedWorkflows_provider from '@atlassian/jira-relay-provider/src/merge-board-workflows.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "8ab9d2595535bdb4474da7992bbcb0eda979882355177ecb5eb15f202bde4878",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcmergeboardworkflowsrelayprovider": withMergedWorkflows_provider
    }
  }
};

export default node;
