import boardView from './board-view.tsx';
import collections from './collections.tsx';
import * as copyProjectConfiguration from './copy-project-configuration.tsx';
import deliveryItems from './delivery-items.tsx';
import fieldsSidebar from './fields-sidebar.tsx';
import formulaField from './formula-field.tsx';
import globalFieldsAdministration from './global-fields-administration.tsx';
import headerView from './header-view.tsx';
import ideaViewBentoSidebar from './idea-view-bento-sidebar.tsx';
import ideaView from './idea-view.tsx';
import issues from './issues.tsx';
import listView from './list-view.tsx';
import matrixView from './matrix-view.tsx';
import navBar from './nav-bar.tsx';
import noViewsScreen from './no-views-screen.tsx';
import playSidepanel from './play-sidepanel.tsx';
import productAccess from './product-access.tsx';
import project from './project.tsx';
import sharing from './sharing.tsx';
import timelineView from './timeline-view.tsx';
import viewPermissions from './view-permissions.tsx';
import view from './view.tsx';

export const experience = {
	listView,
	formulaField,
	headerView,
	boardView,
	ideaView,
	ideaViewBentoSidebar,
	matrixView,
	navBar,
	project,
	timelineView,
	fieldsSidebar,
	issues,
	deliveryItems,
	sharing,
	productAccess,
	globalFieldsAdministration,
	playSidepanel,
	noViewsScreen,
	view,
	viewPermissions,
	collections,
	copyProjectConfiguration,
};
