import React from 'react';
import type DeploymentFrequencyReportSpa from '@atlassian/jira-spa-apps-software-reports-deployment-frequency-report/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

/** @deprecated remove when clean up fun-1598-migrate-reportsdeployment-entrypoint */
export const LazyDeploymentFrequencyReport = lazyForPaint<typeof DeploymentFrequencyReportSpa>(
	() =>
		import(
			/* webpackChunkName: "async-deployment-frequency-report", jiraSpaEntry: "async-deployment-frequency-report" */ '@atlassian/jira-spa-apps-software-reports-deployment-frequency-report'
		),
);

export const DeploymentFrequencyReportNextGen = () => (
	<LazyPage
		Page={LazyDeploymentFrequencyReport}
		pageId="deploymentFrequencyReportNextGen"
		shouldShowSpinner
	/>
);

export const DeploymentFrequencyReportClassic = () => (
	<LazyPage
		Page={LazyDeploymentFrequencyReport}
		pageId="deploymentFrequencyReportClassic"
		shouldShowSpinner
	/>
);
