/**
 * Product keys as defined in flight-deck.
 * https://bitbucket.org/atlassian/flight-deck/src/master/packages/integrations/twp-switch-to-products/contrib/
 */
export enum Products {
	// ATLAS_* - Need to be standalone apps to sit in this space
	ATLAS_HOME = 'ATLAS_HOME',
	ATLAS_GOALS = 'ATLAS_GOALS',
	ATLAS_TEAMS = 'TEAMS',
	ATLAS_HUB = 'ATLAS_HUB',
	ASSETS = 'ASSETS',
	BITBUCKET = 'BITBUCKET',
	COMPASS = 'COMPASS',
	FOCUS = 'mercury',
	STUDIO = 'STUDIO',
	JIRA_SERVICE_MANAGEMENT = 'JIRA_SERVICE_MANAGEMENT',
	JIRA_PRODUCT_DISCOVERY = 'JIRA_PRODUCT_DISCOVERY',
	JIRA_SOFTWARE = 'JIRA_SOFTWARE',
	CONFLUENCE = 'CONFLUENCE',
	LOOM = 'LOOM',
	PROJECTS = 'PROJECTS',
}

export const ANALYTICS_CHANNEL = 'navigation';
