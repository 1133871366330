import React, { type ReactNode, useCallback } from 'react';

import { useIntl } from 'react-intl-next';

import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import LinkExternalIcon from '@atlaskit/icon/utility/link-external';
import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';
import { MenuLinkItem as MenuLinkItemPrimitive } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { ANALYTICS_CHANNEL } from '../../constants';
import type { GlobalShortcutLinkItem } from '../../types';

import { messages } from './messages';

export type MenuLinkItemProps = GlobalShortcutLinkItem & {
	children?: ReactNode;
	url?: string;
	disabled?: boolean;
	onDisable?: (reason?: string) => void;
};

// renders null if url is undefined, renders disabled if url is null but disabled is true, renders link item if url has a value
export const MenuLinkItem = ({
	analyticsName,
	url,
	label,
	icon,
	disabled,
	onDisable,
}: MenuLinkItemProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		createAnalyticsEvent({
			eventType: UI_EVENT_TYPE,
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'globalNavigation',
			attributes: {
				name: analyticsName,
			},
		}).fire(ANALYTICS_CHANNEL);
	}, [createAnalyticsEvent, analyticsName]);

	// Don't render anything if url is undefined or empty, and disabled is not explicitly set to true
	if ((!url || url === '') && !disabled) {
		return null;
	}

	// Render disabled items if no url and/or disabled is true
	if (!url || disabled) {
		if (!url || url === '') {
			onDisable?.('Invalid URL');
		} else {
			onDisable?.();
		}

		return (
			<MenuButtonItem elemBefore={icon} elemAfter={<LinkExternalIcon label="" />} isDisabled>
				{label}
				<span aria-label={formatMessage(messages.disabledItemAriaLabel)} />
			</MenuButtonItem>
		);
	}

	// Only render link item if url is defined
	return (
		<MenuLinkItemPrimitive
			elemBefore={icon}
			elemAfter={<LinkExternalIcon label="open external link" />}
			href={url}
			target="_blank"
			onClick={onClick}
		>
			{label}
		</MenuLinkItemPrimitive>
	);
};
