import React, { createContext, useCallback } from 'react';

import { BFFConsumerEnum, RequestStatus } from '../constants';

export type ProviderValue = {
	cache: Record<string, any>;
	cacheKey: string;
	status: RequestStatus | null;
	consumer: BFFConsumerEnum | null;
};

export const defaultProviderValue: ProviderValue = {
	cache: {},
	cacheKey: '',
	status: null,
	consumer: null,
};

type BFFContext = {
	value: ProviderValue;
	currentLoadingRequests: string[];
	setValue: React.Dispatch<React.SetStateAction<ProviderValue>>;
	updateCurrentLoadingRequests: (name: string, status: RequestStatus) => void;
};

export const BFFContext = createContext<BFFContext>({
	value: defaultProviderValue,
	currentLoadingRequests: [],
	setValue: () => {},
	updateCurrentLoadingRequests: (name: string, status: RequestStatus) => {},
});

type BFFProviderProps = {
	consumer: BFFConsumerEnum;
	children: React.ReactNode;
};

export function BFFProvider({ consumer, children }: BFFProviderProps) {
	const [value, setValue] = React.useState<ProviderValue>({
		cache: {},
		cacheKey: consumer,
		consumer,
		status: RequestStatus.INIT,
	});
	const [currentLoadingRequests, setCurrentLoadingRequests] = React.useState<string[]>([]);

	const updateCurrentLoadingRequests = useCallback(
		(name: string, status: string) => {
			switch (status) {
				case RequestStatus.LOADING:
					setCurrentLoadingRequests((prev) => [...prev, name]);
					break;
				case RequestStatus.COMPLETE:
					setCurrentLoadingRequests((prev) => prev.filter((request) => request !== name));
					break;
				case RequestStatus.ERROR:
					setCurrentLoadingRequests([]);
					break;
			}
		},
		[setCurrentLoadingRequests],
	);

	return (
		<BFFContext.Provider
			value={{ value, setValue, currentLoadingRequests, updateCurrentLoadingRequests }}
		>
			{children}
		</BFFContext.Provider>
	);
}
