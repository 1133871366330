{
  "jira.jira-notify-reaction.message-template.text.single-reaction": [
    {
      "type": 0,
      "value": ":bell: <@"
    },
    {
      "type": 1,
      "value": "userId"
    },
    {
      "type": 0,
      "value": "> *reacted to your comment*"
    }
  ],
  "jira.jira-notify-reaction.message-template.text.single-reaction-with-name": [
    {
      "type": 0,
      "value": ":bell: *"
    },
    {
      "type": 1,
      "value": "userName"
    },
    {
      "type": 0,
      "value": "reacted to your comment*"
    }
  ],
  "jira.jira-notify-reaction.message-template.text.view-comment": [
    {
      "type": 0,
      "value": "View Comment"
    }
  ]
}