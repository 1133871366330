{
  "app-switcher-discovery-section.discover-more-products": [
    {
      "type": 0,
      "value": "Discover more apps"
    }
  ],
  "app-switcher-discovery-section.more-atlassian-products": [
    {
      "type": 0,
      "value": "More Atlassian apps"
    }
  ],
  "app-switcher-discovery-section.recommended-for-your-team": [
    {
      "type": 0,
      "value": "Recommended for your team"
    }
  ],
  "first-party-recommendation.reason-1": [
    {
      "type": 0,
      "value": "the size of your team"
    }
  ],
  "first-party-recommendation.reason-2": [
    {
      "type": 0,
      "value": "your existing Atlassian products"
    }
  ],
  "growth.admin-hub-recommendation.actions-heading": [
    {
      "type": 0,
      "value": "Actions"
    }
  ],
  "growth.admin-hub-recommendation.free-plan": [
    {
      "type": 0,
      "value": "Free, cancel anytime"
    }
  ],
  "growth.admin-hub-recommendation.heading": [
    {
      "type": 0,
      "value": "Recommended based on your existing products"
    }
  ],
  "growth.admin-hub-recommendation.plan-heading": [
    {
      "type": 0,
      "value": "Plan"
    }
  ],
  "growth.admin-hub-recommendation.try-it-now": [
    {
      "type": 0,
      "value": "Try it now"
    }
  ],
  "growth.app-switcher-discovery-section.flag.description": [
    {
      "type": 0,
      "value": "You won’t see this here as often."
    }
  ],
  "growth.app-switcher-discovery-section.flag.title": [
    {
      "type": 0,
      "value": "Got it, thanks for letting us know"
    }
  ],
  "growth.app-switcher-discovery-section.wac-toast.close.aria-label": [
    {
      "type": 0,
      "value": "Dismiss toast"
    }
  ],
  "growth.recommendation-atlas.value-proposition": [
    {
      "type": 0,
      "value": "Your company's teamwork directory"
    }
  ],
  "growth.recommendation-confluence.cta.label": [
    {
      "type": 0,
      "value": "Try Confluence"
    }
  ],
  "growth.recommendation-confluence.jira-your-work.description": [
    {
      "type": 0,
      "value": "Create a centralized space to organize requirements, release notes, goals, and more."
    }
  ],
  "growth.recommendation-confluence.jira-your-work.header": [
    {
      "type": 0,
      "value": "Get everyone on the same page"
    }
  ],
  "growth.recommendation-confluence.jira-your-work.header-image-alt-text": [
    {
      "type": 0,
      "value": "Multiple people collaborating and commenting on the same page at the same time"
    }
  ],
  "growth.recommendation-confluence.jira-your-work.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "confluence"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-confluence.product-alt-text": [
    {
      "type": 0,
      "value": "A document titled quarterly planning. The document has details like mission, scope and a monthly plan."
    }
  ],
  "growth.recommendation-confluence.product-heading": [
    {
      "type": 0,
      "value": "Create one source of truth, for all"
    }
  ],
  "growth.recommendation-confluence.product-name.header": [
    {
      "type": 0,
      "value": "Collaborate on pages in real-time"
    }
  ],
  "growth.recommendation-confluence.product-subheading": [
    {
      "type": 0,
      "value": "Create and link documents like release notes, product requirements, and roadmaps from a single place."
    }
  ],
  "growth.recommendation-confluence.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "confluence"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-confluence.value-proposition": [
    {
      "type": 0,
      "value": "Document collaboration"
    }
  ],
  "growth.recommendation-jira.product-alt-text": [
    {
      "type": 0,
      "value": "A board view to move tasks from to do, to in progress to done"
    }
  ],
  "growth.recommendation-jira.product-heading": [
    {
      "type": 0,
      "value": "Plan and track work across teams"
    }
  ],
  "growth.recommendation-jira.product-subheading": [
    {
      "type": 0,
      "value": "Break big ideas down into achievable steps. Organize work, create milestones, map dependencies, and get visibility across all teams."
    }
  ],
  "growth.recommendation-jpd-product-roadmap.value-proposition": [
    {
      "type": 0,
      "value": "Align everyone with custom roadmaps"
    }
  ],
  "growth.recommendation-jpd.admin-hub-recommendation-value-proposition": [
    {
      "type": 0,
      "value": "Plan and deliver all on the same platform"
    }
  ],
  "growth.recommendation-jpd.cta.label": [
    {
      "type": 0,
      "value": "Try Jira Product Discovery"
    }
  ],
  "growth.recommendation-jpd.jira-your-work.description": [
    {
      "type": 0,
      "value": "Prioritize your ideas then easily move them into delivery, without losing any details on the way."
    }
  ],
  "growth.recommendation-jpd.jira-your-work.header": [
    {
      "type": 0,
      "value": "Build right features, the right way"
    }
  ],
  "growth.recommendation-jpd.jira-your-work.header-image-alt-text": [
    {
      "type": 0,
      "value": "An idea in Jira Product Discovery automatically turning into an Epic in Jira"
    }
  ],
  "growth.recommendation-jpd.jira-your-work.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "jpd"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-jpd.product-alt-text": [
    {
      "type": 0,
      "value": "Five ideas are all organized into now, next and later groups, with a goal, score and effort ranking connected to each idea."
    }
  ],
  "growth.recommendation-jpd.product-heading": [
    {
      "type": 0,
      "value": "Build the right features, the right way"
    }
  ],
  "growth.recommendation-jpd.product-name.header": [
    {
      "type": 0,
      "value": "Transform insights into action plans"
    }
  ],
  "growth.recommendation-jpd.product-subheading": [
    {
      "type": 0,
      "value": "Capture and prioritize your ideas, then easily move them into delivery, without losing any details on the way."
    }
  ],
  "growth.recommendation-jpd.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "jpd"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-jpd.value-proposition": [
    {
      "type": 0,
      "value": "Prioritize, collaborate, and deliver new ideas"
    }
  ],
  "growth.recommendation-jsm-work-request.value-proposition": [
    {
      "type": 0,
      "value": "Create one place to manage requests"
    }
  ],
  "growth.recommendation-jsm.admin-hub-recommendation-value-prop": [
    {
      "type": 0,
      "value": "Bring your developers, IT, and operations onto the same platform."
    }
  ],
  "growth.recommendation-jsm.cta.label": [
    {
      "type": 0,
      "value": "Try Jira Service Management"
    }
  ],
  "growth.recommendation-jsm.jira-your-work.description": [
    {
      "type": 0,
      "value": "Connect support teams with development teams to get the full picture, from request to resolution."
    }
  ],
  "growth.recommendation-jsm.jira-your-work.header": [
    {
      "type": 0,
      "value": "Link your teams, make work visible"
    }
  ],
  "growth.recommendation-jsm.jira-your-work.header-image-alt-text": [
    {
      "type": 0,
      "value": "Requests from Slack, Teams and email turning into a Jira Service Management request automatically"
    }
  ],
  "growth.recommendation-jsm.jira-your-work.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "jsm"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-jsm.product-alt-text": [
    {
      "type": 0,
      "value": "Seven requests are all organized in one place, with a status and project connected to each request."
    }
  ],
  "growth.recommendation-jsm.product-heading": [
    {
      "type": 0,
      "value": "Make your work more visible"
    }
  ],
  "growth.recommendation-jsm.product-name.header": [
    {
      "type": 0,
      "value": "Automate workflows for faster resolutions"
    }
  ],
  "growth.recommendation-jsm.product-subheading": [
    {
      "type": 0,
      "value": "Connect Dev and Ops teams on a single platform to collaborate and connect the whole picture, from request to resolution."
    }
  ],
  "growth.recommendation-jsm.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "jsm"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-jsm.value-proposition": [
    {
      "type": 0,
      "value": "Collaborative IT service management"
    }
  ],
  "growth.recommendation-jsw-dynamic-description.value-proposition": [
    {
      "type": 0,
      "value": "Plan, track, and organize any project"
    }
  ],
  "growth.recommendation-jsw-visualize-work.cta.label": [
    {
      "type": 0,
      "value": "Try Jira"
    }
  ],
  "growth.recommendation-jsw-visualize-work.product-name.header": [
    {
      "type": 0,
      "value": "Visualize your projects in lists, boards, and more"
    }
  ],
  "growth.recommendation-jsw-visualize-work.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "product"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-jsw.recommendations.dropdown-menu-trigger.aria-label": [
    {
      "type": 1,
      "value": "productName"
    },
    {
      "type": 0,
      "value": " options"
    }
  ],
  "growth.recommendation-jsw.value-proposition": [
    {
      "type": 0,
      "value": "Project and issue tracking"
    }
  ],
  "growth.recommendation-jwm.value-proposition": [
    {
      "type": 0,
      "value": "ITSM that unlocks high-velocity teams"
    }
  ],
  "growth.recommendation-loom.cta.label": [
    {
      "type": 0,
      "value": "Try Loom"
    }
  ],
  "growth.recommendation-loom.product-name.header": [
    {
      "type": 0,
      "value": "Record and share videos easily"
    }
  ],
  "growth.recommendation-loom.reasons-modal.message": [
    {
      "type": 0,
      "value": "We think "
    },
    {
      "type": 1,
      "value": "product"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "growth.recommendation-loom.value-proposition": [
    {
      "type": 0,
      "value": "Record and share videos easily"
    }
  ],
  "growth.recommendation-opsgenie.value-proposition": [
    {
      "type": 0,
      "value": "Modern incident management"
    }
  ],
  "growth.recommendation-product-store.learn-cta-button-text": [
    {
      "type": 0,
      "value": "Learn more"
    }
  ],
  "growth.recommendation-product-store.try-cta-button-text": [
    {
      "type": 0,
      "value": "Try it now"
    }
  ],
  "post-office.ad-controls.default-reason.existing-products": [
    {
      "type": 0,
      "value": "your existing Atlassian products"
    }
  ],
  "post-office.ad-controls.default-reason.team-size": [
    {
      "type": 0,
      "value": "the size of your team"
    }
  ],
  "post-office.ad-controls.dismiss-flag.not-interested.subtitle": [
    {
      "type": 0,
      "value": "You won’t see this here as often."
    }
  ],
  "post-office.ad-controls.dismiss-flag.not-interested.title": [
    {
      "type": 0,
      "value": "Got it, thanks for letting us know"
    }
  ],
  "post-office.ad-controls.dropdown-item.not-interested.label": [
    {
      "type": 0,
      "value": "Not interested"
    }
  ],
  "post-office.ad-controls.dropdown-item.why-am-i-seeing-this.label": [
    {
      "type": 0,
      "value": "Why am I seeing this?"
    }
  ],
  "post-office.ad-controls.dropdown-menu-trigger.aria-label": [
    {
      "type": 1,
      "value": "messageInstanceId"
    },
    {
      "type": 0,
      "value": " options menu"
    }
  ],
  "post-office.ad-controls.dropdown-menu-trigger.tooltip": [
    {
      "type": 0,
      "value": "More actions"
    }
  ],
  "post-office.ad-controls.next-best-action-message.recommendation-basis": [
    {
      "type": 0,
      "value": "This recommendation is based on:"
    }
  ],
  "post-office.ad-controls.next-best-action-reason.actions-done-so-far": [
    {
      "type": 0,
      "value": "What you’ve done so far in the product"
    }
  ],
  "post-office.ad-controls.next-best-action-reason.suggestions": [
    {
      "type": 0,
      "value": "What we suggest to help you get the most value from Atlassian"
    }
  ],
  "post-office.ad-controls.next-best-action-title.why-am-i-seeing-this": [
    {
      "type": 0,
      "value": "Why am I seeing this?"
    }
  ],
  "post-office.ad-controls.reasons-modal.accept-button.label-ok": [
    {
      "type": 0,
      "value": "OK"
    }
  ],
  "post-office.ad-controls.reasons-modal.body": [
    {
      "type": 0,
      "value": "We think you'd find this relevant. This is based on:"
    }
  ],
  "post-office.ad-controls.reasons-modal.privacy-policy-link.text": [
    {
      "type": 0,
      "value": "Learn more about your data privacy with Atlassian"
    }
  ],
  "post-office.ad-controls.reasons-modal.title": [
    {
      "type": 0,
      "value": "Why am I seeing this?"
    }
  ],
  "post-office.close-button.tooltip": [
    {
      "type": 0,
      "value": "Close"
    }
  ],
  "post-office.confluence-banner-first-party-recommendation.try-it-now": [
    {
      "type": 0,
      "value": "Try it now"
    }
  ],
  "post-office.confluence-side-nav.next-best-action.craete-space-header": [
    {
      "type": 0,
      "value": "Centralize your team's projects, and docs."
    }
  ],
  "post-office.confluence-side-nav.next-best-action.create-page-cta-message": [
    {
      "type": 0,
      "value": "Create page"
    }
  ],
  "post-office.confluence-side-nav.next-best-action.create-page-header": [
    {
      "type": 0,
      "value": "Plan projects, take notes, and track tasks."
    }
  ],
  "post-office.confluence-side-nav.next-best-action.create-space-cta-message": [
    {
      "type": 0,
      "value": "Create space"
    }
  ],
  "post-office.confluence-side-nav.next-best-action.create-whiteboard-cta-message": [
    {
      "type": 0,
      "value": "Create whiteboard"
    }
  ],
  "post-office.confluence-side-nav.next-best-action.create-whiteboard-header": [
    {
      "type": 0,
      "value": "Dream up new ideas, and visualize your work."
    }
  ],
  "post-office.growth.recommendation-jpd.confluence-home-banner.description": [
    {
      "type": 0,
      "value": "Make sure your team is building the right things with Jira Product Discovery."
    }
  ],
  "post-office.growth.recommendation-jpd.confluence-home-banner.header": [
    {
      "type": 0,
      "value": "Capture your ideas and customer feedback all in one place"
    }
  ],
  "post-office.growth.recommendation-jpd.confluence-home-banner.logo-alt-text": [
    {
      "type": 0,
      "value": "Jira Product Discovery Product Logo"
    }
  ],
  "post-office.growth.recommendation-jsm.confluence-home-banner.description": [
    {
      "type": 0,
      "value": "Automatically turn Slack, Teams, and email requests into tickets."
    }
  ],
  "post-office.growth.recommendation-jsm.confluence-home-banner.header": [
    {
      "type": 0,
      "value": "See the whole picture, from request to resolution"
    }
  ],
  "post-office.growth.recommendation-jsm.confluence-home-banner.logo-alt-text": [
    {
      "type": 0,
      "value": "Jira Service Management Product Logo"
    }
  ],
  "post-office.growth.recommendation-jsw-real-time-updates.confluence-home-banner.description": [
    {
      "type": 0,
      "value": "See analytics and insights about what your team is working on at any moment"
    }
  ],
  "post-office.growth.recommendation-jsw-real-time-updates.confluence-home-banner.header": [
    {
      "type": 0,
      "value": "Sync real-time data from Jira to any document in Confluence"
    }
  ],
  "post-office.growth.recommendation-jsw-real-time-updates.confluence-home-banner.logo-alt-text": [
    {
      "type": 0,
      "value": "Jira Product Logo"
    }
  ],
  "post-office.growth.recommendation-jsw-tech-teams.confluence-home-banner.description": [
    {
      "type": 0,
      "value": "76% customers shipped projects faster after adding Confluence and Jira."
    }
  ],
  "post-office.growth.recommendation-jsw-tech-teams.confluence-home-banner.header": [
    {
      "type": 0,
      "value": "Build world-class products, fast"
    }
  ],
  "post-office.growth.recommendation-jsw-tech-teams.confluence-home-banner.logo-alt-text": [
    {
      "type": 0,
      "value": "Jira Product Logo"
    }
  ],
  "post-office.growth.recommendation-jsw-visualize-work.confluence-home-banner.description": [
    {
      "type": 0,
      "value": "Jira lets you view your project and tasks in a way that works for you and your team."
    }
  ],
  "post-office.growth.recommendation-jsw-visualize-work.confluence-home-banner.header": [
    {
      "type": 0,
      "value": "Visualize any project in a list, board, backlog, and more"
    }
  ],
  "post-office.growth.recommendation-jsw-visualize-work.confluence-home-banner.logo-alt-text": [
    {
      "type": 0,
      "value": "Jira Product Logo"
    }
  ],
  "post-office.jira-side-nav-first-party-recommendation.try-product": [
    {
      "type": 0,
      "value": "Try Product"
    }
  ],
  "post-office.jira-your-work-first-party-recommendation.learn-more": [
    {
      "type": 0,
      "value": "Learn more"
    }
  ],
  "post-office.jira-your-work-first-party-recommendation.learn-more.alt-text": [
    {
      "type": 0,
      "value": "Discover more about "
    },
    {
      "type": 1,
      "value": "product"
    }
  ],
  "post-office.jira-your-work-first-party-recommendation.try-it-now": [
    {
      "type": 0,
      "value": "Try it now"
    }
  ],
  "post-office.jira-your-work-first-party-recommendation.try-product": [
    {
      "type": 0,
      "value": "Try Product"
    }
  ],
  "recommendation-controls.existing-products": [
    {
      "type": 0,
      "value": "your existing Atlassian products"
    }
  ],
  "recommendation-controls.team-size": [
    {
      "type": 0,
      "value": "the size of your team"
    }
  ],
  "recommendations.ad-controls.dismiss-flag.not-interested.subtitle": [
    {
      "type": 0,
      "value": "You won’t see this here as often."
    }
  ],
  "recommendations.ad-controls.dismiss-flag.not-interested.title": [
    {
      "type": 0,
      "value": "Got it, thanks for letting us know"
    }
  ],
  "recommendations.ad-controls.reasons-modal.accept-button.label": [
    {
      "type": 0,
      "value": "Ok"
    }
  ],
  "recommendations.ad-controls.reasons-modal.accept-button.label-ok": [
    {
      "type": 0,
      "value": "OK"
    }
  ],
  "recommendations.ad-controls.reasons-modal.body": [
    {
      "type": 0,
      "value": "We think that "
    },
    {
      "type": 1,
      "value": "productName"
    },
    {
      "type": 0,
      "value": " will improve your team's productivity. The recommendation is based on:"
    }
  ],
  "recommendations.ad-controls.reasons-modal.privacy-policy-link.text": [
    {
      "type": 0,
      "value": "Learn more about your data privacy with Atlassian"
    }
  ],
  "recommendations.ad-controls.reasons-modal.title": [
    {
      "type": 0,
      "value": "Why am I seeing this?"
    }
  ],
  "switcher.ad-controls.dropdown-item.not-interested.label": [
    {
      "type": 0,
      "value": "Not interested"
    }
  ],
  "switcher.ad-controls.dropdown-item.why-am-i-seeing-this.label": [
    {
      "type": 0,
      "value": "Why am I seeing this?"
    }
  ],
  "test": [
    {
      "type": 0,
      "value": "default message"
    }
  ]
}