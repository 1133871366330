import React, { type ReactNode, useCallback } from 'react';

import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import {
	FlyoutMenuItem,
	FlyoutMenuItemContent,
	FlyoutMenuItemTrigger,
} from '@atlassian/navigation-system/side-nav/flyout-menu-item';
import { MenuButtonItem as MenuButtonItemPrimitive } from '@atlassian/navigation-system/side-nav/menu-button-item';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';

import { ANALYTICS_CHANNEL } from '../../constants';
import type { GlobalShortcutExpandItem, GlobalShortcutsItem } from '../../types';
import { MenuContent } from '../menu-content';
import { MenuLinkItem } from '../menu-link-item';
import { MenuSection } from '../menu-section';

export type MenuExpandItemProps = GlobalShortcutExpandItem & {
	name?: string;
	children?: ReactNode;
	disabled?: boolean;
	onDisable?: () => void;
	promotedItem?: GlobalShortcutExpandItem;
};

export const MenuExpandItem = ({
	icon,
	label,
	analyticsName,
	items,
	disabled,
	onDisable,
	promotedItem,
}: MenuExpandItemProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const createLinkItem = useCallback(
		(shortcut: GlobalShortcutsItem, index: number) => {
			return shortcut.items ? (
				<MenuExpandItem
					key={shortcut?.apsKey ?? `shortcut-${index}`}
					icon={shortcut.icon}
					label={shortcut.label}
					items={shortcut.items}
					disabled={disabled}
				/>
			) : (
				<MenuLinkItem
					key={shortcut?.apsKey ?? `shortcut-${index}`}
					apsKey={shortcut.apsKey}
					icon={shortcut.icon}
					url={shortcut.url || shortcut?.fallbackUrl?.()}
					label={shortcut.label}
					disabled={disabled}
				/>
			);
		},
		[disabled],
	);

	const onTriggerClick = useCallback(
		(isOpen: boolean) => {
			createAnalyticsEvent({
				eventType: UI_EVENT_TYPE,
				action: 'clicked',
				actionSubject: 'globalNavigation',
				actionSubjectId: 'navigationItem',
				attributes: {
					name: analyticsName,
					isExpanding: isOpen,
				},
			}).fire(ANALYTICS_CHANNEL);
		},
		[createAnalyticsEvent, analyticsName],
	);

	if (disabled) {
		onDisable?.();

		return (
			<MenuButtonItemPrimitive
				elemBefore={icon}
				elemAfter={<ChevronRightIcon label="open menu" />}
				isDisabled={disabled}
			>
				{label}
			</MenuButtonItemPrimitive>
		);
	}

	return (
		<FlyoutMenuItem onOpenChange={onTriggerClick}>
			<FlyoutMenuItemTrigger elemBefore={icon}>{label}</FlyoutMenuItemTrigger>
			<FlyoutMenuItemContent>
				<MenuContent heading={label}>
					{promotedItem && (
						<MenuSection>
							<MenuList key="gas-promoted-sites">{createLinkItem(promotedItem, 1)}</MenuList>
						</MenuSection>
					)}
					{/* TODO: NAVX-373 update string to localisation object */}
					<MenuSection label="All sites">
						<MenuList key="gas-all-sites">{items?.map(createLinkItem)}</MenuList>
					</MenuSection>
				</MenuContent>
			</FlyoutMenuItemContent>
		</FlyoutMenuItem>
	);
};
