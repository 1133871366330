import React, { useCallback, useMemo } from 'react';

import { UI_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { RequestStatus } from '@atlassian/navigation-bff-data-provider';

import { ANALYTICS_CHANNEL } from '../../constants';
import type { GlobalShortcutsItem, MappedGlobalShortcutsItem } from '../../types';
import { extractNameFromLabel } from '../../utils';
import { MenuExpandItem } from '../menu-expand-item';
import { MenuLinkItem } from '../menu-link-item';

type ShortcutMenuItemProps = {
	shortcut: MappedGlobalShortcutsItem;
	status: RequestStatus;
	expandItems?: GlobalShortcutsItem[];
	forceStatic?: boolean;
	promotedItem?: GlobalShortcutsItem;
};

export function ShortcutMenuItem({
	shortcut,
	status,
	expandItems,
	forceStatic,
	promotedItem,
}: ShortcutMenuItemProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const analyticsName = useMemo(() => {
		if (typeof shortcut.label === 'string') {
			return shortcut.label;
		}
		if (React.isValidElement(shortcut.label)) {
			return extractNameFromLabel(shortcut.label);
		}
	}, [shortcut.label]);

	/**
	 * Set disabled state based on what we know at this point
	 * from whether we are forcing static rendering and the status of the BFF request.
	 * If we have more conditions received from the BFF in the future (such as permissions), we can account for those here too.
	 */
	const disabled = useMemo(() => {
		// If forceStatic is explicitly set to true, do not disable the item
		if (forceStatic) {
			return false;
		}
		// Do not explicitly disable links that have empty URLs, because we don't want to display them at all
		if (shortcut?.fallbackUrl && shortcut?.fallbackUrl() === '') {
			return false;
		}
		// For anything else, show disabled state while initializing, loading, or there is a request error
		if (status !== RequestStatus.COMPLETE) {
			return true;
		}
		if (forceStatic === undefined) {
			return false;
		}

		return !forceStatic;
	}, [forceStatic, shortcut, status]);

	/**
	 * An event to pass down to the inner menu item component (MenuLinkItem or MenuExpandItem) to be called when the item is disabled.
	 * This allows the inner components to handle further disabling conditions within themselves if applicable,
	 * and optionally pass up a reason for the disabled state determined within itself.
	 * If no reason is passed, we check against what we know here from the data received from the BFF.
	 */
	const onDisable = useCallback(
		(reason?: string) => {
			// Do not fire analytics event until loading is complete
			if (status === RequestStatus.INIT || status === RequestStatus.LOADING) {
				return;
			}

			// This currently covers reasons like request errors.
			// If the BFF gives us more information in the future (such as permissions), that should be accounted for here too.
			const bffReason = status !== RequestStatus.COMPLETE ? status : undefined;

			createAnalyticsEvent({
				eventType: UI_EVENT_TYPE,
				action: 'disabled',
				actionSubject: 'button',
				actionSubjectId: 'globalNavigation',
				attributes: {
					name: analyticsName,
					reason: reason ?? bffReason,
				},
			}).fire(ANALYTICS_CHANNEL);
		},
		[createAnalyticsEvent, analyticsName, status],
	);

	if (expandItems && expandItems?.length > 0) {
		return (
			<MenuExpandItem
				key={shortcut.key}
				analyticsName={analyticsName}
				icon={shortcut.icon}
				label={shortcut.label}
				items={expandItems}
				disabled={disabled}
				onDisable={onDisable}
				promotedItem={promotedItem}
			/>
		);
	}

	return (
		<MenuLinkItem
			analyticsName={analyticsName}
			key={shortcut.key}
			disabled={disabled}
			apsKey={shortcut.apsKey}
			icon={shortcut.icon}
			url={shortcut?.fallbackUrl?.()}
			label={shortcut.label}
			onDisable={onDisable}
		/>
	);
}
