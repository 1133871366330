import React, { useEffect, type PropsWithChildren } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { CAPTURE_INTEGRATIONS_TASK } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import {
	useIsDismissed,
	useOpenTask,
	useQuickstartActions,
} from '@atlassian/jira-onboarding-quickstart-core/src/services/index.tsx';
import { NavigationAppsNavItemWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/navigation-apps/async.tsx';
import { useHasOpened } from '@atlassian/jira-software-onboarding-quickstart-has-opened/src/services/has-opened/index.tsx';
import { useExpandSideNav } from '@atlassian/navigation-system/layout/side-nav';
import { fg } from '@atlassian/jira-feature-gating';

// When the Nav4Apps menuitem is rendered (which occurs both when the sidebar is visible AND hidden),
// AND when the quickstart tour is open
// AND when the current tour-task is CAPTURE_INTEGRATIONS_TASK
// Then show a highlight around the apps menu
export function Nav4AppsSpotlightWrapper({ children }: PropsWithChildren) {
	const [{ hasOpened: shouldShowNudge }] = useHasOpened();
	const [isUnloaded] = useIsDismissed();
	const expandSideNav = useExpandSideNav();

	const [openTask] = useOpenTask();
	const [, { setHasNudgeActive }] = useQuickstartActions();

	useEffect(() => {
		if (!isUnloaded && openTask === CAPTURE_INTEGRATIONS_TASK && shouldShowNudge) {
			expandSideNav();
			setHasNudgeActive(true); // This re-shows the button-highlight, which can be hidden if the user tries to hide the sidebar
		}
	}, [isUnloaded, openTask, shouldShowNudge, setHasNudgeActive, expandSideNav]);

	if (fg('jira_onboarding_experiences_a11y_fixes')) {
		return !__SERVER__ && shouldShowNudge ? (
			<Box xcss={fullWidthStyles}>
				<NavigationAppsNavItemWrapperNudgeAsync>{children}</NavigationAppsNavItemWrapperNudgeAsync>
			</Box>
		) : (
			children
		);
	}

	return !__SERVER__ && shouldShowNudge ? (
		<NavigationAppsNavItemWrapperNudgeAsync>{children}</NavigationAppsNavItemWrapperNudgeAsync>
	) : (
		children
	);
}

const fullWidthStyles = xcss({
	width: '100%',
});
