import { fg } from '@atlassian/jira-feature-gating';
import { getIsAdmin } from '@atlassian/jira-navigation-apps-sidebar-nav4-get-user-meta/src/index.tsx';
import { getIsInPreGaStage } from '../get-is-in-pre-ga-stage.tsx';

// We don't inline feature gates as our logic to show nav4 is not trivial and should not be duplicated.
// See https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design#Decision-points
// All changes to this function should be replicated in the backend (see readme.md).
// eslint-disable-next-line jira/ff/inline-usage
export const getWillShowNav4UserOptIn = () => {
	if (!fg('jira_nav4')) {
		return false;
	}

	// Previous stages were mutually exclusive. GA is not.
	// Therefore, we need to make sure the customer is not in another stage before showing user opt in.
	if (getIsInPreGaStage()) {
		return false;
	}

	// Later GA phases eclipse earlier phases.
	if (fg('jira_nav4_ga_phase-3') || fg('jira_nav4_ga_phase-2') || fg('jira_nav4_ga_phase-1-5')) {
		return false;
	}

	if (fg('jira_nav4_ga_phase-1')) {
		return getIsAdmin();
	}

	return fg('jira_nav4_ga_sandboxes');
};
