/**
 * @generated SignedSource<<18a79c7a5821ef1c34c76a73e684ad75>>
 * @relayHash 97e95f9d23b870cfc7e741d0704ef3e5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 75f20ff7f706d5230200d7fb82e36930aef0f3f78da44adc9965aac2412ba293

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { mainIssueAggQuery } from './mainIssueAggQuery.graphql';

import issueViewRelayConcealItemsIntoMeatballMenuExperimentFlag_provider from '@atlassian/jira-relay-provider/src/conceal-items-into-meatball-menu-experiment.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import issueViewRedactionExperience_provider from '@atlassian/jira-relay-provider/src/jira-issue-view-redaction-experience.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isOneEventImprovementsEnabled_provider from '@atlassian/jira-relay-provider/src/one-event-improvements-enabled.relayprovider';
import issueViewRelayConfigurationUrlFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-configuration-url.relayprovider';
import issueViewRelayApproversFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-approvers.relayprovider';
import issueViewRelayAssigneeFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-assignee.relayprovider';
import issueViewRelayCheckboxSelectFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-checkbox-select.relayprovider';
import issueViewRelayComponentsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-components.relayprovider';
import isIssueViewFieldConfigEditEnabled_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-custom-field-config.relayprovider';
import issueViewRelayGroupPickerFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-group-picker.relayprovider';
import issueViewRelayHeaderActionsFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-header-actions.relayprovider';
import issueViewRelayIssueRestrictionsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-restrictions.relayprovider';
import issueViewRelayIssueTypeSwitcherFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-issue-type-switcher.relayprovider';
import issueViewRelayMultiGroupPickerFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-group-picker.relayprovider';
import issueViewRelayMultiUserAndParticipantsAndRequestParticipantsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user-participants-request-participants.relayprovider';
import issueViewRelayMultiUserParticipantsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-user-participants.relayprovider';
import issueViewRelayOriginalEstimateFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-original-estimate.relayprovider';
import issueViewRelayParentFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent';
import issueViewRelayPeopleFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-people.relayprovider';
import issueViewRelayRadioSelectFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-radio-select.relayprovider';
import issueViewRelayReporterFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-reporter.relayprovider';
import issueViewRelayRequestParticipantsFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-request-participants.relayprovider';
import issueViewRelayResolutionFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-resolution.relayprovider';
import issueViewRelayRichTextFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-rich-text.relayprovider';
import issueViewRelaySecurityLevelFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-security-level.relayprovider';
import issueViewRelaySingleLineTextMessageFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-single-line-text-message.relayprovider';
import issueViewRelayStatusPanelFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status-panel.relayprovider';
import issueViewRelayStatusFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-status.relayprovider';
import issueViewRelayTeamFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-team.relayprovider';
import issueViewRelayTimeTrackingFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-time-tracking.relayprovider';
import issueViewRelayUserFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-user.relayprovider';
import issueViewRelayVotesFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-votes.relayprovider';
import issueViewRelayWatchersFieldFlag_provider from '@atlassian/jira-relay-provider/src/relay-migration-issue-fields-watchers.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: PreloadableConcreteRequest<mainIssueAggQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "75f20ff7f706d5230200d7fb82e36930aef0f3f78da44adc9965aac2412ba293",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "jira",
            "issueByKey",
            "attachments"
          ]
        }
      ]
    },
    "name": "mainIssueAggQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationconfigurationurlrelayprovider": issueViewRelayConfigurationUrlFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiraissueviewredactionexperiencerelayprovider": issueViewRedactionExperience_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider": isIssueViewFieldConfigEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent": issueViewRelayParentFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldstimetrackingrelayprovider": issueViewRelayTimeTrackingFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscheckboxselectrelayprovider": issueViewRelayCheckboxSelectFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscomponentsrelayprovider": issueViewRelayComponentsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsoriginalestimaterelayprovider": issueViewRelayOriginalEstimateFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrequestparticipantsrelayprovider": issueViewRelayMultiUserAndParticipantsAndRequestParticipantsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsgrouppickerrelayprovider": issueViewRelayGroupPickerFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider": issueViewRelayMultiGroupPickerFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsteamrelayprovider": issueViewRelayTeamFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsradioselectrelayprovider": issueViewRelayRadioSelectFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrichtextrelayprovider": issueViewRelayRichTextFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultiuserparticipantsrelayprovider": issueViewRelayMultiUserParticipantsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsrequestparticipantsrelayprovider": issueViewRelayRequestParticipantsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider": issueViewRelayPeopleFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider": issueViewRelayApproversFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider": issueViewRelaySingleLineTextMessageFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider": issueViewRelayAssigneeFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider": issueViewRelayReporterFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider": issueViewRelayUserFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider": issueViewRelayHeaderActionsFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldswatchersrelayprovider": issueViewRelayWatchersFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssecuritylevelrelayprovider": issueViewRelaySecurityLevelFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsvotesrelayprovider": issueViewRelayVotesFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuerestrictionsrelayprovider": issueViewRelayIssueRestrictionsFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcconcealitemsintomeatballmenuexperimentrelayprovidertsx": issueViewRelayConcealItemsIntoMeatballMenuExperimentFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsissuetypeswitcherrelayprovider": issueViewRelayIssueTypeSwitcherFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsresolutionrelayprovider": issueViewRelayResolutionFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatusrelayprovider": issueViewRelayStatusFieldFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsstatuspanelrelayprovider": issueViewRelayStatusPanelFlag_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrconeeventimprovementsenabledrelayprovider": isOneEventImprovementsEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider
    }
  }
};

export default node;
