/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useMemo } from 'react';

import { NavigationAnalyticsContext } from '@atlaskit/analytics-namespaced-context';
import { jsx } from '@atlaskit/css';
import { Stack } from '@atlaskit/primitives/compiled';
import {
	BFFConsumerEnum,
	BFFProvider,
	RequestStatus,
	useBFFDataProvider,
} from '@atlassian/navigation-bff-data-provider';
import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';

import type {
	GlobalShortcutsItem,
	GlobalShortcutsProps,
	MappedGlobalShortcutsItem,
} from '../types';
import { sortAlphabetically } from '../utils';

import { ImageFromUrl } from './image-from-url';
import { MenuSkeletonItem } from './menu-skeleton-item';
import { ShortcutMenuItem } from './shortcut-menu-item';

function Menu({
	testId,
	shortcutItems = [],
	forceStatic,
	cloudId,
	orgId,
	useSkeleton,
	promoteSite,
}: GlobalShortcutsProps) {
	const { buildURLAndFetchData, status, productLinks } = useBFFDataProvider({
		request: {},
		cloudId,
		orgId,
	});

	const mappedShortcutItems: Array<MappedGlobalShortcutsItem> = useMemo(() => {
		return shortcutItems.map((item, index) => {
			return {
				...item,
				key: item.apsKey || `shortcut-${index}`,
			};
		});
	}, [shortcutItems]);

	const apsKeys = useMemo(() => {
		return shortcutItems.map((item) => item.apsKey || '').filter((item) => !!item);
	}, [shortcutItems]);

	useEffect(() => {
		if (!forceStatic) {
			buildURLAndFetchData(apsKeys);
		}
	}, [apsKeys, buildURLAndFetchData, forceStatic]);

	const createLinkItem = useCallback(
		(shortcut: MappedGlobalShortcutsItem, index: number) => {
			// Render nested items if the dynamic data is available and we are not forcing static rendering
			if (
				!forceStatic &&
				status === RequestStatus.COMPLETE &&
				shortcut?.apsKey &&
				productLinks?.[shortcut.apsKey]
			) {
				// Determine whether shortcut is a single site and is current site and return link instead of flyout
				const isSingleSiteCurrentSite =
					productLinks[shortcut.apsKey].length === 1 &&
					productLinks[shortcut.apsKey][0].isCurrentSite;

				if (isSingleSiteCurrentSite) {
					return <ShortcutMenuItem key={shortcut.key} shortcut={shortcut} status={status} />;
				}

				// Determine promoted site and filter out from list
				const sites = productLinks[shortcut.apsKey].reduce<{
					promoted?: GlobalShortcutsItem;
					items: (GlobalShortcutsItem & { displayName: string })[];
				}>(
					(acc, item) => {
						// Check if the item is a promoted site and filter it out
						if (promoteSite && item.isCurrentSite) {
							acc.promoted = {
								icon: item.avatar ? <ImageFromUrl src={item.avatar} /> : shortcut.icon,
								label: item.displayName,
								url: item.url,
							};
							return acc;
						}
						acc.items.push({
							icon: item.avatar ? <ImageFromUrl src={item.avatar} /> : shortcut.icon,
							label: item.displayName,
							displayName: item.displayName, // used for sorting items
							url: item.url,
						});
						return acc;
					},
					{ promoted: undefined, items: [] },
				);

				// Sort the items alphabetically by displayName
				const sorted = sites.items.sort((a, b) => {
					return sortAlphabetically('asc')(a.displayName, b.displayName);
				});

				return (
					<ShortcutMenuItem
						key={shortcut.key}
						shortcut={shortcut}
						status={status}
						expandItems={sorted}
						promotedItem={sites.promoted}
					/>
				);
			}

			// Show skeleton while BFF is loading
			if (useSkeleton && (status === RequestStatus.LOADING || status === RequestStatus.INIT)) {
				return <MenuSkeletonItem key={shortcut.key} />;
			}

			// We only want to allow static menu expand item if we are forcing static,
			// otherwise it should be showing the singular item as a disabled link until the
			// data is loaded.
			if (forceStatic && shortcut.items) {
				return (
					<ShortcutMenuItem
						key={shortcut.key}
						shortcut={shortcut}
						status={status ?? RequestStatus.INIT}
						expandItems={shortcut.items}
						forceStatic={forceStatic}
					/>
				);
			}

			return (
				<ShortcutMenuItem
					key={shortcut.key}
					shortcut={shortcut}
					status={status ?? RequestStatus.INIT}
					forceStatic={forceStatic}
				/>
			);
		},
		[forceStatic, productLinks, status, useSkeleton, promoteSite],
	);

	return (
		<Stack testId={testId}>
			<MenuList>{mappedShortcutItems?.map(createLinkItem)}</MenuList>
		</Stack>
	);
}

export default function GlobalShortcuts(props: GlobalShortcutsProps) {
	return (
		<BFFProvider consumer={BFFConsumerEnum.GLOBAL_SHORTCUTS}>
			<NavigationAnalyticsContext data={{ product: props?.product }}>
				<Menu {...props} />
			</NavigationAnalyticsContext>
		</BFFProvider>
	);
}
