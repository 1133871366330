import React, { useCallback } from 'react';
import Lozenge from '@atlaskit/lozenge';
import { useIntl } from '@atlassian/jira-intl';
import { SECTION_ITEM_ASSETS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ASSETS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { L1ExpandableMenuItemSubmenu } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/l1-expandable-menu-item-submenu/main.tsx';
import { useInsightGlobalConfig } from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/services/index.tsx';
import { getLandingPageUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { useByokComplianceSettingWithInitialisation } from '@atlassian/jira-products-jsm-compliance-main/src/services/byok-compliance-setting/index.tsx';
import { AssetsMenuItems } from '../../common/ui/asset-menu-items/index.tsx';
import messages from './messages.tsx';
import { HeaderSchemasMenuItems } from './schemas/index.tsx';
import { SectionActions } from './section-actions/index.tsx';

type AssetsMenuProps = {
	isExpanded: boolean;
};

export function AssetsMenu({ isExpanded }: AssetsMenuProps) {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.ASSETS);
	const isSiteAdmin = useIsSiteAdmin();
	const { isByokCustomer } = useByokComplianceSettingWithInitialisation();
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();
	const [
		{ config, dataManager, loading },
		{ fetchGlobalConfig, fetchProvisioningStatus, fetchMyDataManagerRoles },
	] = useInsightGlobalConfig();
	const fetchAssetsConfig = useCallback(async () => {
		fetchGlobalConfig(false);
		fetchProvisioningStatus(false);
		fetchMyDataManagerRoles(false);
	}, [fetchGlobalConfig, fetchMyDataManagerRoles, fetchProvisioningStatus]);
	const menuId = MENU_ID_ASSETS;

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onExpansionToggle={(hasExpanded) => {
				if (hasExpanded) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.ASSETS,
						sectionItem: SECTION_ITEM_ASSETS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				elemBefore={icon}
				actionsOnHover={<SectionActions isInsightAdministrator={config?.insightAdministrator} />}
				testId={getTestId(menuId)}
				onClick={(_e, _a, { isExpanded: hasExpanded }) => {
					if (hasExpanded) {
						sendSectionItemAnalyticsEvent({
							menuId: L1_MENU_ID.ASSETS,
							sectionItem: SECTION_ITEM_ASSETS,
						});
					}
				}}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				<HeaderSchemasMenuItems />
				<L1ExpandableMenuItemSubmenu
					fetch={fetchAssetsConfig}
					isFetching={loading || dataManager.provisioningLoading || dataManager.myRolesLoading}
					MenuItems={() => (
						<AssetsMenuItems
							dataManagerIsProvisioned={dataManager.isProvisioned}
							dataManagerMyRoles={dataManager.myRoles}
							isSiteAdmin={isSiteAdmin}
							insightAdministrator={config?.insightAdministrator}
							insightReporting={config?.insightReporting}
							isByokCustomer={isByokCustomer}
						/>
					)}
					skeletonCount={2}
				/>
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}

type AssetsSingleMenuItemProps = {
	showUpsellLozenge: boolean;
};

export const AssetsSingleMenuItem = ({ showUpsellLozenge = false }: AssetsSingleMenuItemProps) => {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.ASSETS);
	const { formatMessage } = useIntl();
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	return (
		<Nav4MenuLinkItem
			onClick={() => {
				sendSectionItemAnalyticsEvent({
					menuId: L1_MENU_ID.ASSETS,
					sectionItem: SECTION_ITEM_ASSETS,
				});
			}}
			menuId={MENU_ID_ASSETS}
			elemBefore={icon}
			href={getLandingPageUrl()}
			actionsOnHover={<SectionActions isInsightAdministrator={false} />}
		>
			{`${formattedMessage} `}
			{showUpsellLozenge && (
				<Lozenge
					testId="navigation-apps-sidebar-nav4-sidebars-content-assets.ui.assets-menu.upsell-lozenge"
					appearance="new"
				>
					{formatMessage(messages.upsellLozengeText)}
				</Lozenge>
			)}
		</Nav4MenuLinkItem>
	);
};
