export enum BFFConsumerEnum {
	ATLASSIAN_SWITCHER = 'ATLASSIAN_SWITCHER',
	GLOBAL_SHORTCUTS = 'GLOBAL_SHORTCUTS',
}

export const ASSETS_APP_KEY = 'ASSETS';

export const MAX_ATTEMPTS_DEFAULT = 3;
export const RETRY_INTERVAL_DEFAULT = 1000;

export const DAYS_AUTO_PROVISIONED_ITEM_CONSIDERED_NEW = 14;
export const DAYS_END_USER_ACTIVE_PRODUCT_CONSIDERED_NEW = 14;

export enum RequestStatus {
	INIT = 'initializing',
	LOADING = 'loading',
	COMPLETE = 'complete',
	ERROR = 'error',
}
export const BFF_REQUEST_STATUS_KEY = 'bff';
export const FLIGHTDECK_REQUEST_STATUS_KEY = 'flightdeck';
