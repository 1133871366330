/**
 * @fileOverview Implements a fetch function that can handle multipart/mixed responses.
 * The multipart response is expected to follow the GraphQL multipart request spec.
 *
 * See https://github.com/graphql/graphql-over-http/blob/main/rfcs/IncrementalDelivery.md#content-type-multipartmixed
 * See https://github.com/graphql/graphql-over-http/blob/main/spec/GraphQLOverHTTP.md#multipart-request-format
 */
import { Observable } from 'relay-runtime';
import type { Sink } from 'relay-runtime/lib/network/RelayObservable';
import { getReroutableURL } from '@atlassian/jira-fetch/src/utils/get-reroutable-url.tsx';
import { applyObservabilityHeaders } from '@atlassian/jira-fetch/src/index.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import { NO_CONTENT } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { handleNetworkErrors, handleErrors } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { GraphQLMultipartResponsePayload } from './types.tsx';

function getBoundary(contentType: string): string | null {
	const boundaryMatch = contentType.match(/boundary=['"]([^;]+)['"]/);
	return boundaryMatch ? boundaryMatch[1] : null;
}

/**
 * Processes a chunk of multipart data and emits parsed JSON payloads to the observer
 * Uses a more memory-efficient approach by processing chunks directly
 */
function processChunk(
	chunk: string,
	boundaryString: string,
	observer: Sink<GraphQLMultipartResponsePayload>,
): string {
	const parts = chunk.split(boundaryString);

	// Process all complete parts (except the last one which might be incomplete)
	for (let i = 0; i < parts.length - 1; i++) {
		const part = parts[i].trim();

		// Skip empty parts and encapsulation boundaries
		if (part && !part.startsWith('--')) {
			// Split content-type header and body
			const [, body] = part.split(/\r?\n\r?\n/);
			if (body) {
				try {
					const jsonData = JSON.parse(body.trim());
					observer.next(jsonData);
				} catch (error) {
					observer.error(
						new Error(`Invalid JSON: ${error instanceof Error ? error.message : String(error)}`),
					);
				}
			}
		}
	}

	// Return the last part which might be incomplete
	return parts[parts.length - 1];
}

/**
 * Processes a ReadableStream of multipart data with improved memory efficiency
 */
async function processStream(
	reader: ReadableStreamDefaultReader<Uint8Array>,
	boundary: string,
	observer: Sink<GraphQLMultipartResponsePayload>,
): Promise<void> {
	const decoder = new TextDecoder('utf-8');
	const boundaryString = `--${boundary}`;
	let incompleteChunk = '';

	// Process chunks until the stream is done
	const processNextChunk = async (): Promise<void> => {
		const result = await reader.read();

		if (result.done) {
			// Process any remaining data
			if (incompleteChunk.length > 0) {
				processChunk(incompleteChunk, boundaryString, observer);
			}
			observer.complete();
			return;
		}

		// Decode the new chunk and combine with any incomplete data from previous chunk
		const newText = decoder.decode(result.value, { stream: true });
		const combinedChunk = incompleteChunk + newText;

		// Process the combined chunk and get any incomplete part
		incompleteChunk = processChunk(combinedChunk, boundaryString, observer);

		return processNextChunk();
	};

	await processNextChunk();
}

function handleMultipartResponse(
	response: Response,
	observer: Sink<GraphQLMultipartResponsePayload>,
): void {
	const contentType = response.headers.get('content-type');
	const boundary = getBoundary(contentType || '');

	if (!boundary) {
		observer.error(new Error('Invalid multipart boundary'));
		return;
	}

	const reader = response.body?.getReader();
	if (!reader) {
		observer.error(new Error('Failed to get reader from response body'));
		return;
	}

	processStream(reader, boundary, observer).catch((error) => observer.error(error));
}

function handleJsonResponse(
	response: Response,
	observer: Sink<GraphQLMultipartResponsePayload>,
): void {
	response
		.json()
		.then((data) => observer.next(data))
		.catch((error) => observer.error(error))
		.finally(() => observer.complete());
}

function handleTextResponse(
	response: Response,
	observer: Sink<GraphQLMultipartResponsePayload>,
): void {
	response
		.text()
		.then((data) => {
			const maybeJson = data ? JSON.parse(data) : null;
			observer.next(maybeJson);
			observer.complete();
		})
		.catch((error) => observer.error(error));
}

function processMultipartPromise(
	source: () => Promise<Response>,
): Observable<GraphQLMultipartResponsePayload> {
	// Cache the result of the source promise to avoid calling it multiple times.
	const result = source();
	// Wrap an observer around the result to handle the response.
	return Observable.create((observer) => {
		result
			.then((response) => {
				if (response.status === NO_CONTENT) {
					observer.complete();
					return;
				}

				const contentType = response.headers.get('content-type') || '';

				if (contentType.includes('application/json')) {
					handleJsonResponse(response, observer);
				} else if (contentType.includes('text/plain')) {
					handleTextResponse(response, observer);
				} else if (contentType.includes('multipart/mixed')) {
					handleMultipartResponse(response, observer);
				} else {
					observer.error(new Error(`Unsupported content type: ${contentType}`));
				}
			})
			.catch((error) => observer.error(error));
	});
}

function withErrorHandling(source: Promise<Response>): Promise<Response> {
	return source.then(handleErrors).catch(handleNetworkErrors);
}

function buildGraphqlFetch(url: string, options?: RequestInit): () => Promise<Response> {
	const postOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json, multipart/mixed',
		},
	};

	return () =>
		fetch(
			getReroutableURL(url),
			applyObservabilityHeaders(url, { ...getDefaultOptions(url), ...options, ...postOptions }),
		);
}

function fetchMultipartGraphQL(
	url: string,
	options?: RequestInit,
): Observable<GraphQLMultipartResponsePayload> {
	const fetchFn = buildGraphqlFetch(url, options);
	return processMultipartPromise(() => withErrorHandling(fetchFn()));
}

export {
	fetchMultipartGraphQL as fetchGraphQL,
	buildGraphqlFetch,
	withErrorHandling,
	processMultipartPromise,
};
