import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createHook, createStore } from '@atlassian/react-sweet-state';
import * as actions from './actions/index.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

const getIssueViewLayoutContainers = (state: State, issueKey: IssueKey) =>
	state[String(issueKey)]?.containers || [];

const getIssueViewLayoutGlance = (state: State, issueKey: IssueKey) =>
	state[String(issueKey)]?.glance;

const getIssueViewLayoutContextPanel = (state: State, issueKey: IssueKey) =>
	state[String(issueKey)]?.contextPanel;

// this is a global state
export const store = createStore<State, Actions>({
	name: 'issue-view-layout-service',
	initialState: {},
	actions,
});

export const useIssueLayoutContainersStore = createHook(store, {
	selector: getIssueViewLayoutContainers,
});

export const useIssueLayoutGlanceStore = createHook(store, {
	selector: getIssueViewLayoutGlance,
});

export const useIssueLayoutContextPanelStore = createHook(store, {
	selector: getIssueViewLayoutContextPanel,
});

export const useIssueLayoutActionsStore = createHook(store, {
	selector: null,
});
