import React from 'react';
import type NextGenReportsCumulativeType from '@atlassian/jira-spa-apps-next-gen-reports-cumulative/src/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';
import { getBoardId } from '../../../../common/index.tsx';

/** @deprecated remove when clean up fun-1592-migrate-cumulative-report-to-entrypoint */
export const LazyNextGenCumulativeReport = lazyForPaint<typeof NextGenReportsCumulativeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-next-gen-reports-cumulative", jiraSpaEntry: "next-gen-reports-cumulative" */ '@atlassian/jira-spa-apps-next-gen-reports-cumulative/src/index-old.tsx'
		),
	),
);

type Props = RouteContext;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ location, match }: Props) => (
	<LazyPage
		Page={LazyNextGenCumulativeReport}
		pageId={`next-gen-reports-cumulative-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);
