import React, { useCallback, useEffect, useState, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { ExitingPersistence, SlideIn } from '@atlaskit/motion';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { DRAWER_Z_INDEX } from '../../common/constants.tsx';
import messages from './messages.tsx';

type DrawerProps = PropsWithChildren<{
	isOpen: boolean;
	onClose: VoidFunction;
}>;

export const Drawer = ({ isOpen, onClose, children }: DrawerProps) => {
	const { formatMessage } = useIntl();
	const [cookieBannerHeight, setCookieBannerHeight] = useState<number | undefined>(undefined);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape' && isOpen) {
				onClose();
			}
		},
		[isOpen, onClose],
	);

	useEffect(() => {
		if (isOpen) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('keydown', handleKeyDown);
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, handleKeyDown]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const banner = document.querySelector('[role="banner"]');
		const updateBannerHeight = () => {
			setCookieBannerHeight(banner ? banner.getBoundingClientRect().height : undefined);
		};

		updateBannerHeight();
		const observer = new MutationObserver(() => updateBannerHeight());

		const cookieBannerParent = banner?.parentNode;
		if (cookieBannerParent) {
			// so that we can adjust the drawer height when the cookie banner is dismissed
			observer.observe(cookieBannerParent, { childList: true });
		}
		return () => {
			if (cookieBannerParent) {
				observer.disconnect();
			}
		};
	}, []);

	return (
		<ExitingPersistence appear>
			{isOpen && (
				<SlideIn duration="medium" enterFrom="left" exitTo="left">
					{(props) => (
						<Container {...props} bannerHeight={cookieBannerHeight}>
							{children}
							<Box xcss={closeButtonStyles}>
								<IconButton
									shape="circle"
									icon={CrossIcon}
									label={formatMessage(messages.closeButtonLabel)}
									appearance="subtle"
									onClick={onClose}
								/>
							</Box>
						</Container>
					)}
				</SlideIn>
			)}
		</ExitingPersistence>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ bannerHeight?: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	height: ({ bannerHeight }) =>
		bannerHeight ? `calc(100vh - ${bannerHeight}px)` : 'calc(100vh - var(--topNavigationHeight))',
	width: '100vw',
	position: 'fixed',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	top: ({ bannerHeight }) => (bannerHeight ? `${bannerHeight}px` : 'var(--topNavigationHeight)'),
	left: token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: DRAWER_Z_INDEX,
	backgroundColor: token('elevation.surface.overlay'),
	overflow: 'hidden',
});

const closeButtonStyles = xcss({
	position: 'absolute',
	top: 'space.250',
	left: 'space.250',
});
