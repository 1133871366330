import React, { useMemo, useCallback } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID_PLANS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useRefetchWhenIsSelected } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-refetch-when-is-selected/index.tsx';
import { useSubscribeAndUpdateFavorites } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/services/set-favorite/index.tsx';
import { useStableItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-stable-items/index.tsx';
import QUERY, {
	type PlanListQuery,
} from '@atlassian/jira-relay/src/__generated__/PlanListQuery.graphql';
import type { PlansList$key } from '@atlassian/jira-relay/src/__generated__/PlansList.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	MenuListItem,
	MenuList,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import { PlansEmptyContent } from '../empty-content/index.tsx';
import messages from './messages.tsx';
import { PlanMenuItem } from './plan-menu-item/PlanMenuItem.tsx';

type Props = {
	fragmentRef: PlansList$key;
};

const MAX_RECENT_PLANS = 5;

/**
 * We intentionally request 15 starred and 20 recent plans.
 * If all 15 starred plans were recently visited, we still show 5 recent plans as well.
 */
export const PlansList = ({ fragmentRef }: Props) => {
	const { isSelectedPath } = useSidebarNav4();
	const cloudId = useCloudId();
	const { getStableItems } = useStableItems();

	const [data, refetch] = useRefetchableFragment<PlanListQuery, PlansList$key>(
		graphql`
			fragment PlansList on Query
			@refetchable(queryName: "PlanListQuery")
			@argumentDefinitions(currentURLPath: { type: URL }) {
				jira @required(action: LOG) {
					favouritePlans: favourites(
						cloudId: $cloudId
						filter: { type: PLAN, sort: { order: DESC } }
						first: 15
					) @required(action: LOG) {
						__id
						edges {
							node @required(action: LOG) {
								... on JiraPlan {
									id @required(action: LOG)
									planId
									...PlanMenuItem
								}
							}
						}
					}
					recentPlans: recentItems(
						cloudId: $cloudId
						currentURL: $currentURLPath
						types: [PLAN]
						first: 20
					) @required(action: LOG) {
						__id
						edges {
							node @required(action: LOG) {
								... on JiraPlan {
									id @required(action: LOG)
									planId
									...PlanMenuItem
								}
							}
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	useRefetchWhenIsSelected({ cloudId, isSelectedPath, QUERY, refetch, menuId: MENU_ID_PLANS });

	const refetchOnChange = useCallback(
		({ onComplete }: { onComplete: () => void }) => {
			refetch({ cloudId }, { fetchPolicy: 'store-and-network', onComplete });
		},
		[cloudId, refetch],
	);

	useSubscribeAndUpdateFavorites({
		entityTypename: 'JiraPlan',
		favouriteConnectionId: data?.jira.favouritePlans.__id || '',
		recentConnectionId: data?.jira.recentPlans.__id || '',
		refetch: refetchOnChange,
	});

	const { formatMessage } = useIntl();
	const starred = formatMessage(messages.starred);
	const recent = formatMessage(messages.recent);

	const favorites = useMemo(() => {
		if (!data) return { idSet: new Set(), list: [], hasItems: false };

		const ids = data.jira.favouritePlans.edges?.map((edge) => edge?.node.id).filter(Boolean) ?? [];

		const list =
			data.jira.favouritePlans.edges
				?.filter(Boolean)
				.map((edge) => (
					<PlanMenuItem
						key={edge.node.planId}
						fragmentRef={edge.node}
						favouriteConnectionId={data.jira.favouritePlans.__id}
					/>
				)) ?? [];

		return { idSet: new Set(ids), list, hasItems: Boolean(list.length) };
	}, [data]);

	const recentPlans = useMemo(() => {
		if (!data) return { list: [], hasItems: false };

		const nodes =
			data.jira.recentPlans.edges
				?.filter(Boolean)
				.filter((edge) => edge.node.id && !favorites.idSet.has(edge.node.id))
				.map((edge) => edge.node)
				.slice(0, MAX_RECENT_PLANS) ?? [];

		const stableOrderedNodes = getStableItems({ nodes, idKey: 'id' });

		const list = stableOrderedNodes.map((node) => (
			<PlanMenuItem
				key={node.planId}
				fragmentRef={node}
				favouriteConnectionId={data.jira.favouritePlans.__id}
			/>
		));

		return { list, hasItems: Boolean(list.length) };
	}, [data, favorites.idSet, getStableItems]);

	if (!favorites.hasItems && !recentPlans.hasItems) {
		return <PlansEmptyContent />;
	}

	return (
		<>
			{favorites.hasItems && (
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{starred}</MenuSectionHeading>
						<MenuList>{favorites.list}</MenuList>
					</MenuSection>
				</MenuListItem>
			)}
			{recentPlans.hasItems && (
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{recent}</MenuSectionHeading>
						<MenuList>{recentPlans.list}</MenuList>
					</MenuSection>
				</MenuListItem>
			)}
		</>
	);
};
