import React, { type FC, type PropsWithChildren } from 'react';

/**
 * Reports a SSR failure on render. To be placed in (top level) Suspense fallback
 * 🚨Single use per route 🚨
 */
export const ReportSSRFailOnUse: FC<{ pageId: string }> = () => {
	// this component does nothing. Its .server. version does
	return null;
};

/**
 * Disables SSR render failure detection.
 * To be placed inside Suspense to aid `RLL ssr:false` components
 */
export const DisableSSRFailDetection: FC<PropsWithChildren> = ({ children }) => <>{children}</>;
