import React from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { Dashboards } from './dashboards/index.tsx';
import { IssueViewModal } from './issue-view-modal/index.tsx';
import { KeyboardShortcuts } from './keyboard-shortcuts/index.tsx';
import { Projects } from './projects/index.tsx';

export const Integrations = componentWithFG(
	'blu-7315-fix-sidebar-shortcut',
	IntegrationsNew,
	IntegrationsOld,
);
Integrations.displayName = 'Integrations'; // This is needed to pass the test.tsx's uniqueness-test. Without it, the component appears as 'ComponentWithCondition[]', which already exists in the list.

function IntegrationsOld() {
	if (!__SERVER__) {
		return (
			<>
				<KeyboardShortcuts />
				<IssueViewModal />
				<Projects />
				<Dashboards />
			</>
		);
	}
	return null;
}

function IntegrationsNew() {
	if (!__SERVER__) {
		return (
			<>
				<IssueViewModal />
				<Projects />
				<Dashboards />
			</>
		);
	}
	return null;
}

export function IntegrationsWhichRequireNavigationRoot() {
	if (!__SERVER__) {
		return <KeyboardShortcuts />;
	}
	return null;
}
