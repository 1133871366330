import React, { type ReactElement } from 'react';

import type { GlobalShortcutLinkItem, GlobalShortcutsItem } from './types';

/**
 * A helpful type guard to determine whether items is a link or expand item.
 * @param item
 * @returns
 */
export const isGlobalShortcutLinkItem = (
	item: GlobalShortcutsItem,
): item is GlobalShortcutLinkItem => {
	return (item as GlobalShortcutLinkItem).fallbackUrl !== undefined;
};

/**
 * Utility method to help sorting string alphabetically.
 * @param direction
 * @returns positive or negative number
 */
export const sortAlphabetically = (direction: 'asc' | 'desc') => (a: string, b: string) => {
	const aLower = a.toLowerCase();
	const bLower = b.toLowerCase();

	if (direction === 'asc') {
		return aLower.localeCompare(bLower);
	}
	return bLower.localeCompare(aLower);
};

/**
 * Utility function to extract the label text so we can use it to identify items in analytics events
 * @param label
 */
export function extractNameFromLabel(label: ReactElement): string | undefined {
	if (!label?.props?.children) {
		return undefined;
	}

	if (typeof label?.props?.children === 'string') {
		return (label as ReactElement)?.props?.children;
	} else if (React.isValidElement(label.props.children)) {
		return extractNameFromLabel(label.props.children);
	}
}
