import React, { ReactNode } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type ContentMenuProps = {
	children?: ReactNode;
	heading?: ReactNode;
};

const FLYOUT_MENU_MAX_HEIGHT = 476;

// MenuContent is responsible for defining a max-height for the menu content
// of the flyout menu. Heavily inspired by confluence's menu content componnet.
export const MenuContent = ({ children, heading }: ContentMenuProps) => {
	return (
		<Box xcss={menuContentContainerStyle}>
			{heading && <Box xcss={menuContentHeadingStyle}>{heading}</Box>}
			{children}
		</Box>
	);
};

const menuContentContainerStyle = xcss({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	maxHeight: `min(calc(100vh - 26px), ${FLYOUT_MENU_MAX_HEIGHT}px)`,
});

const menuContentHeadingStyle = xcss({
	height: 'size.300',
	paddingBlock: 'space.075',
	paddingLeft: 'space.075',
	color: 'color.text.subtle',
	font: token('font.body'),
	fontWeight: token('font.weight.bold'),
	display: 'flex',
	alignItems: 'center',
});
