import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { nativeIssueTableEntryPoint } from '@atlassian/jira-native-issue-table/src/ui/entrypoint.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const issueNavigatorEntrypoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() => import(/* webpackChunkName: "async-project-issue-navigator-entrypoint" */ './src'),
		{ entryFsSsrLiftImportToModule: true },
	),
	getPreloadProps: (entryPointParams: EntryPointRouteParams) => {
		const issueKey = entryPointParams?.context?.match?.params?.issueKey;

		// exclude nativeIssueTableEntryPoint from preloading on the issue route
		if (!issueKey && fg('jira_list_issue_table_entrypoint')) {
			return {
				entryPoints: {
					nativeIssueTableEntryPoint: {
						entryPoint: nativeIssueTableEntryPoint,
						entryPointParams,
					},
				},
			};
		}

		return {};
	},
});
