import React, { useCallback } from 'react';
import {
	DATA_MANAGER_ROLES,
	DATA_MANAGER_ADAPTERS_ROLES,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/data-manager-types.tsx';
import { isFedRamp } from '@atlassian/atl-context';
import { fg } from '@atlassian/jira-feature-gating';
import { DataManagerAdapterMenuItems } from '../data-manager-adapter/index.tsx';
import { DataManagerMenuItems } from '../data-manager/index.tsx';
import { ReportsMenuItem } from '../reports/index.tsx';

export const AssetsMenuItems = ({
	dataManagerIsProvisioned,
	dataManagerMyRoles,
	isSiteAdmin,
	insightAdministrator,
	insightReporting,
	isByokCustomer,
}: {
	dataManagerIsProvisioned: boolean | undefined;
	dataManagerMyRoles: string[] | undefined;
	isSiteAdmin: boolean;
	insightAdministrator: boolean | undefined;
	insightReporting: boolean | undefined;
	isByokCustomer: boolean;
}) => {
	const showDataManagerLink = useCallback(() => {
		if (dataManagerIsProvisioned && dataManagerMyRoles) {
			const hasDMRoles = dataManagerMyRoles.some((myRole) => DATA_MANAGER_ROLES.includes(myRole));
			const hasAdapterRoles = dataManagerMyRoles.some((myRole) =>
				DATA_MANAGER_ADAPTERS_ROLES.includes(myRole),
			);
			if (hasDMRoles) {
				return <DataManagerMenuItems />;
			}

			if (hasAdapterRoles) {
				return <DataManagerAdapterMenuItems />;
			}
		}
	}, [dataManagerIsProvisioned, dataManagerMyRoles]);

	const isAssetsReportsByokSupported = isByokCustomer
		? fg('assets_reports_enabled_for_byok_customers')
		: true;

	const isInsightReportsEnabled = useCallback(
		() => (isSiteAdmin || insightAdministrator) && isAssetsReportsByokSupported && insightReporting,
		[insightAdministrator, insightReporting, isSiteAdmin, isAssetsReportsByokSupported],
	);

	return (
		<>
			{isInsightReportsEnabled() && !isFedRamp() && <ReportsMenuItem />}
			{showDataManagerLink()}
		</>
	);
};
