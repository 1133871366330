import React, { type ComponentType, useEffect } from 'react';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import ApdexProvider from '@atlassian/jira-providers-spa-apdex-analytics/src/provider.tsx';
import {
	SpaStateContainer,
	SpaStateAppStartWithMatchCheck,
} from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import type { LazyComponent } from '@atlassian/react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ReportSSRFailOnUse,
	DisableSSRFailDetection,
} from '@atlassian/jira-ssr-render-guard/src/index.tsx';

type LazyPageProps = {
	shouldShowSpinner: boolean;
	pageId: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Page: LazyComponent<any> | ComponentType<any>;
};
const LOCATION = 'lazy-page';
export const EmptySkeleton = () => null;

/**
 * This component is designed for the lazy loading of pages with an option to display a loading indicator. It handles two scenarios based on the route's status: if the route is a redirect, it logs a warning and renders a placeholder; otherwise, it wraps the page in performance tracking and global state management contexts. This dual functionality ensures both optimal user experience during page load and adherence to best practices in application state and performance monitoring.
 */
export const LazyPage = ({ pageId, Page, shouldShowSpinner = true }: LazyPageProps) => {
	const route = useCurrentRoute();
	const Skeleton = route.skeleton || (shouldShowSpinner ? Spinner : EmptySkeleton);
	useEffect(() => {
		if (route.isRedirect) {
			log.safeWarnWithoutCustomerData(
				LOCATION,
				`LazyPage was used inside a redirect component: ${route.name}`,
			);
		}
	}, [route.isRedirect, route.name]);

	const fallback = fg('jfp_magma_jira-ssr-fail-detection') ? (
		<>
			<Skeleton />
			<ReportSSRFailOnUse pageId={pageId} />
		</>
	) : (
		<Skeleton />
	);

	const page = fg('jfp_magma_jira-ssr-fail-detection') ? (
		<DisableSSRFailDetection>
			<Page />
		</DisableSSRFailDetection>
	) : (
		<Page />
	);

	return route.isRedirect ? (
		<Placeholder name="lazy_page_redirect" fallback={<Skeleton />}>
			<Page />
		</Placeholder>
	) : (
		<ApdexProvider pageId={pageId}>
			<SpaStateContainer isGlobal appId={pageId}>
				<SpaStateAppStartWithMatchCheck />
				<Placeholder name="lazy_page" fallback={fallback}>
					{page}
				</Placeholder>
			</SpaStateContainer>
		</ApdexProvider>
	);
};
