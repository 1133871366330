/**
 * @generated SignedSource<<96999ed864068358afd9745f6fb144ab>>
 * @relayHash 97866d0698672dd92115c501babd2fa9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3d194e9adf82ac76354970006d56b73b4a99f9fc6946de3b213b22d0de248620

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "3d194e9adf82ac76354970006d56b73b4a99f9fc6946de3b213b22d0de248620",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
