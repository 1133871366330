import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const cumulativeReportEntrypoint = createEntryPoint({
	root: JSResourceForUserVisible(
		() =>
			import(
				/* webpackChunkName: "async-next-gen-reports-cumulative-entrypoint" */ './src/index.tsx'
			),
	),
	getPreloadProps: () => ({}),
});
