import React, { ReactNode } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type ContentMenuProps = {
	children?: ReactNode;
	label?: ReactNode;
};

// MenuSection is responsible for seperating logical groups of menu items.
// it is primarily used to seperate the promoted site from the rest of the menu items.
export const MenuSection = ({ children, label }: ContentMenuProps) => {
	return (
		<Box xcss={menuSectionContainerStyle}>
			{label && <Box xcss={menuSectionLabelStyle}>{label}</Box>}
			{children}
		</Box>
	);
};

const menuSectionContainerStyle = xcss({
	display: 'flex',
	flexDirection: 'column',
});

const menuSectionLabelStyle = xcss({
	height: 'size.300',
	paddingBlock: 'space.100',
	paddingLeft: 'space.075',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	color: 'color.text.subtle',
	font: token('font.body'),
	fontWeight: 'font.weight.semibold',
	display: 'flex',
	alignItems: 'center',
});
