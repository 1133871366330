import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';
import { reportsOverviewEntrypoint } from '@atlassian/jira-spa-apps-next-gen-reports-overview/entrypoint.tsx';

export const reportsOverviewPageEntrypoint = createPageEntryPoint({
	main: reportsOverviewEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});
